import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Color as AlertSeverity } from '@material-ui/lab/Alert/Alert';
import { resetGame } from '../../../../clients/BatRackClient';
import { Button, Card, CardContent, Grid, TextField, Typography } from '@material-ui/core';
import Alert from '../../../Global/Alert';

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: '700px',
    margin: '0 auto',
    marginBottom: '24px',
  },
  grid: {
    marginTop: '8px',
    display: 'flex',
    justifyContent: 'center',
  },
  commonText: {
    textAlign: 'center',
    margin: '0',
    lineHeight: '28px',
    fontSize: '15px',
    fontWeight: '300',
  },
  listText: {
    marginTop: '4px',
    textAlign: 'left',
    fontSize: '13px',
    width: '550px',
    margin: '0 auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  textField: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  button: {
    marginLeft: '16px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: '0',
      marginTop: '16px',
    },
  },
}));

const ResetGame = () => {
  const classes = useStyles();
  const [gamePk, setGamePk] = React.useState('');
  const [alertIsOpen, setAlertIsOpen] = React.useState(false);
  const [alertSeverity, setAlertSeverity] = React.useState('success' as AlertSeverity);

  const handleResetGame = async () => {
    try {
      setGamePk('');
      await resetGame(gamePk);
      setAlertSeverity('success');
    } catch (error) {
      if ([401, 403].includes(error.response?.status)) {
        return;
      }
      setAlertSeverity('error');
    }
    setAlertIsOpen(true);
  };

  return (
    <Card variant='outlined' className={classes.card}>
      <CardContent>
        <Typography color='textSecondary' gutterBottom>
          Reset Game
        </Typography>
        <p className={classes.commonText}>
          Only reset a game after checking the following and/or there continues to be significant issues
        </p>
        <ul className={`${classes.commonText} ${classes.listText}`}>
          <li>Check status.mlb.com to see if there are any ongoing issues</li>
          <li>Restart the app. This can be done by swiping up slowly from the bottom of the iPad</li>
          <li>Reboot the device</li>
          <li>Check BatRack to see if the game is accessible</li>
          <li>If possible, determine the issue is not network related (connected to MLBAM001?)</li>
        </ul>
        <Grid className={classes.grid} container>
          <TextField
            className={classes.textField}
            id='outlined-basic'
            label='Game PK'
            variant='outlined'
            size='small'
            value={gamePk}
            onChange={e => setGamePk(e.target.value)}
          />
          <Button
            className={classes.button}
            variant='contained'
            color='primary'
            onClick={handleResetGame}
            disabled={!gamePk || gamePk.length < 1}
          >
            Reset Game
          </Button>
          <Alert
            open={alertIsOpen}
            setOpen={setAlertIsOpen}
            message={`${alertSeverity === 'success' ? 'Game Reset Successful' : 'Game Reset Failed'}`}
            severity={alertSeverity}
            autoHideDuration={5000}
          />
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ResetGame;
