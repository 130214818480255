import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import {
  ALLOWED_INCORRECT_CHALLENGES,
  GamePitchChallengeRow,
  INITIAL_TEAM_CHALLENGES,
  PITCH_CHALLENGE_RESULT,
  PitchChallengesCount,
} from '../../../types/PitchChallenge';

type TabsGameChallengesSummaryProps = {
  row: GamePitchChallengeRow;
  classes: Record<string, string>;
};

const GameChallengesSummary: React.FC<TabsGameChallengesSummaryProps> = ({ row, classes }) => {
  const [awayChallenges, setAwayChallenges] = useState(INITIAL_TEAM_CHALLENGES);
  const [homeChallenges, setHomeChallenges] = useState(INITIAL_TEAM_CHALLENGES);

  const teamChallengesByTeamId = (teamId: number): PitchChallengesCount => {
    const total = row.gamePitchChallenges.pitchChallenges.filter(c => c.pitchChallengeTeamId === teamId);
    const overturned = total.filter(c => c.pitchChallengeResult === PITCH_CHALLENGE_RESULT.OVERTURNED);
    const upheld = total.filter(c => c.pitchChallengeResult === PITCH_CHALLENGE_RESULT.UPHELD);
    return { challenges: total.length, overturned: overturned.length, upheld: upheld.length };
  };

  const teamChallengesSummaryText = (challengesCount: PitchChallengesCount) => {
    const successRate = `${challengesCount.overturned}/${challengesCount.challenges}`;
    const remaining = ALLOWED_INCORRECT_CHALLENGES - challengesCount.upheld;
    return `(${successRate}, ${remaining} remaining)`;
  };

  useEffect(() => {
    setAwayChallenges(teamChallengesByTeamId(row.gamePitchChallenges.awayTeamId));
    setHomeChallenges(teamChallengesByTeamId(row.gamePitchChallenges.homeTeamId));
  }, [row]);

  return (
    <Grid container>
      <Grid item xs={12} lg={9} style={{ display: 'flex', alignItems: 'center' }}>
        <div className={classes.gameChallengesSummaryContainer}>
          <div className={classes.gameChallengesSummaryHorizontal}>
            <div className={classes.gameChallengesSummaryLabelLg}>{row.gamePitchChallenges.gamePk}</div>
          </div>
          <div className={classes.gameChallengesSummaryHorizontal}>
            <div className={classes.gameChallengesSummaryLabelSymbol}>&mdash;</div>
          </div>
          <div className={classes.gameChallengesSummaryVertical}>
            <div className={classes.gameChallengesSummaryLabel}>{row.gamePitchChallenges.awayTeam.name}</div>
            <div className={classes.gameChallengesSummaryText}>{teamChallengesSummaryText(awayChallenges)}</div>
          </div>
          <div className={classes.gameChallengesSummaryHorizontal}>
            <div className={classes.gameChallengesSummaryLabelSymbol}>&#64;</div>
          </div>
          <div className={classes.gameChallengesSummaryVertical}>
            <div className={classes.gameChallengesSummaryLabel}>{row.gamePitchChallenges.homeTeam.name}</div>
            <div className={classes.gameChallengesSummaryText}>{teamChallengesSummaryText(homeChallenges)}</div>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} lg={3} className={classes.summary}>
        <div className={classes.summaryText}>
          <div style={{ display: 'flex' }}>
            <div className={classes.wrapper}>
              <span className={`${classes.circle} ${classes.challenged}`}>
                {awayChallenges.challenges + homeChallenges.challenges}
              </span>
              <span>challenge{awayChallenges.challenges + homeChallenges.challenges === 1 ? '' : 's'}</span>
            </div>
            <div className={classes.wrapper}>
              <span className={`${classes.circle} ${classes.upheld}`}>
                {awayChallenges.upheld + homeChallenges.upheld}
              </span>
              <span>upheld</span>
            </div>
            <div className={classes.wrapper}>
              <span className={`${classes.circle} ${classes.overturned}`}>
                {awayChallenges.overturned + homeChallenges.overturned}
              </span>
              <span>overturned</span>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default GameChallengesSummary;
