import React from 'react';

import ResetGame from './ResetGame/ResetGame';
import GameTestability from './GameTestability/GameTestability';
import LoadGame from './LoadGame/LoadGame';

const GamesPage = () => {
  return (
    <div>
      <>
        <LoadGame />
        <ResetGame />
        <GameTestability />
      </>
    </div>
  );
};

export default GamesPage;
