import React from 'react';
import {
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Theme,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import PlayLookupDetails from '../PlayLookupDetails';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { makeStyles } from '@material-ui/core/styles';
import dugoutLogo from '../../../../assets/baseball-logo.png';
import { useRoles } from '../../../../context/RoleContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      cursor: 'pointer',
    },
    dialogTitle: {
      display: 'flex',
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      '& .MuiTypography-root': {
        display: 'flex',
        alignItems: 'center',
        fontSize: '20px',
        fontWeight: 'bold',
      },
    },
    dugoutLogo: {
      width: '55px',
      marginRight: '10px',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: 'white',
    },
  }),
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

type PlayDetailsDialog = {
  playId: string;
};

const PlayDetailsDialog: React.FC<PlayDetailsDialog> = ({ playId }) => {
  const classes = useStyles();
  const { isSuperAdminUser, isAdminUser, isBatrackUser } = useRoles();

  const [open, setOpen] = React.useState(false);

  const canViewPlayDetails = (): boolean => {
    return isSuperAdminUser || isAdminUser || isBatrackUser;
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {canViewPlayDetails() ? (
        <>
          <Link onClick={handleClickOpen} className={classes.link}>
            {playId}
          </Link>
          <Dialog fullScreen open={open} onClose={handleClickClose} TransitionComponent={Transition}>
            <DialogTitle className={classes.dialogTitle}>
              <img src={dugoutLogo} alt='batrack' className={classes.dugoutLogo} />
              <Typography>Details for Play {playId}</Typography>
              <IconButton className={classes.closeButton} onClick={handleClickClose}>
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <PlayLookupDetails playId={playId} />
            </DialogContent>
          </Dialog>
        </>
      ) : (
        <>{playId}</>
      )}
    </div>
  );
};

export default PlayDetailsDialog;
