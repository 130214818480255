import React, { useEffect, useState } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { GameType } from '../../../types/Game';
import { getFlaggedData, getFlaggedPitchOptions } from '../../../clients/UmpireApiClient';
import { FlaggedPitch, FlaggedPitchOptions } from '../../../types/FlaggedPitches';
import StyledTableCell from '../../Global/Tables/StyledTableCell';
import { getGumboHydratePerson } from '../../../clients/StatsApiClient';
import { getResearchApiUrlForPlay, getStatsApiUrlLiveGameWithStrikeZoneAnalytics } from '../../../utils/shared-links';
import StyledLinkIconButton from '../../Global/Buttons/StyledLinkIconButton';
import PlayDetailsDialog from '../../BatRack/PlayLookupPage/PlayDetails/PlayDetailsDialog';

type TabsDataProps = {
  games: GameType[];
};

const columnNames = ['Game', 'Play ID', 'Team', 'Batter', 'Pitcher', 'Flag', 'Comment', 'Research', 'GUIDs'];

type TabsDataType = FlaggedPitch & {
  game: string | number;
  flag: string | number;
  team: string | number;
  pitcher: string | number;
  batter: string | number;
  research: string;
  guids: string;
};

type FlagPitchOptionMap = {
  [flagPitchOption: number]: FlaggedPitchOptions;
};

type GameMapType = {
  [gamePk: number]: GameType;
};

const TabsData: React.FC<TabsDataProps> = ({ games }: TabsDataProps) => {
  const [displayData, setDisplayData] = useState<TabsDataType[]>([]);
  const [flaggedPitches, setFlaggedPitches] = useState<FlaggedPitch[]>([]);
  const [flagPitchOptions, setFlagPitchOptions] = useState<FlagPitchOptionMap>({});
  const [gameMap, setGameMap] = useState<GameMapType>({});
  const [teamMap, setTeamMap] = useState({});
  const [playerMap, setPlayerMap] = useState({});

  const getData = async (gamePks: number[]) => {
    try {
      await Promise.all([getFlaggedPitchData(gamePks)]);
    } catch (e) {
      console.error(e);
    }
  };

  const getFlaggedPitchData = async (gamePks: number[]) => {
    const result: FlaggedPitch[] = await getFlaggedData(gamePks);
    setFlaggedPitches(result);
  };

  const getFlaggedPitchOptionsData = async () => {
    const result: FlaggedPitchOptions[] = await getFlaggedPitchOptions();
    const map: FlagPitchOptionMap = {};
    result.forEach((option: FlaggedPitchOptions) => {
      map[option.flaggedOptionId] = option;
    });
    setFlagPitchOptions(map);
  };

  const resetData = () => {
    setDisplayData([]);
    setFlaggedPitches([]);
    setGameMap({});
    setFlagPitchOptions({});
  };

  const getTeamAndPlayerMap = async (gamePk: number): Promise<any> => {
    const result = await getGumboHydratePerson(gamePk);
    const homeTeam = result.gameData.teams.home;
    const awayTeam = result.gameData.teams.away;
    const players = {
      ...result.liveData.boxscore.teams.home.players,
      ...result.liveData.boxscore.teams.away.players,
    };
    return {
      teamMap: {
        [homeTeam.id]: homeTeam,
        [awayTeam.id]: awayTeam,
      },
      playerMap: {
        ...players,
      },
    };
  };

  const createTeamAndPlayerMaps = async (gamePks: number[]) => {
    let playerMapData = {};
    let teamMapData = {};
    for (const gamePk of gamePks) {
      const result = await getTeamAndPlayerMap(gamePk);
      playerMapData = {
        ...playerMapData,
        ...result.playerMap,
      };
      teamMapData = {
        ...teamMapData,
        ...result.teamMap,
      };
    }
    setTeamMap(teamMapData);
    setPlayerMap(playerMapData);
  };

  useEffect(() => {
    getFlaggedPitchOptionsData();
  }, []);

  useEffect(() => {
    if (games.length) {
      const gamePks: number[] = games.map((game: GameType) => game.gamePk);
      const map: GameMapType = {};
      games.forEach((game: GameType) => {
        map[game.gamePk] = game;
      });
      setGameMap(map);
      createTeamAndPlayerMaps(gamePks);
      getData(gamePks);
    } else {
      resetData();
    }
  }, [games]);

  useEffect(() => {
    const mappedData: TabsDataType[] = flaggedPitches.map((pitch: FlaggedPitch) => {
      const game: GameType | undefined = gameMap[pitch.gamePk];
      const gameStr = game ? `${game.awayTeamName.code} @ ${game.homeTeamName.code}` : pitch.gamePk.toString();
      const pitcherKey = `ID${pitch.pitcherId}`;
      const batterKey = `ID${pitch.batterId}`;
      return {
        ...pitch,
        game: gameStr,
        flag: flagPitchOptions[pitch.flaggedOptionId]?.option || pitch.flaggedOptionId,
        team: teamMap[pitch.teamId]?.abbreviation || pitch.teamId,
        pitcher: playerMap[pitcherKey]?.person?.fullName || pitch.pitcherId.toString(),
        batter: playerMap[batterKey]?.person?.fullName || pitch.batterId,
        research: getResearchApiUrlForPlay(pitch.gamePk, pitch.playId),
        guids: getStatsApiUrlLiveGameWithStrikeZoneAnalytics(pitch.gamePk),
      };
    });
    setDisplayData(mappedData);
  }, [flagPitchOptions, flaggedPitches, gameMap, teamMap, playerMap]);

  return (
    <Paper>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columnNames.map(columnName => (
                <StyledTableCell key={columnName}>{columnName}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {displayData.map((data: TabsDataType) => (
              <TableRow key={`${data.gamePk}_${data.playId}_${data.teamId}`}>
                <TableCell>{data.game}</TableCell>
                <TableCell>
                  <PlayDetailsDialog playId={data.playId} />
                </TableCell>
                <TableCell>{data.team}</TableCell>
                <TableCell>{data.batter}</TableCell>
                <TableCell>{data.pitcher}</TableCell>
                <TableCell>{data.flag}</TableCell>
                <TableCell>{data.freeText}</TableCell>
                <TableCell align='center'>
                  <StyledLinkIconButton linkUrl={data.research} />
                </TableCell>
                <TableCell align='center'>
                  <StyledLinkIconButton linkUrl={data.guids} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TabsData;
