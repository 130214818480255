import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, MenuItem, Select } from '@material-ui/core';
import moment from 'moment-timezone';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: '8px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
  commonText: {
    textAlign: 'right',
    margin: '0',
    lineHeight: '28px',
    fontWeight: 'bold',
    fontSize: '20px',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  smallText: {
    fontSize: '15px',
    fontWeight: '300',
  },
  italic: {
    fontStyle: 'italic',
  },
  select: {
    height: '40px',
    display: 'flex',
    alignSelf: 'flex-end',
    marginBottom: '6px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

type TeamsTableControlBarProps = {
  divisions: string[];
  filterTeams: (division: string) => void;
};

const TeamsTableControlBar = ({ divisions, filterTeams }: TeamsTableControlBarProps) => {
  const classes = useStyles();
  const [selectedDivision, setSelectedDivision] = useState<string>('ALL');

  let date = moment.tz(moment(), 'America/New_York');
  if (date.hour() < 5) {
    date = date.subtract(1, 'days');
  }

  const handleDivisionChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    const division = event.target.value as string;
    setSelectedDivision(division);
    filterTeams(division);
  };

  return (
    <Grid className={classes.root} container justify='space-between'>
      {divisions && divisions.length > 0 ? (
        <Select
          margin='dense'
          className={classes.select}
          style={{ minWidth: 135 }}
          variant='outlined'
          value={selectedDivision}
          onChange={handleDivisionChange}
        >
          <MenuItem value='ALL'>ALL</MenuItem>
          {divisions.sort().map(division => {
            return (
              <MenuItem key={division} value={division}>
                {division}
              </MenuItem>
            );
          })}
        </Select>
      ) : (
        <div></div>
      )}

      <div>
        <p className={`${classes.commonText} ${classes.smallText}`}>Game data displayed below is for</p>
        <p className={classes.commonText}>{date.format('dddd, MMMM DD, YYYY')}</p>
        <p className={`${classes.commonText} ${classes.smallText} ${classes.italic}`}>
          Schedule refreshes daily at 5:00 a.m. Eastern
        </p>
      </div>
    </Grid>
  );
};

export default TeamsTableControlBar;
