import axios from 'axios';

const BASE_URL = process.env.BASE_URL;
const ABS_SCOREBOARD_API_URL = process.env.ABS_SCOREBOARD_API_URL;
const MLBAUTH_API_URL = process.env.MLBAUTH_API_URL;
const UMPIRE_API_URL = process.env.UMPIRE_API_URL;
const STATS_API_URL = process.env.STATS_API_URL;
const LINEUP_URL = process.env.LINEUP_URL;
const SSE_API_URL = process.env.SSE_API_URL;

const createAxiosInstance = (version: string) =>
  axios.create({
    baseURL: `${BASE_URL}/${version}`,
  });

export const batrackV1Client = createAxiosInstance('v1');

export const absScoreboardClient = axios.create({
  baseURL: `${ABS_SCOREBOARD_API_URL}`,
});

export const mlbAuthClient = axios.create({
  baseURL: `${MLBAUTH_API_URL}`,
});

export const umpireV1Client = axios.create({
  baseURL: `${UMPIRE_API_URL}`,
});

export const statsApiClient = axios.create({
  baseURL: `${STATS_API_URL}`,
});

export const lineupApiClient = axios.create({
  baseURL: `${LINEUP_URL}`,
});

export const sseApiClient = axios.create({
  baseURL: `${SSE_API_URL}`,
});
