export type RouteConstants =
  | '/'
  | '/users'
  | '/beastAuditReport'
  | '/challenges'
  | '/delayTypes'
  | '/gameChanges'
  | '/games'
  | '/lineupCards'
  | '/lineupCards/status'
  | '/login'
  | '/login/callback'
  | '/mlbAuth'
  | '/mlbAuth/sessionAuthenticator/:sessionAuthenticatorId'
  | '/playLookup'
  | '/raul'
  | '/slider'
  | '/sportId'
  | '/sse/listeners'
  | '/syncStrategy'
  | '/tabs'
  | '/notFound'
  | '*';

const RouteConstants = {
  BASE: '/' as RouteConstants,
  USERS: '/users' as RouteConstants,
  BEAST_AUDIT_REPORT: '/beastAuditReport' as RouteConstants,
  CHALLENGES: '/challenges' as RouteConstants,
  CLOCKCOM: '/clockcom' as RouteConstants,
  DELAY_TYPES: '/delayTypes' as RouteConstants,
  EXPIRIES: '/expiries' as RouteConstants,
  GAME_CHANGES: '/gameChanges' as RouteConstants,
  GAMES: '/games' as RouteConstants,
  LINEUP_CARDS: '/lineupCards' as RouteConstants,
  LINEUP_CARDS_STATUS: '/lineupCards/status' as RouteConstants,
  LOGIN: '/login' as RouteConstants,
  LOGIN_CALLBACK: '/login/callback' as RouteConstants,
  MLB_AUTH: '/mlbAuth' as RouteConstants,
  MLB_AUTH_SESSION_AUTHENTICATOR: '/mlbAuth/sessionAuthenticator/:sessionAuthenticatorId' as RouteConstants,
  PLAY_LOOKUP: '/playLookup' as RouteConstants,
  RAUL: '/raul' as RouteConstants,
  SLIDER: '/slider' as RouteConstants,
  SPORT_ID: '/sportId' as RouteConstants,
  SSE: '/sse/listeners' as RouteConstants,
  SYNC_STRATEGY: '/syncStrategy' as RouteConstants,
  TABS: '/tabs' as RouteConstants,
  NOT_FOUND: '/notFound' as RouteConstants,
  WILDCARD: '*' as RouteConstants,
};

export default RouteConstants;
