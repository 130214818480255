import React from 'react';
import {
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import StyledTableCell from '../../Global/Tables/StyledTableCell';
import StyledLinkIconButton from '../../Global/Buttons/StyledLinkIconButton';
import { ArrowDropDown, ArrowDropUp, Home } from '@material-ui/icons';
import { getResearchApiUrlForPlay, getStatsApiUrlLiveGameWithStrikeZoneAnalytics } from '../../../utils/shared-links';
import PlayDetailsDialog from '../../BatRack/PlayLookupPage/PlayDetails/PlayDetailsDialog';
import { PitchChallenge, GamePitchChallenges, PITCH_CHALLENGE_RESULT } from '../../../types/PitchChallenge';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .MuiSvgIcon-root': {
        verticalAlign: 'text-bottom',
      },
    },
  }),
);

const columnNames = [
  'Inning',
  'Challenged By',
  'Batter',
  'Pitcher',
  'Pitch Call',
  'Challenge Result',
  'Play ID',
  'Research',
  'GUIDs',
];

type TabsChallengedPlayTableProps = {
  gamePitchChallenges: GamePitchChallenges;
};

const ChallengedPlayTable: React.FC<TabsChallengedPlayTableProps> = ({ gamePitchChallenges }) => {
  const classes = useStyles();

  const pitchCall = (challenge: PitchChallenge): string => {
    if (challenge.pitchChallengeResult === PITCH_CHALLENGE_RESULT.UPHELD) {
      return challenge.playResult;
    } else {
      return challenge.playResult === 'Ball' ? 'Called Strike' : 'Ball';
    }
  };

  const pitchChallengeTeamCode = (teamId: number) => {
    return teamId === gamePitchChallenges.awayTeamId
      ? gamePitchChallenges.awayTeam.code
      : gamePitchChallenges.homeTeam.code;
  };

  return (
    <Paper className={classes.root}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columnNames.map(columnName => (
                <StyledTableCell key={columnName}>{columnName}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {gamePitchChallenges.pitchChallenges.map(challenge => (
              <TableRow key={challenge.playId}>
                <TableCell>
                  {challenge.inning}
                  {challenge.halfInning === 'top' ? <ArrowDropUp /> : <ArrowDropDown />}
                </TableCell>
                <TableCell>{pitchChallengeTeamCode(challenge.pitchChallengeTeamId)}</TableCell>
                <TableCell>
                  {challenge.batterName} {challenge.halfInning === 'bottom' ? <Home fontSize='small' /> : ''}
                </TableCell>
                <TableCell>
                  {challenge.pitcherName} {challenge.halfInning === 'top' ? <Home fontSize='small' /> : ''}
                </TableCell>
                <TableCell>{pitchCall(challenge)}</TableCell>
                <TableCell>{challenge.pitchChallengeResult}</TableCell>
                <TableCell>
                  <PlayDetailsDialog playId={challenge.playId} />
                </TableCell>
                <TableCell align='center'>
                  <StyledLinkIconButton
                    linkUrl={`${getResearchApiUrlForPlay(gamePitchChallenges.gamePk, challenge.playId)}#media=preview`}
                  />
                </TableCell>
                <TableCell align='center'>
                  <StyledLinkIconButton
                    linkUrl={getStatsApiUrlLiveGameWithStrikeZoneAnalytics(gamePitchChallenges.gamePk)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ChallengedPlayTable;
