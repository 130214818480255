import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { Cancel, CheckCircle, Help } from '@material-ui/icons';
import { LineupCardStatus } from '../../../../types/LineupCardStatus';
import LineupCardsTableHeaderCells from './LineupCardsTableHeaderCells/LineupCardsTableHeaderCells';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const useStyles = makeStyles(theme => ({
  tableContainer: {
    height: '75vh',
    [theme.breakpoints.down('xs')]: {
      margin: '25px auto',
      width: '100%',
      height: '50vh',
    },
  },
  table: {
    height: 'max-content',
    position: 'sticky',
  },
  emptyTable: {
    height: '70vh',
    [theme.breakpoints.down('xs')]: {
      height: '45vh',
    },
  },
  TableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  },
}));

type LineupCardStatusTableProps = {
  loading: boolean;
  cardStatusList: LineupCardStatus[];
  handleOpenModal(card: LineupCardStatus): void;
};

const LineupCardsTable: React.FC<LineupCardStatusTableProps> = ({ loading, cardStatusList, handleOpenModal }) => {
  const classes = useStyles();

  const determineStatusIcon = (status: boolean | undefined): JSX.Element => {
    if (status == true) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CheckCircle style={{ color: 'green' }} />
        </div>
      );
    } else if (status == false) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Cancel style={{ color: 'red' }} />
        </div>
      );
    } else {
      return <Help />;
    }
  };

  const determineRowColor = (status: boolean | undefined): string => {
    if (status == true) {
      return '';
    } else {
      return '#fcdede';
    }
  };

  return (
    <TableContainer className={classes.tableContainer}>
      <Table stickyHeader={true} size={'small'} className={classes.table}>
        <LineupCardsTableHeaderCells id={'orgId'} label={''} />
        <TableBody>
          {!loading &&
            cardStatusList.length > 0 &&
            cardStatusList.map((lc: LineupCardStatus) => (
              <TableRow
                key={lc.orgId}
                className={classes.TableRow}
                style={{ backgroundColor: determineRowColor(lc.submitted) }}
              >
                <TableCell align={'center'}>{lc.teamName}</TableCell>
                <TableCell align={'center'}>{determineStatusIcon(lc.submitted)}</TableCell>
                <TableCell align={'center'}>{determineStatusIcon(lc.generated)}</TableCell>
                {lc.version > 0 && (
                  <>
                    <TableCell align={'center'}>
                      <b>{lc.version}</b>
                    </TableCell>
                    <TableCell align={'center'}>
                      <button onClick={() => handleOpenModal(lc)}>
                        <ExitToAppIcon />
                      </button>
                    </TableCell>
                  </>
                )}
                {!loading && lc.version == 0 && (
                  <>
                    <TableCell align={'center'}>{lc.version}</TableCell>
                    <TableCell align={'center'}>N/A</TableCell>
                  </>
                )}
              </TableRow>
            ))}
          {cardStatusList.length == 0 && (
            <TableRow key='empty-table' className={classes.emptyTable}>
              <TableCell colSpan={7} align={'center'}>
                No date selected
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LineupCardsTable;
