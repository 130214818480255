import React, { useEffect, useState } from 'react';
import StyledTable, { StyledTableColumn } from '../Global/Tables/StyledTable';
import { fetchAllListeners } from '../../clients/BestSSEClient';
import { flattenListener, SseListener } from '../../types/Sse';

const SseListenersPage: React.FC = () => {
  const [sseListeners, setSseListeners] = useState<SseListener[]>([]);

  useEffect(() => {
    getData().then();
  }, []);

  const getData = async () => {
    const result: SseListener[] = await fetchAllListeners();
    setSseListeners(result);
  };

  const flattenedListeners = () => {
    return sseListeners.map(listener => {
      return flattenListener(listener);
    });
  };

  const tableColumns: StyledTableColumn[] = [
    { field: 'channel', title: 'Channel' },
    { field: 'podId', title: 'POD ID' },
    { field: 'authenticated', title: 'Authenticated' },
    { field: 'groups', title: 'Groups' },
    { field: 'ipAddress', title: 'IP Address' },
    { field: 'eventTypes', title: 'Event Types' },
    { field: 'status', title: 'Status' },
  ];

  return <StyledTable tableColumns={tableColumns} data={flattenedListeners()} />;
};

export default SseListenersPage;
