import { Play, VideoUrl } from '../../../../types/Game';
import React from 'react';
import PlayLookupCard from './PlayLookupCard';

type BatterDetailsProps = {
  play: Play;
};

const VideoUrlDetails: React.FC<BatterDetailsProps> = ({ play }) => {
  const videoUrlToString = (videoUrl: VideoUrl): string => {
    return (
      `Angle ID: ${videoUrl.angleId}\n` +
      `Start Offset: ${videoUrl.startOffset}\n` +
      `Duration: ${videoUrl.duration}\n` +
      `${videoUrl.url}\n`
    );
  };

  const listItems = new Map<string, string>([]);
  play.videoUrls.forEach(videoUrl => listItems.set(String(videoUrl.id), videoUrlToString(videoUrl)));

  return <PlayLookupCard cardTitle='Video URLs' listItems={listItems} />;
};

export default VideoUrlDetails;
