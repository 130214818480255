import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, CardHeader, Grid, Paper, Theme, Typography } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import TabsGameList from './TabsGameList';
import TabsData from './TabsData';
import { GameType } from '../../../types/Game';
import { getTabsTeams } from '../../../clients/BatRackClient';
import TabsDifferingCalls from './TabsDifferingCalls';

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    padding: spacing(2),
  },
  cardContent: {
    padding: 'unset',
    '&:last-child': {
      paddingBottom: '0',
    },
  },
}));

const TabsPage: React.FC = () => {
  const classes = useStyles();
  const [date, setDate] = useState<Date>(new Date());
  const [games, setGames] = useState<GameType[]>([]);

  const getGames = async () => {
    const dateStr = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    const result: GameType[] = await getTabsTeams(dateStr);
    setGames(result);
  };

  useEffect(() => {
    getGames();
  }, [date]);

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card className={classes.card}>
            <KeyboardDatePicker
              id='date-picker-inline'
              label='Game Date'
              required={true}
              format='MM/dd/yyyy'
              variant='inline'
              disableToolbar
              margin='normal'
              value={date}
              onChange={(date: Date | null) => {
                if (date) {
                  setDate(date);
                }
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader subheader='Schedule' />
            <CardContent className={classes.cardContent}>
              <TabsGameList games={games} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader subheader='TABS Feedback' />
            <CardContent className={classes.cardContent}>
              <TabsData games={games} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader subheader='ABS/Umpire Differences' />
            <CardContent className={classes.cardContent}>
              <TabsDifferingCalls games={games} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default TabsPage;
