import { makeStyles } from '@material-ui/core/styles';
import { createStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React, { useEffect } from 'react';
import StyledTableCell from '../../Global/Tables/StyledTableCell';
import { GameType } from '../../../types/Game';
import { getTodaysRaulGames } from '../../../clients/BatRackClient';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'inline-flex',
      height: 'calc(100% - 50px)',
      width: 'max-content',
    },
    table: {
      height: 'max-content',
      position: 'sticky',
    },
  }),
);

const RaulGamesTable: React.FC = () => {
  const classes = useStyles();

  const [games, setGames] = React.useState<GameType[]>([]);

  const columnNames = ['Game PK', 'Game Time', 'Away Team', 'Home Team', 'Venue ID'];

  useEffect(() => {
    getGames().then();
  }, []);

  const getGames = async () => {
    const data: Map<string, GameType[]> = await getTodaysRaulGames();
    // Flatten the GameType array
    const todaysGames: GameType[] = [].concat(...Object.values(data));
    // Filter flattened array looking for unique game pks
    const uniqueGames = todaysGames.filter((game, index, self) => {
      return self.findIndex(g => g.gamePk === game.gamePk) === index;
    });
    setGames(uniqueGames);
  };

  return (
    <div className={classes.container}>
      <TableContainer>
        <Table stickyHeader={true} size={'small'} className={classes.table}>
          <TableHead>
            <TableRow>
              {columnNames.map(columnName => (
                <StyledTableCell key={columnName}>{columnName}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {games.map(game => (
              <TableRow key={game.gamePk}>
                <TableCell>{game.gamePk}</TableCell>
                <TableCell>{game.gameTime}</TableCell>
                <TableCell>{game.awayTeamName.name}</TableCell>
                <TableCell>{game.homeTeamName.name}</TableCell>
                <TableCell>{game.venueId}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default RaulGamesTable;
