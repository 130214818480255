import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const challengesStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      minHeight: 'calc(100vh - 100px)',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    summary: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    summaryText: {
      display: 'inline-flex',
      [theme.breakpoints.down('xs')]: {
        display: 'block',
        textAlign: 'center',
        width: '100%',
      },
    },
    summaryWrapper: {
      [theme.breakpoints.down('xs')]: {
        marginBottom: '8px',
      },
    },
    gameListItem: {
      padding: '1rem 1rem',
      [theme.breakpoints.down('xs')]: {
        padding: '1rem 0.5rem',
      },
    },
    gameLabel: {
      backgroundColor: '#F8F8F8',
      borderBottom: '1px solid lightgrey',
      '& .MuiTypography-root': {
        fontWeight: 'bold',
      },
    },
    challengedPlayTable: {
      padding: '0.5rem',
    },
    circle: {
      width: '25px',
      height: '25px',
      lineHeight: '25px',
      borderRadius: '50%',
      fontSize: '14px',
      fontWeight: 'bold',
      color: 'white',
      textAlign: 'center',
      margin: '0px 5px 0px 10px',
      [theme.breakpoints.down('md')]: {
        width: '20px',
        height: '20px',
        lineHeight: '20px',
        fontSize: '12px',
      },
    },
    challenged: {
      backgroundColor: theme.palette.primary.dark,
    },
    noLeftMargin: {
      marginLeft: 0,
    },
    upheld: {
      backgroundColor: 'green',
    },
    overturned: {
      backgroundColor: 'crimson',
    },
    noChallengeGames: {
      display: 'flex',
      flexDirection: 'column',
      height: '50vh',
      justifyContent: 'center',
      alignItems: 'center',
    },
    gameChallengesSummaryContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
      },
    },
    gameChallengesSummaryHorizontal: {
      display: 'flex',
      flexDirection: 'row',
      marginRight: '1rem',
    },
    gameChallengesSummaryVertical: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginRight: '1rem',
    },
    gameChallengesSummaryLabel: {
      fontSize: '1rem',
      fontWeight: 'bold',
    },
    gameChallengesSummaryLabelLg: {
      fontSize: '1.125rem',
      fontWeight: 'bold',
    },
    gameChallengesSummaryLabelSymbol: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
    },
    gameChallengesSummaryText: {
      marginTop: '-0.25rem',
      fontSize: '0.85rem',
      fontWeight: 'normal',
    },
    circlesWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginRight: '15px',
    },
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      marginRight: '15px',
      [theme.breakpoints.down('md')]: {
        fontSize: '12px',
      },
    },
  }),
);
