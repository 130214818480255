import { useEffect, useState } from 'react';

function useAsyncState<T>(asyncSupplier: () => Promise<T> | null, initialState: T, dependencies: any[]) {
  const [state, setState] = useState<T>(initialState);
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let isMounted = true;
    if (typeof asyncSupplier === 'function') {
      setLoading(true);
      setError(null);
      asyncSupplier()
        .then(response => {
          // make sure the component hasn't unmounted while the asyncSupplier was waiting to be resolved
          if (isMounted) {
            setState(response);
          }
        })
        .catch(e => {
          if (isMounted) {
            console.error(e);
            setError(e);
          }
        })
        .finally(() => {
          if (isMounted) {
            setLoading(false);
          }
        });
    }

    return () => {
      isMounted = false;
    };
  }, dependencies);

  return { state, loading, error };
}

export default useAsyncState;
