import { Collapse, Divider, List, ListItem, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { getGamePitchChallengesByDateAndSportId } from '../../../clients/BatRackClient';
import {
  GamePitchChallengeRow,
  GamePitchChallenges,
  INITIAL_TEAM_CHALLENGES,
  PITCH_CHALLENGE_RESULT,
  PitchChallenge,
  PitchChallengesCount,
} from '../../../types/PitchChallenge';
import { SportId } from '../../../types/SportId';
import LoadingOverlay from '../../Global/LoadingOverlay';
import ChallengedPlayTable from './ChallengedPlayTable';
import ChallengesForm from './ChallengesForm';
import { challengesStyles } from './ChallengesStyles';
import GameChallengesSummary from './GameChallengesSummary';
import TotalChallengesSummary from './TotalChallengesSummary';

const ChallengesPage: React.FC = () => {
  const classes = challengesStyles();

  const [challengesCount, setChallengesCount] = useState<PitchChallengesCount>(INITIAL_TEAM_CHALLENGES);
  const [date, setDate] = useState<Date>(new Date());
  const [expandAllRows, setExpandAllRows] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [rows, setRows] = useState<GamePitchChallengeRow[]>([]);
  const [sportId, setSportId] = useState<SportId>();

  useEffect(() => {
    getPitchChallenges();
  }, [sportId, date]);

  const getPitchChallenges = async () => {
    if (!date || !sportId?.sportId) {
      return;
    }

    setLoading(true);

    const dateStr = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    const res = await getGamePitchChallengesByDateAndSportId(dateStr, sportId.sportId);

    const totals = { challenges: 0, overturned: 0, upheld: 0 };
    res.forEach(r => {
      r.pitchChallenges = sortChallengesByInning(r.pitchChallenges);
      totals.challenges += r.pitchChallenges.length;
      totals.overturned += r.pitchChallenges.filter(
        c => c.pitchChallengeResult === PITCH_CHALLENGE_RESULT.OVERTURNED,
      ).length;
      totals.upheld += r.pitchChallenges.filter(c => c.pitchChallengeResult === PITCH_CHALLENGE_RESULT.UPHELD).length;
    });

    setRows(res.map(row => ({ gamePitchChallenges: row, expanded: expandAllRows })));
    setChallengesCount(totals);
    setLoading(false);
  };

  const sortChallengesByInning = (challenges: PitchChallenge[]) => {
    // Sort by inning ASC and half inning DESC
    return challenges.sort((c1, c2) => {
      if (c1.inning === c2.inning) {
        return c1.halfInning === c2.halfInning ? 0 : c1.halfInning > c2.halfInning ? -1 : 1;
      }
      return c1.inning > c2.inning ? 1 : -1;
    });
  };

  const toggleRowExpanded = (row: GamePitchChallengeRow) => {
    const updatedRows = [...rows];
    updatedRows
      .filter(r => row.gamePitchChallenges.gamePk === r.gamePitchChallenges.gamePk)
      .map(r => (r.expanded = !r.expanded));
    if (rows.length === updatedRows.filter(r => r.expanded).length) {
      // All rows are expanded so update the toggle to show COLLAPSE ALL
      setExpandAllRows(true);
    } else if (rows.length === updatedRows.filter(r => !r.expanded).length) {
      // All rows are expanded so update the toggle to show EXPAND ALL
      setExpandAllRows(false);
    }
    setRows(updatedRows);
  };

  const toggleAllRowsExpanded = (toggle: boolean) => {
    setRows(rows.map(r => ({ ...r, expanded: toggle })));
    setExpandAllRows(toggle);
  };

  return (
    <Paper className={classes.paper}>
      <LoadingOverlay open={loading} />
      <ChallengesForm
        date={date}
        setDate={setDate}
        selectedSportId={sportId}
        setSelectedSportId={setSportId}
        expandAll={expandAllRows}
        expandAllClick={toggleAllRowsExpanded}
      />
      <Divider />
      <List disablePadding>
        <ListItem disableGutters className={`${classes.gameListItem} ${classes.summary}`}>
          <TotalChallengesSummary totalGames={rows.length} challengesCount={challengesCount} classes={classes} />
        </ListItem>
        {rows.map(row => (
          <div key={`${row.gamePitchChallenges.gamePk} -Root`} className={classes.gameLabel}>
            <ListItem
              disableGutters
              className={classes.gameListItem}
              button
              key={row.gamePitchChallenges.gamePk}
              onClick={() => toggleRowExpanded(row)}
            >
              <div style={{ display: 'flex', width: '100%' }}>
                <div style={{ display: 'flex', flex: 1 }}>
                  <GameChallengesSummary row={row} classes={classes} />
                </div>
              </div>
            </ListItem>
            <Collapse in={row.expanded} timeout='auto' unmountOnExit>
              <div className={classes.challengedPlayTable}>
                <ChallengedPlayTable gamePitchChallenges={row.gamePitchChallenges} />
              </div>
            </Collapse>
          </div>
        ))}
      </List>
      {!loading && rows.length === 0 && (
        <div className={classes.noChallengeGames}>
          {`${sportId?.sportName} has no ABS challenge games on ${date.toDateString()}`}
        </div>
      )}
    </Paper>
  );
};

export default ChallengesPage;
