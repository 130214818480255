import StyledTable, { StyledTableColumn } from '../../../Global/Tables/StyledTable';
import React, { useEffect, useState } from 'react';
import { DelayType } from '../../../../types/DelayType';
import { Paper } from '@material-ui/core';
import Alert from '../../../Global/Alert';
import { Color as AlertSeverity, Color } from '@material-ui/lab/Alert/Alert';
import { executeAndAlert } from '../../../../utils/restUtil';
import { getAllDelayTypes } from '../../../../clients/BatRackClient';

const DelayTypePage: React.FC = () => {
  const [alertIsOpen, setAlertIsOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = React.useState<string>('');
  const [alertSeverity, setAlertSeverity] = React.useState<Color>('success' as AlertSeverity);
  const [delayTypes, setDelayTypes] = useState<DelayType[]>([]);

  const tableColumns: StyledTableColumn[] = [
    { field: 'control', title: 'Control Type' },
    { field: 'type', title: 'Delay Type' },
    { field: 'sportId', title: 'Sport ID' },
  ];

  useEffect(() => {
    getDelayTypes().then();
  }, []);

  const getDelayTypes = async () => {
    const successfulAlertMsg = '';
    const failureAlertMsg = 'Failed to retrieve delay types from Batrack';
    const result = await executeAndAlert(
      getAllDelayTypes,
      successfulAlertMsg,
      failureAlertMsg,
      setAlertMessage,
      setAlertSeverity,
      setAlertIsOpen,
    );
    setDelayTypes((result as DelayType[]).sort(delayTypesSort));
  };

  const delayTypesSort = (a: DelayType, b: DelayType) => {
    if (a.sportId === b.sportId) {
      return a.control > b.control ? 1 : -1;
    }
    return a.sportId > b.sportId ? 1 : -1;
  };

  return (
    <Paper>
      {delayTypes?.length > 0 && <StyledTable tableColumns={tableColumns} data={delayTypes} />}
      <Alert
        open={alertIsOpen && alertMessage.length > 0}
        setOpen={setAlertIsOpen}
        message={alertMessage}
        severity={alertSeverity}
        autoHideDuration={5000}
      />
    </Paper>
  );
};

export default DelayTypePage;
