import React, { useEffect, useState } from 'react';
import { Checkbox } from '@material-ui/core';
import { GamePlays } from '../../../../types/Game';
import { getGamePlays } from '../../../../clients/BatRackClient';

type PlaysCheckProps = {
  gamePk: number;
};

const PlaysCheck: React.FC<PlaysCheckProps> = ({ gamePk }: PlaysCheckProps) => {
  const [hasPlays, setHasPlays] = useState(false);

  const checkHasPlays = async () => {
    const result: GamePlays = await getGamePlays(gamePk);
    setHasPlays(!!result.plays.length);
  };

  useEffect(() => {
    checkHasPlays();
  }, [gamePk]);

  return (
    <Checkbox
      checked={hasPlays}
      onChange={checkHasPlays}
      color='primary'
      inputProps={{ 'aria-label': 'secondary checkbox' }}
    />
  );
};

export default PlaysCheck;
