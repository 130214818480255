import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { isAfter } from 'date-fns';
import { GameType } from '../../../types/Game';
import StyledTableCell from '../../Global/Tables/StyledTableCell';
import moment from 'moment-timezone';
import PlaysCheck from './checks/PlaysCheck';
import LineupCheck from './checks/LineupCheck';
import { HasOpenedTabs } from '../../../types/FlaggedPitches';
import { getHasOpenedTabs } from '../../../clients/UmpireApiClient';
import { getResearchApiUrlGamePreview, getStatsApiUrlLiveGameFeed } from '../../../utils/shared-links';
import StyledLinkIconButton from '../../Global/Buttons/StyledLinkIconButton';
import { FilterCenterFocus, Gavel } from '@material-ui/icons';

const useStyles = makeStyles(() =>
  createStyles({
    gameTypeIcon: {
      fontSize: '1rem',
      marginLeft: '0.5rem',
    },
  }),
);

type TabsGameListProps = {
  games: GameType[];
};

const columnNames = [
  'Game PK',
  'Away',
  'Home',
  'Game Start',
  'Venue ID',
  'Sport ID',
  'Lineups',
  'Plays',
  'Home Team Opened',
  'Away Team Opened',
];

type TabsGameType = GameType & {
  gumbo: string;
  research: string;
  homeTeamOpened: boolean;
  awayTeamOpened: boolean;
};

type HasOpenedTabsMap = {
  [teamId: number]: HasOpenedTabs;
};

const TabsGameList: React.FC<TabsGameListProps> = ({ games }: TabsGameListProps) => {
  const classes = useStyles();

  const [displayGames, setDisplayGames] = useState<TabsGameType[]>([]);
  const [hasOpenedTabs, setHasOpenedTabs] = useState<HasOpenedTabsMap>({});

  const getHasOpenedData = async (gamePks: number[]) => {
    const results: HasOpenedTabs[] = await getHasOpenedTabs(gamePks);
    const map: HasOpenedTabsMap = {};
    results.forEach((result: HasOpenedTabs) => {
      map[result.hasOpenedTabsId.teamId] = result;
    });
    setHasOpenedTabs(map);
  };

  useEffect(() => {
    const gamePks: number[] = games.map((game: GameType) => game.gamePk);
    if (gamePks?.length > 0) {
      getHasOpenedData(gamePks);
    }
  }, [games]);

  useEffect(() => {
    const mappedData: TabsGameType[] = games.map((game: GameType) => ({
      ...game,
      gumbo: getStatsApiUrlLiveGameFeed(game.gamePk),
      research: getResearchApiUrlGamePreview(game.gamePk),
      homeTeamOpened: !!hasOpenedTabs[game.homeTeamStatsId],
      awayTeamOpened: !!hasOpenedTabs[game.awayTeamStatsId],
    }));
    setDisplayGames(mappedData);
  }, [games, hasOpenedTabs]);

  return (
    <Paper>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columnNames.map(columnName => (
                <StyledTableCell key={columnName}>{columnName}</StyledTableCell>
              ))}
              <StyledTableCell align='center' key='GUMBO'>
                GUMBO
              </StyledTableCell>
              <StyledTableCell align='center' key='Research'>
                Research
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayGames
              .sort((game1: TabsGameType, game2: TabsGameType) => (isAfter(game1.gameTime, game2.gameTime) ? -1 : 1))
              .map((game: TabsGameType) => (
                <TableRow key={game.gamePk}>
                  <TableCell>
                    {game.gamePk}
                    {game.isAbsChallengeGame ? (
                      <Tooltip title='ABS Challenge Game'>
                        <Gavel className={classes.gameTypeIcon} />
                      </Tooltip>
                    ) : (
                      <Tooltip title='ABS Game'>
                        <FilterCenterFocus className={classes.gameTypeIcon} />
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell>{game.awayTeamName.name}</TableCell>
                  <TableCell>{game.homeTeamName.name}</TableCell>
                  <TableCell>
                    {moment
                      .utc(game.gameTime)
                      .tz('America/New_York')
                      .format('MM/DD/YYYY hh:mm a z')}
                  </TableCell>
                  <TableCell>{game.venueId}</TableCell>
                  <TableCell>{game.sportId.sportId}</TableCell>
                  <TableCell>
                    <LineupCheck gamePk={game.gamePk} />
                  </TableCell>
                  <TableCell>
                    <PlaysCheck gamePk={game.gamePk} />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={game.homeTeamOpened}
                      onChange={() => console.log('ignore')}
                      color='primary'
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={game.awayTeamOpened}
                      onChange={() => console.log('ignore')}
                      color='primary'
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </TableCell>
                  <TableCell align='center'>
                    <StyledLinkIconButton linkUrl={game.gumbo} />
                  </TableCell>
                  <TableCell align='center'>
                    <StyledLinkIconButton linkUrl={game.research} />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TabsGameList;
