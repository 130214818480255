import React from 'react';
import { Redirect } from 'react-router-dom';
import mlbLogo from '../../assets/mlb-auth-logo.png';
import { Button, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { oktaAuth, useAuth } from '../../context/AuthContext';
import RouteConstants from '../../constants/RouteConstants';

const useStyles = makeStyles({
  wrapper: {
    backgroundColor: '#EEEEF5FF',
  },
  root: {
    minWidth: 275,
  },
  title: {
    textAlign: 'center',
  },
  contentWrapper: {
    gap: '8px',
  },
});

const Login = () => {
  const { state: authState } = useAuth();
  const classes = useStyles();

  const loginFunc = () => {
    oktaAuth.signInWithRedirect({ originalUri: RouteConstants.BASE });
  };

  return (
    <div className={classes.wrapper}>
      {authState!.loggedIn ? (
        <Redirect to='/' />
      ) : (
        <Grid
          container
          spacing={0}
          direction='column'
          alignItems='center'
          style={{ minHeight: '100vh', justifyContent: 'center' }}
        >
          <Card className={classes.root} variant='outlined'>
            <CardContent>
              <Grid container direction='column' alignItems='center' className={classes.contentWrapper}>
                <img src={mlbLogo} alt='MLB' width={150} />
                <Typography variant='h5' className={classes.title}>
                  BEST Admin
                </Typography>
                <Button onClick={loginFunc}>Log in with MLB SSO</Button>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}
    </div>
  );
};

export default Login;
