import Moment from 'moment';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const formatDate = (date: Moment.MomentInput, format = 'MM/DD/YYYY') => {
  const m = Moment.isMoment(date) ? date : Moment(date);

  if (format.includes('z')) {
    return Moment(date)
      .tz(Moment.tz.guess())
      .format(format);
  }

  return m.format(format);
};
