import { Play } from '../../../../types/Game';
import React from 'react';
import PlayLookupCard from './PlayLookupCard';

type BatterDetailsProps = {
  play: Play;
};

const BatterDetails: React.FC<BatterDetailsProps> = ({ play }) => {
  const listItems = new Map<string, string | number | boolean>([
    ['Batter', `${play.batterName} (${play.batterId})`],
    ['Bat Side', play.batSide],
    ['Exit Velocity', play.hitData?.exitVelocity],
    ['Launch Angle', play.hitData?.launchAngle],
    ['Distance', play.hitData?.distance],
    ['At Bat Index', play.playIndex?.atBatIndex],
    ['At Bat Result', play.atBatResult],
    ['At Bat Result Type', play.atBatResultType],
  ]);

  return <PlayLookupCard cardTitle='Batter Details' listItems={listItems} />;
};

export default BatterDetails;
