import { createStyles, TableCell, Theme, withStyles } from '@material-ui/core';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      fontWeight: 600,
    },
  }),
)(TableCell);

export default StyledTableCell;
