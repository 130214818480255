import React, { useEffect, useState } from 'react';
import { BrowserRouter, useHistory, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import BatrackHttpInterceptor from './components/Authentication/BatrackHttpInterceptor';
import BatRackHeader from './components/BatRackHeader/BatRackHeader';
import theme from './utils/theme';
import CssBaseline from '@material-ui/core/CssBaseline';
import UmpireApiHttpInterceptor from './components/Authentication/UmpireApiHttpInterceptor';
import { AuthProvider, oktaAuth, useAuth } from './context/AuthContext';
import { Security } from '@okta/okta-react';
import AuthenticatedApp from './apps/AuthenticatedApp';
import UnauthenticatedApp from './apps/UnauthenticatedApp';
import { OktaAuth } from '@okta/okta-auth-js';
import { RolesProvider } from './context/RoleContext';
import LineupHttpInterceptor from './components/Authentication/LineupHttpInterceptor';

const AppCheck = () => {
  const history = useHistory();
  const location = useLocation();
  const { state } = useAuth();
  const [hasRedirected, setHasRedirected] = useState(false);
  const [pathname, setPathname] = useState('');

  useEffect(() => {
    if (!pathname.length) {
      setPathname(location.pathname + location.search);
    }
    if (state?.loggedIn && !hasRedirected && !!pathname.length) {
      history.push(pathname);
      setHasRedirected(true);
    }
  }, [state, location.pathname]);

  return <>{state?.loggedIn ? <AuthenticatedApp /> : <UnauthenticatedApp />}</>;
};

const BatRackAdmin = () => {
  const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
    window.location.replace(originalUri);
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <AuthProvider>
            <RolesProvider>
              <BatrackHttpInterceptor>
                <LineupHttpInterceptor>
                  <UmpireApiHttpInterceptor>
                    <CssBaseline />
                    <BrowserRouter>
                      <BatRackHeader />
                      <AppCheck />
                    </BrowserRouter>
                  </UmpireApiHttpInterceptor>
                </LineupHttpInterceptor>
              </BatrackHttpInterceptor>
            </RolesProvider>
          </AuthProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Security>
  );
};

export default BatRackAdmin;
