import React from 'react';

import { Checkbox, MenuItem, Select, TableCell, Tooltip } from '@material-ui/core';
import { Color as AlertSeverity } from '@material-ui/lab/Alert';

import { updateInGameVideoAllowed, updateSyncStatusOverride } from '../../../clients/BatRackClient';
import { SyncStatusOverride } from '../../../types/SyncStatusOverride';
import { Team } from '../../../types/Team';
import Alert from '../../Global/Alert';
import moment from 'moment-timezone';

type SyncStatusCellsProps = Pick<
  Team,
  | 'code'
  | 'syncAllowed'
  | 'inGameVideoAllowed'
  | 'syncStatusOverride'
  | 'playsCount'
  | 'videoCount'
  | 'nonPitchEventsCount'
> & {
  refreshTeams: () => Promise<void>;
};

const SyncStatusCells = ({
  code,
  syncAllowed,
  inGameVideoAllowed,
  syncStatusOverride,
  refreshTeams,
  playsCount,
  videoCount,
  nonPitchEventsCount,
}: SyncStatusCellsProps) => {
  const [alertIsOpen, setAlertIsOpen] = React.useState(false);
  const [alertSeverity, setAlertSeverity] = React.useState('success' as AlertSeverity);

  const handleSyncStatusOverrideChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    try {
      const override = event.target.value as SyncStatusOverride;
      await updateSyncStatusOverride(code, override);
      setAlertSeverity('success');
    } catch (error) {
      if ([401, 403].includes(error.response?.status)) {
        return;
      }
      setAlertSeverity('error');
    }
    setAlertIsOpen(true);
    refreshTeams();
  };

  const handleVideoChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      await updateInGameVideoAllowed(code, event.target.checked);
      setAlertSeverity('success');
    } catch (error) {
      if ([401, 403].includes(error.response?.status)) {
        return;
      }
      setAlertSeverity('error');
    }
    setAlertIsOpen(true);
    refreshTeams();
  };

  return (
    <>
      <TableCell>
        <Tooltip title={`Non Pitch Events: ${nonPitchEventsCount}`} placement='top-start'>
          <p>{playsCount === 0 ? '' : playsCount}</p>
        </Tooltip>
      </TableCell>
      <TableCell>
        <p>{videoCount === 0 ? '' : videoCount}</p>
      </TableCell>
      <TableCell>{syncAllowed ? 'Allowed' : 'Denied'}</TableCell>
      <TableCell>
        <Select
          margin='dense'
          style={{ minWidth: 135 }}
          variant='outlined'
          value={syncStatusOverride}
          onChange={handleSyncStatusOverrideChange}
        >
          <MenuItem value={SyncStatusOverride.NO_OVERRIDE}>No override</MenuItem>
          <MenuItem value={SyncStatusOverride.ALLOW_SYNC}>Allow sync</MenuItem>
          <MenuItem value={SyncStatusOverride.DENY_SYNC}>Deny sync</MenuItem>
        </Select>
        <Alert
          open={alertIsOpen}
          setOpen={setAlertIsOpen}
          message={`${alertSeverity === 'success' ? 'Successfully updated' : 'Error updating'} ${code}`}
          severity={alertSeverity}
          autoHideDuration={5000}
        />
      </TableCell>
      <TableCell>
        <Checkbox
          checked={inGameVideoAllowed}
          onChange={handleVideoChange}
          color='primary'
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
      </TableCell>
    </>
  );
};

export default SyncStatusCells;
