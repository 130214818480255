import RouteConstants from './RouteConstants';
import GroupIcon from '@material-ui/icons/Group';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import TabletIcon from '@material-ui/icons/Tablet';
import SportsBaseballIcon from '@material-ui/icons/SportsBaseball';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import HearingIcon from '@material-ui/icons/HearingOutlined';
import EventIcon from '@material-ui/icons/Event';
import { createElement } from 'react';
import SyncIcon from '@material-ui/icons/Sync';
import { NavigationMenuItem } from '../types/NavigationMenu';
import {
  AccessTime,
  Fastfood,
  Gavel,
  HourglassEmpty,
  Person,
  PlayArrow,
  Watch,
  Alarm,
  Update,
} from '@material-ui/icons';
import ChallengesPage from '../components/ABS/Challenges/ChallengesPage';
import TabsPage from '../components/ABS/Tabs/TabsPage';
import GamesPage from '../components/BatRack/GamesPage/GamesPage';
import SportIDPage from '../components/BatRack/SportID/SportIDPage';
import SyncStrategyPage from '../components/BatRack/SyncStrategyPage/SyncStrategyPage';
import TeamsTable from '../components/Dugout/TeamsTable/TeamsTable';
import LineupCardsPage from '../components/LineupCards/LineupCardsPage/LineupCardsPage';
import { Roles } from './RolesConstants';
import UsersPage from '../components/Admin/UsersPage';
import BeastAuditReportPage from '../components/Batter/BeastAuditReport/BeastAuditReportPage';
import RaulPage from '../components/Pitchclock/Raul/RaulPage';
import PlayLookupPage from '../components/BatRack/PlayLookupPage/PlayLookupPage';
import DelayTypePage from '../components/BatRack/GamesPage/DelayTypePage/DelayTypePage';
import SliderPage from '../components/ABS/Slider/SliderPage';
import ClockcomPage from '../components/Pitchclock/Clockcom/ClockcomPage';
import ExpiriesPage from '../components/Pitchclock/Expiries/ExpiriesPage';
import LineupCardsStatus from '../components/LineupCards/StatusPage/LineupCardsStatus';
import SseListenersPage from '../components/SSE/SseListenersPage';
import GameChangesPage from '../components/Batter/GameChanges/GameChangesPage';

const BatRackNavigationMenu: NavigationMenuItem[] = [
  {
    label: 'Admin',
    open: true,
    appRole: Roles.BATRACK_SUPER_ADMIN,
    pages: [
      {
        name: 'Users',
        component: createElement(UsersPage),
        route: RouteConstants.USERS,
        icon: createElement(Person),
      },
    ],
  },
  {
    label: 'ABS',
    open: true,
    appRole: Roles.BATRACK_ABS_USER,
    pages: [
      {
        name: 'Challenges',
        component: createElement(ChallengesPage),
        route: RouteConstants.CHALLENGES,
        icon: createElement(Gavel),
      },
      {
        name: 'SLIDER',
        component: createElement(SliderPage),
        route: RouteConstants.SLIDER,
        icon: createElement(Fastfood),
      },
      { name: 'TABS', component: createElement(TabsPage), route: RouteConstants.TABS, icon: createElement(TabletIcon) },
    ],
  },
  {
    label: 'BatRack',
    open: true,
    appRole: Roles.BATRACK_BATRACK_USER,
    pages: [
      {
        name: 'Delay Types',
        component: createElement(DelayTypePage),
        route: RouteConstants.DELAY_TYPES,
        icon: createElement(AccessTime),
      },
      {
        name: 'Games',
        component: createElement(GamesPage),
        route: RouteConstants.GAMES,
        icon: createElement(LiveTvIcon),
      },
      {
        name: 'Play Lookup',
        component: createElement(PlayLookupPage),
        route: RouteConstants.PLAY_LOOKUP,
        icon: createElement(PlayArrow),
      },
      {
        name: 'Sport IDs',
        component: createElement(SportIDPage),
        route: RouteConstants.SPORT_ID,
        icon: createElement(SportsBaseballIcon),
      },
      {
        name: 'Sync Strategy',
        component: createElement(SyncStrategyPage),
        route: RouteConstants.SYNC_STRATEGY,
        icon: createElement(SyncIcon),
      },
    ],
  },
  {
    label: 'Batter',
    open: true,
    appRole: Roles.BATRACK_BATTER_USER,
    pages: [
      {
        name: 'Beast Audit Report',
        component: createElement(BeastAuditReportPage),
        route: RouteConstants.BEAST_AUDIT_REPORT,
        icon: createElement(EventIcon),
      },
      {
        name: 'Game Changes',
        component: createElement(GameChangesPage),
        route: RouteConstants.GAME_CHANGES,
        icon: createElement(Update),
      },
    ],
  },
  {
    label: 'Dugout',
    open: true,
    appRole: Roles.BATRACK_DUGOUT_USER,
    pages: [
      {
        name: 'Teams',
        component: createElement(TeamsTable),
        route: RouteConstants.BASE,
        icon: createElement(GroupIcon),
      },
    ],
  },
  {
    label: 'Lineup Cards',
    open: true,
    appRole: Roles.BATRACK_LINEUP_CARDS_USER,
    pages: [
      {
        name: 'Lineup Cards',
        component: createElement(LineupCardsPage),
        route: RouteConstants.LINEUP_CARDS,
        icon: createElement(AccountBoxOutlinedIcon),
      },
      {
        name: 'Status',
        component: createElement(LineupCardsStatus),
        route: RouteConstants.LINEUP_CARDS_STATUS,
        icon: createElement(InfoOutlinedIcon),
      },
    ],
  },
  // {
  //   label: 'MLB Auth',
  //   open: true,
  //   appRole: Roles.BATRACK_MLB_AUTH_USER,
  //   pages: [
  //     {
  //       name: 'MLB Auth',
  //       component: createElement(MLBAuthPage),
  //       route: RouteConstants.MLB_AUTH,
  //       icon: createElement(AssignmentTurnedInIcon),
  //     },
  //   ],
  // },
  {
    label: 'Pitch Clock',
    open: true,
    appRole: Roles.BATRACK_PITCH_CLOCK_USER,
    pages: [
      {
        name: 'ClockCom',
        component: createElement(ClockcomPage),
        route: RouteConstants.CLOCKCOM,
        icon: createElement(Alarm),
      },
      {
        name: 'Expiries',
        component: createElement(ExpiriesPage),
        route: RouteConstants.EXPIRIES,
        icon: createElement(HourglassEmpty),
      },
      {
        name: 'RAUL',
        component: createElement(RaulPage),
        route: RouteConstants.RAUL,
        icon: createElement(Watch),
      },
    ],
  },
  {
    label: 'SSE',
    open: true,
    appRole: Roles.BATRACK_USER,
    pages: [
      {
        name: 'Listeners',
        component: createElement(SseListenersPage),
        route: RouteConstants.SSE,
        icon: createElement(HearingIcon),
      },
    ],
  },
];

export default BatRackNavigationMenu;
