import React from 'react';
import RouteConstants from '../constants/RouteConstants';
import { Redirect, Route, Switch } from 'react-router-dom';
import { LoginCallback } from '@okta/okta-react';
import Login from '../components/Authentication/Login';

const UnauthenticatedApp: React.FC = () => {
  return (
    <Switch>
      <Route path={RouteConstants.LOGIN} exact component={Login} />
      <Route path={RouteConstants.LOGIN_CALLBACK} exact component={LoginCallback} />
      <Redirect from={RouteConstants.WILDCARD} to={RouteConstants.LOGIN} />
    </Switch>
  );
};

export default UnauthenticatedApp;
