import React, { useEffect, useState } from 'react';
import UsersGrid from './UsersGrid/UsersGrid';
import { UserInfo } from '../../types/UserInfo';
import { getAllUsersInfo, importsOktaUsers, updateUserInfo } from '../../clients/BatRackClient';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Paper, Theme } from '@material-ui/core';
import { cloneDeep, isEqual } from 'lodash';
import Alert from '../Global/Alert';
import { executeAndAlert, executeWithParams } from '../../utils/restUtil';
import { Color, Color as AlertSeverity } from '@material-ui/lab/Alert/Alert';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '80vh',
  },
  paper: {
    width: '100%',
    height: '100%',
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
    '& .MuiButton-root': {
      margin: '1rem 0.25rem',
    },
  },
}));

const UsersPage: React.FC = () => {
  const classes = useStyles();

  const [alertIsOpen, setAlertIsOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = React.useState<string>('');
  const [alertSeverity, setAlertSeverity] = React.useState<Color>('success' as AlertSeverity);
  const [rowData, setRowData] = useState<UserInfo[]>([]);
  const [usersInfo, setUsersInfo] = useState<UserInfo[]>([]);
  const [userInfoUpdates, setUserInfoUpdates] = useState<UserInfo[]>([]);

  useEffect(() => {
    getUsersInfo().then();
  }, []);

  useEffect(() => {
    // Pass a copy of users info to the user info grid to keep track of edits
    setRowData(cloneDeep(usersInfo));
  }, [usersInfo]);

  const addUserInfoUpdate = (userInfoUpdate: UserInfo): void => {
    // Remove previous update for the user if it exists
    const currentUpdates = userInfoUpdates;
    const previousUpdate = userInfoUpdates.findIndex(u => u.accountId === userInfoUpdate.accountId);
    if (previousUpdate !== -1) {
      currentUpdates.splice(previousUpdate, 1);
    }
    // Add the new update if it is different from the original
    const originalUserInfo = usersInfo.filter(u => u.accountId === userInfoUpdate.accountId)?.[0];
    if (!isEqual(originalUserInfo, userInfoUpdate)) {
      currentUpdates.push(userInfoUpdate);
    }
    setUserInfoUpdates(cloneDeep(currentUpdates));
  };

  const getUsersInfo = async () => {
    const responseData = await request(getAllUsersInfo, '', 'Failed to retrieve users');
    setUsersInfo(responseData?.length > 0 ? responseData : []);
  };

  const importUsers = async () => {
    await request(importsOktaUsers, 'Successfully imported users', 'Failed to import users');
    await getUsersInfo();
  };

  const request = async (
    call: () => Promise<any>,
    successfulAlertMsg: string,
    failureAlertMsg: string,
  ): Promise<any> => {
    return await executeAndAlert(
      call,
      successfulAlertMsg,
      failureAlertMsg,
      setAlertMessage,
      setAlertSeverity,
      setAlertIsOpen,
    );
  };

  const submitUpdates = async () => {
    const failedUpdates = [];
    for (const userInfoUpdate of userInfoUpdates) {
      const response = await executeWithParams(updateUserInfo, userInfoUpdate);
      if (response === undefined || response.status < 200 || response.status > 299) {
        console.log('Error response: ', response);
        failedUpdates.push(userInfoUpdate.email);
      }
    }
    if (failedUpdates.length > 0) {
      const alertMsg = `Failed to update users ${failedUpdates.join(', ')}`;
      setAlertMessage(alertMsg);
      setAlertIsOpen(true);
    } else {
      setAlertMessage('Successfully updated user(s)');
      setAlertSeverity('success' as AlertSeverity);
      setAlertIsOpen(true);
    }
    setUserInfoUpdates([]);
    await getUsersInfo();
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.btnContainer}>
          <Button variant='contained' color='primary' onClick={importUsers}>
            Import Users
          </Button>
          <Button variant='contained' color='primary' disabled={userInfoUpdates?.length === 0} onClick={submitUpdates}>
            Submit Updates
          </Button>
        </div>
        <UsersGrid rowData={rowData} addUserInfoUpdate={addUserInfoUpdate} />
      </Paper>
      <Alert
        open={alertIsOpen && alertMessage.length > 0}
        setOpen={setAlertIsOpen}
        message={alertMessage}
        severity={alertSeverity}
        autoHideDuration={5000}
      />
    </div>
  );
};

export default UsersPage;
