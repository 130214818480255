import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: '8px',
  },
  grid: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
}));
const NotFoundPage: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid item xs={12} className={classes.grid}>
        This page does not exist or you do not have permission to view it. Please contact a BEST Admin.
      </Grid>
    </div>
  );
};

export default NotFoundPage;
