import { Venue } from '../../../types/Venue';
import { Button, createStyles, TextField, Theme } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { postNewRaulVenue } from '../../../clients/BatRackClient';
import { Color as AlertSeverity } from '@material-ui/lab/Alert/Alert';
import Alert from '../../Global/Alert';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      alignItems: 'center',
      display: 'inline-flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      padding: '1rem 1rem',
      [theme.breakpoints.down('xs')]: {
        marginTop: '2rem',
      },
    },
    venueSelector: {
      width: '300px',
    },
    submitButton: {
      margin: '1rem 0',
      width: 'fit-content',
    },
  }),
);

type AddRaulVenueProps = {
  venueOpts: Venue[];
  refreshVenues: () => Promise<void>;
};

const AddRaulVenue: React.FC<AddRaulVenueProps> = ({ venueOpts, refreshVenues }) => {
  const classes = useStyles();

  const [venueToAdd, setVenueToAdd] = useState<Venue>();
  const [alertIsOpen, setAlertIsOpen] = React.useState(false);
  const [alertSeverity, setAlertSeverity] = React.useState('success' as AlertSeverity);

  useEffect(() => {
    if (venueOpts?.length > 0) {
      setVenueToAdd(venueOpts[0]);
    }
  }, [venueOpts]);

  const venueSelected = (event: ChangeEvent<{}>, venue: Venue | null) => {
    if (venue) {
      setVenueToAdd(venue);
    }
  };

  const addRaulVenue = async () => {
    try {
      if (venueToAdd) {
        await postNewRaulVenue(venueToAdd);
        setAlertSeverity('success');
      }
    } catch (error) {
      if ([401, 403].includes(error.response?.status)) {
        return;
      }
      setAlertSeverity('error');
    }
    await refreshVenues();
    setAlertIsOpen(true);
  };

  return (
    <div className={classes.container}>
      {venueOpts.length > 0 && venueToAdd && (
        <Autocomplete
          className={classes.venueSelector}
          value={venueToAdd}
          onChange={venueSelected}
          renderInput={params => <TextField {...params} label='Add RAUL Venue' variant='outlined' />}
          options={venueOpts}
          getOptionLabel={venue => `${venue.id} - ${venue.name}`}
          getOptionSelected={(option, value) => option.id === value.id}
        />
      )}
      <Button className={classes.submitButton} variant='contained' color='primary' onClick={addRaulVenue}>
        Submit
      </Button>
      <Alert
        open={alertIsOpen}
        setOpen={setAlertIsOpen}
        message={`${alertSeverity === 'success' ? 'Venue Added Successfully' : 'Venue Add Failed'}`}
        severity={alertSeverity}
        autoHideDuration={5000}
      />
    </div>
  );
};

export default AddRaulVenue;
