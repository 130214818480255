import React from 'react';
import { LocalStorageError } from '../../types/Error';
import { lineupApiClient } from '../../utils/axios';
import { WrapperProps } from '../../context/AuthContext';

const LineupHttpInterceptor = ({ children }: WrapperProps) => {
  lineupApiClient.interceptors.request.use(request => {
    const oktaToken = localStorage.getItem('okta-token-storage');

    if (!oktaToken) {
      throw new LocalStorageError('Cannot find okta-token-storage.');
    }

    const { accessToken } = JSON.parse(oktaToken);

    if (!accessToken?.accessToken) {
      throw new LocalStorageError('Failed to parse accessToken.');
    }

    request.headers.Authorization = `Bearer ${accessToken.accessToken}`;

    return request;
  });

  return <>{children}</>;
};

export default LineupHttpInterceptor;
