import React, { useState } from 'react';
import { Button, Paper, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PlayLookupDetails from './PlayLookupDetails';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: '80vh',
  },
  paper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
    maxWidth: '700px',
    padding: '1rem',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  gridContainer: {
    height: '80vvh',
  },
  textField: {
    width: '350px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  button: {
    margin: '0 0.5rem 0 1rem',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: 'unset',
      marginTop: '0.5rem',
    },
  },
}));

const PlayLookupPage: React.FC = () => {
  const classes = useStyles();

  const [playIdFormValue, setPlayIdFormValue] = useState<string>('');
  const [playId, setPlayId] = useState<string>('');

  const handleSubmit = () => {
    setPlayId(playIdFormValue);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TextField
          className={classes.textField}
          id='outlined-basic'
          label='Play ID'
          variant='outlined'
          size='small'
          value={playIdFormValue}
          onChange={e => setPlayIdFormValue(e.target.value)}
        />
        <Button
          className={classes.button}
          variant='contained'
          color='primary'
          onClick={handleSubmit}
          disabled={playIdFormValue?.length === 0}
        >
          Submit
        </Button>
      </Paper>
      {playId.length > 0 && <PlayLookupDetails playId={playId} />}
    </div>
  );
};

export default PlayLookupPage;
