import React, { useEffect, useState } from 'react';
import { EbisPerson, LineupCard, PlayerStatus, StatsPerson } from '../../../../types/LineupCard';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { Cancel, CheckCircle, Help, Stars } from '@material-ui/icons';
import LineupCardsTableHeaderCells, {
  getComparator,
  Order,
} from './LineupCardsTableHeaderCells/LineupCardsTableHeaderCells';
import LineupCardsTableFooter from './LineupCardsTableFooter/LineupCardsTableFooter';

const useStyles = makeStyles(theme => ({
  tableContainer: {
    height: '75vh',
    [theme.breakpoints.down('xs')]: {
      margin: '25px auto',
      width: '100%',
      height: '50vh',
    },
  },
  table: {
    height: 'max-content',
    position: 'sticky',
  },
  emptyTable: {
    height: '70vh',
    [theme.breakpoints.down('xs')]: {
      height: '45vh',
    },
  },
  playerPresent: {
    color: 'green',
  },
  playerNotPresent: {
    color: 'red',
  },
  playerUnknown: {
    color: 'lightgrey',
  },
  playerActive: {
    color: 'green',
  },
  playerOptioned: {
    color: theme.palette.primary.dark,
  },
}));

type LineupCardsTableProps = {
  loading: boolean;
  statsRoster: StatsPerson[];
  ebisRoster: EbisPerson[] | undefined;
  tableDisplayOpt: string;
  showOptionedPlayers: boolean;
  showStatsInfo: boolean;
};

const LineupCardsTable: React.FC<LineupCardsTableProps> = ({
  loading,
  statsRoster,
  ebisRoster,
  tableDisplayOpt,
  showOptionedPlayers,
  showStatsInfo,
}) => {
  const classes = useStyles();
  const [lineupCards, setLineupCards] = useState<LineupCard[]>([]);
  const [displayedLineupCards, setDisplayedLineupCards] = useState<LineupCard[]>([]);
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof LineupCard>('lastName');

  useEffect(() => {
    compareRosters();
  }, [statsRoster, ebisRoster]);

  useEffect(() => {
    updateDisplayedLineupCards();
  }, [lineupCards, tableDisplayOpt, showOptionedPlayers]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof LineupCard) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const compareRosters = (): void => {
    const lineupCardsCompared: LineupCard[] = [];
    statsRoster.forEach((person: StatsPerson) => {
      const inEbis = PlayerStatus.NotPresent; // Value will be checked in ebisRoster loop below
      const statsInfo: LineupCard = buildLineupCardFromStatsPerson(person, PlayerStatus.Present, inEbis);
      lineupCardsCompared.push(statsInfo);
    });
    ebisRoster?.forEach((person: EbisPerson) => {
      const lineupCard = lineupCardsCompared.find(lc => lc.statsId === person.statsPlayerId);
      if (lineupCard) {
        // Update existing lineupcard with ebis information
        lineupCard.ebisId = person.ebisId;
        lineupCard.inEbis = PlayerStatus.Present;
        lineupCard.pitcher = person.pitcher;
        lineupCard.twp = person.twp;
        lineupCard.rosterStatus = person.rosterStatus;
      } else {
        // Create new lineup card from ebis information
        const inStats = statsRoster.length > 0 ? PlayerStatus.NotPresent : PlayerStatus.Unknown;
        const ebisInfo: LineupCard = buildLineupCardFromEbisPerson(person, inStats, PlayerStatus.Present);
        lineupCardsCompared.push(ebisInfo);
      }
    });
    setLineupCards(lineupCardsCompared);
  };

  const buildLineupCardFromStatsPerson = (p: StatsPerson, inStats: PlayerStatus, inEbis: PlayerStatus): LineupCard => {
    return {
      firstName: p.firstName,
      lastName: p.lastName,
      statsId: p.id,
      inStats: inStats,
      inEbis: inEbis,
    };
  };

  const buildLineupCardFromEbisPerson = (p: EbisPerson, inStats: PlayerStatus, inEbis: PlayerStatus): LineupCard => {
    return {
      firstName: p.preferredName,
      lastName: p.lastName,
      pitcher: p.pitcher,
      twp: p.twp,
      rosterStatus: p.rosterStatus,
      statsId: p.statsPlayerId,
      ebisId: p.ebisId,
      inStats: inStats,
      inEbis: inEbis,
    };
  };

  const updateDisplayedLineupCards = (): void => {
    let updatedDisplayedLineupCards = handleTableDisplayOptChange();
    if (!showOptionedPlayers) {
      updatedDisplayedLineupCards = updatedDisplayedLineupCards.filter(
        lc => lc.rosterStatus === undefined || lc.rosterStatus === 'Active',
      );
    }
    setDisplayedLineupCards(updatedDisplayedLineupCards);
  };

  const handleTableDisplayOptChange = (): LineupCard[] => {
    switch (tableDisplayOpt) {
      case 'all': {
        return lineupCards;
      }
      case 'ebisRoster': {
        return lineupCards.filter(lc => lc.inEbis);
      }
      case 'statsRoster': {
        return showStatsInfo ? lineupCards.filter(lc => lc.inStats) : [];
      }
      case 'diffs': {
        return lineupCards.filter(lc => lc.inStats !== lc.inEbis);
      }
      default: {
        return lineupCards;
      }
    }
  };

  const determineStatusIcon = (status: PlayerStatus | undefined): JSX.Element => {
    switch (status) {
      case PlayerStatus.Present: {
        return <CheckCircle className={classes.playerPresent} />;
      }
      case PlayerStatus.NotPresent: {
        return <Cancel className={classes.playerNotPresent} />;
      }
      default: {
        return <Help className={classes.playerUnknown} />;
      }
    }
  };

  const determineStatsIcon = (status: PlayerStatus | undefined): JSX.Element => {
    if (showStatsInfo) {
      determineStatusIcon(status);
    }
    return <></>;
  };

  const determineRosterStatusIcon = (status: string | undefined): JSX.Element => {
    switch (status) {
      case 'Active': {
        return <CheckCircle className={classes.playerActive} />;
      }
      default: {
        if (status) {
          return <Stars className={classes.playerOptioned} />;
        }
        return <Help className={classes.playerUnknown} />;
      }
    }
  };

  const emptyTableMessage = () => {
    switch (tableDisplayOpt) {
      case 'diffs': {
        return 'No differences';
      }
      case 'statsRoster': {
        return 'No stats roster';
      }
      default: {
        return 'No team selected';
      }
    }
  };

  return (
    <TableContainer className={classes.tableContainer}>
      <Table stickyHeader={true} size={'small'} className={classes.table}>
        <LineupCardsTableHeaderCells onRequestSort={handleRequestSort} order={order} orderBy={orderBy} />
        <TableBody>
          {!loading &&
            !!displayedLineupCards?.length &&
            displayedLineupCards.sort(getComparator(order, orderBy)).map((lc: LineupCard) => (
              <TableRow key={lc.statsId}>
                <TableCell>{lc.firstName}</TableCell>
                <TableCell>{lc.lastName}</TableCell>
                <TableCell>{showStatsInfo ? lc.statsId : ''}</TableCell>
                <TableCell>{lc.ebisId}</TableCell>
                <TableCell align={'center'}>{determineStatsIcon(lc.inStats)}</TableCell>
                <TableCell align={'center'}>{determineStatusIcon(lc.inEbis)}</TableCell>
                <TableCell align={'center'}>{determineRosterStatusIcon(lc.rosterStatus)}</TableCell>
              </TableRow>
            ))}
          {!loading && displayedLineupCards.length === 0 && (
            <TableRow key='empty-table' className={classes.emptyTable}>
              <TableCell colSpan={7} align={'center'}>
                {emptyTableMessage()}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <LineupCardsTableFooter lineupCards={displayedLineupCards} />
      </Table>
    </TableContainer>
  );
};

export default LineupCardsTable;
