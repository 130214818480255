import { Game, RawGame } from './Game';
import { SyncStatusOverride } from './SyncStatusOverride';
import { TeamName } from './TeamName';

export interface RawTeam {
  team: TeamName;
  division: string;
  games: RawGame[];
  syncAllowed: boolean;
  inGameVideoAllowed: boolean;
  syncStatusOverride: SyncStatusOverride;
  playsCount: number;
  videoCount: number;
  nonPitchEventsCount: number;
}

export class Team {
  constructor(
    private name: string,
    private code: string,
    public division: string,
    private games: Game[],
    private syncAllowed: boolean,
    private inGameVideoAllowed: boolean,
    private syncStatusOverride: SyncStatusOverride,
    private playsCount: number,
    private videoCount: number,
    private nonPitchEventsCount: number,
  ) {}

  static fromRawTeam(rawTeam: RawTeam) {
    return new Team(
      rawTeam.team.name,
      rawTeam.team.code,
      rawTeam.division,
      rawTeam.games.map(game => Game.fromRawGame(game)),
      rawTeam.syncAllowed,
      rawTeam.inGameVideoAllowed,
      rawTeam.syncStatusOverride,
      rawTeam.playsCount,
      rawTeam.videoCount,
      rawTeam.nonPitchEventsCount,
    );
  }
}
