import React, { useState } from 'react';
import BeastAuditReportForm from './BeastAuditReportForm';
import { Paper } from '@material-ui/core';
import { BeastAuditReportRow } from '../../../types/BeastAuditReport';
import LoadingOverlay from '../../Global/LoadingOverlay';
import BeastAuditReportGrid from './BeastAuditReportGrid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    height: '78vh',
  },
  paper: {
    width: '100%',
    height: '100%',
  },
}));

const BeastAuditReportPage: React.FC = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [report, setReport] = useState<BeastAuditReportRow[]>([]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <LoadingOverlay open={loading} loadingMsg={'Retrieving Beast Audit Report'} />
        <BeastAuditReportForm setReport={setReport} setLoading={setLoading} />
        <BeastAuditReportGrid rowData={report} />
      </Paper>
    </div>
  );
};

export default BeastAuditReportPage;
