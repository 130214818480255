import { AutomaticBallType } from '../../../types/AutomaticBall';
import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import StyledTableCell from '../../Global/Tables/StyledTableCell';
import moment from 'moment-timezone';
import PlayDetailsDialog from '../../BatRack/PlayLookupPage/PlayDetails/PlayDetailsDialog';

type AutomaticBallsListProps = {
  automaticBalls: AutomaticBallType[];
  gamePk: string;
};

const columnNames = ['Game PK', 'Play ID', 'At Bat Index', 'Play Index', 'At Bat Result', 'Start Time', 'End Time'];

const AutomaticBallsList: React.FC<AutomaticBallsListProps> = ({ automaticBalls, gamePk }: AutomaticBallsListProps) => {
  return (
    <Paper>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columnNames.map(columnName => (
                <StyledTableCell key={columnName}>{columnName}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {automaticBalls
              .filter(
                ball =>
                  (gamePk === 'ALL' || (!ball.gamePk ? gamePk === 'N/A' : ball.gamePk.toString() === gamePk)) &&
                  ball.atBatResultType !== 'Intent Walk',
              )
              .sort(
                (ball1: AutomaticBallType, ball2: AutomaticBallType) =>
                  moment.utc(ball2.startTime).valueOf() - moment.utc(ball1.startTime).valueOf(),
              )
              .map((ball: AutomaticBallType) => (
                <TableRow key={ball.playId}>
                  <TableCell>{ball.gamePk}</TableCell>
                  <TableCell>
                    <PlayDetailsDialog playId={ball.playId} />
                  </TableCell>
                  <TableCell>{ball.playIndex.atBatIndex}</TableCell>
                  <TableCell>{ball.playIndex.playIndex}</TableCell>
                  <TableCell>{ball.atBatResultType}</TableCell>
                  <TableCell>
                    {ball.startTime
                      ? moment
                          .utc(ball.startTime)
                          .tz('America/New_York')
                          .format('MM/DD/YYYY hh:mm a z')
                      : ''}
                  </TableCell>
                  <TableCell>
                    {ball.endTime
                      ? moment
                          .utc(ball.endTime)
                          .tz('America/New_York')
                          .format('MM/DD/YYYY hh:mm a z')
                      : ''}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default AutomaticBallsList;
