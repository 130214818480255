export const columnDefs = [
  {
    field: 'gamePk',
    headerName: 'Game PK',
  },
  {
    field: 'batterPk',
    headerName: 'BATTER PK',
  },
  {
    field: 'league',
    headerName: 'League',
  },
  {
    field: 'batterHome',
    headerName: 'BATTER Home',
  },
  {
    field: 'beastHome',
    headerName: 'Beast Home',
  },
  {
    field: 'batterAway',
    headerName: 'BATTER Away',
  },
  {
    field: 'beastAway',
    headerName: 'Beast Away',
  },
  {
    field: 'batterDate',
    headerName: 'BATTER Date',
  },
  {
    field: 'beastDate',
    headerName: 'Beast Date',
  },
  {
    field: 'batterTime',
    headerName: 'BATTER Time',
  },
  {
    field: 'beastTime',
    headerName: 'Beast Time',
  },
  {
    field: 'batterVenue',
    headerName: 'BATTER Venue',
  },
  {
    field: 'beastVenue',
    headerName: 'Beast Venue',
  },
  {
    field: 'difference',
    headerName: 'Difference',
    cellStyle: { whiteSpace: 'pre-wrap' },
  },
];
