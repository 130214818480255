import React, { useEffect, useState } from 'react';
import { SportId } from '../../../types/SportId';
import { getAllRaulVenues, getAllSportIds } from '../../../clients/BatRackClient';
import { Venue } from '../../../types/Venue';
import { getVenuesBySportIdsAndSeason } from '../../../clients/StatsApiClient';
import { Card, CardContent, CardHeader, createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddRaulVenue from './AddRaulVenue';
import RaulVenuesTable from './RaulVenuesTable';
import RaulGamesTable from './RaulGamesTable';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      height: '300px',
      maxWidth: '875px',
      margin: '0 auto',
      marginBottom: '24px',
      [theme.breakpoints.down('xs')]: {
        maxHeight: '40vh',
      },
      '& .MuiCardContent-root': {
        display: 'flex',
        height: 'calc(100% - 25px)',
        justifyContent: 'space-evenly',
        overflow: 'auto',
        padding: '0px',
        [theme.breakpoints.down('xs')]: {
          flexDirection: 'column',
          justifyContent: 'flex-start',
        },
      },
    },
  }),
);

const RaulPage: React.FC = () => {
  const classes = useStyles();

  const [raulVenues, setRaulVenues] = useState<Venue[]>([]);
  const [sportIds, setSportIds] = useState<SportId[]>([]);
  const [venueOpts, setVenueOpts] = useState<Venue[]>([]);

  useEffect(() => {
    getSportIds().then();
  }, []);

  useEffect(() => {
    getVenueOpts().then();
  }, [sportIds]);

  useEffect(() => {
    if (venueOpts?.length > 0) {
      getRaulVenues().then();
    }
  }, [venueOpts]);

  const getRaulVenues = async () => {
    const venues = await getAllRaulVenues();
    venues.forEach(venue => {
      venue.id = venue.venueId;
      const statsVenue = venueOpts.filter(v => venue.id === v.id);
      if (statsVenue?.length === 1) {
        venue.name = statsVenue[0].name;
      }
    });
    setRaulVenues(sortVenues(venues));
  };

  const getSportIds = async () => {
    const result: SportId[] = await getAllSportIds();
    setSportIds(result);
  };

  const getVenueOpts = async () => {
    const venues = await getVenuesBySportIdsAndSeason(sportIds, 2022);
    setVenueOpts(sortVenues(venues));
  };

  const sortVenues = (venues: Venue[]): Venue[] => {
    return venues.sort((a, b) => {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    });
  };

  return (
    <div>
      <Card variant='outlined' className={classes.card}>
        <CardHeader subheader='RAUL Games' />
        <CardContent>
          <RaulGamesTable />
        </CardContent>
      </Card>
      <Card variant='outlined' className={classes.card}>
        <CardHeader subheader='RAUL Venues' />
        <CardContent>
          <RaulVenuesTable venues={raulVenues} refreshVenues={getRaulVenues} />
          <AddRaulVenue venueOpts={venueOpts} refreshVenues={getRaulVenues} />
        </CardContent>
      </Card>
    </div>
  );
};

export default RaulPage;
