import { IconButton } from '@material-ui/core';
import React from 'react';
import LinkIcon from '@material-ui/icons/Link';

type StyledLinkIconButtonProps = {
  linkUrl: string;
  target?: string;
  size?: 'small' | 'medium' | undefined;
  disabled?: boolean;
};

const StyledLinkIconButton: React.FC<StyledLinkIconButtonProps> = ({ linkUrl, target, size, disabled }) => {
  return (
    <IconButton
      size={size}
      onClick={() => window.open(linkUrl, target)}
      disabled={disabled !== undefined ? disabled : false}
    >
      <LinkIcon />
    </IconButton>
  );
};

StyledLinkIconButton.defaultProps = {
  linkUrl: '',
  target: '_blank',
  size: 'medium',
  disabled: false,
};

export default StyledLinkIconButton;
