import React, { createContext, useContext, useEffect, useState } from 'react';
import { Roles } from '../constants/RolesConstants';
import { useAuth, WrapperProps } from './AuthContext';
import { Role } from '../types/Role';

type RolesContextType = {
  isSuperAdminUser: boolean;
  isAdminUser: boolean;
  isUnprocessedUser: boolean;
  isABSUser: boolean;
  isBatrackUser: boolean;
  isBATTERUser: boolean;
  isDugoutUser: boolean;
  isLineupCardsUser: boolean;
  isMLBAuthUser: boolean;
  isPitchClockUser: boolean;
  hasRole: (role: string) => boolean;
};

const RolesContext = createContext<RolesContextType | undefined>(undefined);

const RolesProvider = ({ children }: WrapperProps) => {
  const { state } = useAuth();
  const [isSuperAdminUser, setIsSuperAdminUser] = useState<boolean>(false);
  const [isAdminUser, setIsAdminUser] = useState<boolean>(false);
  const [isUnprocessedUser, setIsUnprocessedUser] = useState<boolean>(false);
  const [isABSUser, setIsABSUser] = useState<boolean>(false);
  const [isBatrackUser, setIsBatrackUser] = useState<boolean>(false);
  const [isBATTERUser, setIsBATTERUser] = useState<boolean>(false);
  const [isDugoutUser, setIsDugoutUser] = useState<boolean>(false);
  const [isLineupCardsUser, setIsLineupCardsUser] = useState<boolean>(false);
  const [isMLBAuthUser, setIsMLBAuthUser] = useState<boolean>(false);
  const [isPitchClockUser, setIsPitchClockUser] = useState<boolean>(false);

  const hasRole = (role: string): boolean => {
    if (state?.userInfo?.roles) {
      return state.userInfo.roles.some(r => r.role === role);
    }
    return false;
  };

  const checkForRoles = (roles: Role[]) => {
    setIsSuperAdminUser((roles || []).some(r => r.role === Roles.BATRACK_SUPER_ADMIN));
    setIsAdminUser((roles || []).some(r => r.role === Roles.BATRACK_ADMIN));
    setIsUnprocessedUser((roles || []).some(r => r.role === Roles.BATRACK_USER) && (roles || []).length <= 1);
    setIsABSUser((roles || []).some(r => r.role === Roles.BATRACK_ABS_USER));
    setIsBatrackUser((roles || []).some(r => r.role === Roles.BATRACK_BATRACK_USER));
    setIsBATTERUser((roles || []).some(r => r.role === Roles.BATRACK_BATTER_USER));
    setIsDugoutUser((roles || []).some(r => r.role === Roles.BATRACK_DUGOUT_USER));
    setIsLineupCardsUser((roles || []).some(r => r.role === Roles.BATRACK_LINEUP_CARDS_USER));
    setIsMLBAuthUser((roles || []).some(r => r.role === Roles.BATRACK_MLB_AUTH_USER));
    setIsPitchClockUser((roles || []).some(r => r.role === Roles.BATRACK_PITCH_CLOCK_USER));
  };

  useEffect(() => {
    if (state?.userInfo?.roles) {
      checkForRoles(state.userInfo.roles);
    }
  }, [state]);

  return (
    <RolesContext.Provider
      value={{
        isSuperAdminUser,
        isAdminUser,
        isUnprocessedUser,
        isABSUser,
        isBatrackUser,
        isBATTERUser,
        isDugoutUser,
        isLineupCardsUser,
        isMLBAuthUser,
        isPitchClockUser,
        hasRole,
      }}
    >
      {children}
    </RolesContext.Provider>
  );
};

const useRoles = (): RolesContextType => {
  const rolesContext = useContext<RolesContextType | undefined>(RolesContext);
  if (rolesContext === undefined) {
    throw new Error(`useRoles must be used within a RolesProvider`);
  }
  return rolesContext;
};

export { RolesContext, RolesProvider, useRoles };
