import React from 'react';
import { PitchChallengesCount } from '../../../types/PitchChallenge';

type TabsChallengesSummaryProps = {
  totalGames: number;
  challengesCount: PitchChallengesCount;
  classes: Record<string, string>;
};

const TotalChallengesSummary: React.FC<TabsChallengesSummaryProps> = ({ totalGames, challengesCount, classes }) => {
  return (
    <div className={classes.summaryText}>
      <div className={classes.summaryWrapper}>
        <b>Total:&nbsp;</b>
        <span>
          {totalGames} game{totalGames === 1 ? '' : 's'}
        </span>
      </div>
      <div style={{ display: 'flex' }}>
        <div className={classes.wrapper}>
          <span className={`${classes.circle} ${classes.challenged}`}>{challengesCount.challenges}</span>
          <span>challenge{challengesCount.challenges === 1 ? '' : 's'}</span>
        </div>
        <div className={classes.wrapper}>
          <span className={`${classes.circle} ${classes.upheld}`}>{challengesCount.upheld}</span>
          <span>upheld</span>
        </div>
        <div className={classes.wrapper}>
          <span className={`${classes.circle} ${classes.overturned}`}>{challengesCount.overturned}</span>
          <span>overturned</span>
        </div>
      </div>
    </div>
  );
};

export default TotalChallengesSummary;
