import React, { useEffect, useState } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { GamePlays, GameType, Play } from '../../../types/Game';
import StyledTableCell from '../../Global/Tables/StyledTableCell';
import { getTabsDifferingCalls } from '../../../clients/BatRackClient';
import { getGumboHydratePerson } from '../../../clients/StatsApiClient';
import { getResearchApiUrlForPlay, getStatsApiUrlLiveGameWithStrikeZoneAnalytics } from '../../../utils/shared-links';
import StyledLinkIconButton from '../../Global/Buttons/StyledLinkIconButton';
import PlayDetailsDialog from '../../BatRack/PlayLookupPage/PlayDetails/PlayDetailsDialog';

type TabsDifferingCallsProps = {
  games: GameType[];
};

type TabsDifferingCallsType = Play & {
  awayAbbreviation: string;
  homeAbbreviation: string;
  research: string;
  guids: string;
};

const columnNames = ['Game', 'Play ID', 'Away', 'Home', 'Batter', 'Pitcher', 'ABS', 'Umpire'];

const TabsDifferingCalls: React.FC<TabsDifferingCallsProps> = ({ games }: TabsDifferingCallsProps) => {
  const [displayData, setDisplayData] = useState<TabsDifferingCallsType[]>([]);

  const getData = async (gamePks: number[]) => {
    try {
      await getDifferingCallsForGames(gamePks);
    } catch (e) {
      console.error(e);
    }
  };

  const getDifferingCallsForGames = async (gamePks: number[]) => {
    const differingCallsForGames = [];
    for (const gamePk of gamePks) {
      const game = await getGumboHydratePerson(gamePk);
      const awayAbbreviation = game.gameData.teams.away.abbreviation;
      const homeAbbreviation = game.gameData.teams.home.abbreviation;
      const gamePlays = await getDifferingCallsForGame(gamePk, awayAbbreviation, homeAbbreviation);
      differingCallsForGames.push(gamePlays);
    }
    const newDisplayData = Array.prototype.concat.apply([], differingCallsForGames);
    setDisplayData(newDisplayData);
  };

  const getDifferingCallsForGame = async (gamePk: number, awayAbbreviation: string, homeAbbreviation: string) => {
    const result: GamePlays = await getTabsDifferingCalls(gamePk);
    return result.plays.map(play => {
      return {
        ...play,
        awayAbbreviation: awayAbbreviation,
        homeAbbreviation: homeAbbreviation,
        research: getResearchApiUrlForPlay(gamePk, play.playId),
        guids: getStatsApiUrlLiveGameWithStrikeZoneAnalytics(gamePk),
      };
    });
  };

  const resetData = () => {
    setDisplayData([]);
  };

  useEffect(() => {
    if (games.length) {
      const gamePks: number[] = games.map((game: GameType) => game.gamePk);
      getData(gamePks);
    } else {
      resetData();
    }
  }, [games]);

  return (
    <Paper>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columnNames.map(columnName => (
                <StyledTableCell key={columnName}>{columnName}</StyledTableCell>
              ))}
              <StyledTableCell key='research' align='center'>
                Research
              </StyledTableCell>
              <StyledTableCell key='guids' align='center'>
                GUIDs
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayData.map((data: TabsDifferingCallsType) => (
              <TableRow key={`${data.gamePk}_${data.playId}`}>
                <TableCell>{data.gamePk}</TableCell>
                <TableCell>
                  <PlayDetailsDialog playId={data.playId} />
                </TableCell>
                <TableCell>{data.awayAbbreviation}</TableCell>
                <TableCell>{data.homeAbbreviation}</TableCell>
                <TableCell>{data.batterName}</TableCell>
                <TableCell>{data.pitcherName}</TableCell>
                <TableCell>{data.pitchData.absCall}</TableCell>
                <TableCell>{data.playResult}</TableCell>
                <TableCell align='center'>
                  <StyledLinkIconButton linkUrl={data.research} />
                </TableCell>
                <TableCell align='center'>
                  <StyledLinkIconButton linkUrl={data.guids} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TabsDifferingCalls;
