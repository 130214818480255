import React from 'react';
import { WrapperProps } from '../../types/Auth';
import { LocalStorageError } from '../../types/Error';
import { umpireV1Client } from '../../utils/axios';

const UmpireApiHttpInterceptor = ({ children }: WrapperProps) => {
  umpireV1Client.interceptors.request.use(
    request => {
      const oktaToken = localStorage.getItem('okta-token-storage');

      if (!oktaToken) {
        throw new LocalStorageError('Cannot find okta-token-storage.');
      }

      const { accessToken } = JSON.parse(oktaToken);

      if (!accessToken?.accessToken) {
        throw new LocalStorageError('Failed to parse accessToken.');
      }

      request.headers.Authorization = `Bearer ${accessToken.accessToken}`;
      return request;
    },
    error => {
      //todo: refresh umpire api token
    },
  );

  umpireV1Client.interceptors.response.use(
    response => response,
    error => {
      const errorStatus = error.response?.status;
      const userIsUnauthorized = [401, 403].includes(errorStatus);
      if (userIsUnauthorized) {
        //todo: refresh umpire api token
      }
      return Promise.reject(error);
    },
  );

  return <>{children}</>;
};

export default UmpireApiHttpInterceptor;
