export const Roles = {
  BATRACK_SUPER_ADMIN: 'ROLE_BATRACK-SUPER-ADMIN',
  BATRACK_ADMIN: 'ROLE_BATRACK-ADMIN',
  BATRACK_USER: 'ROLE_BATRACK-USER',
  BATRACK_ABS_USER: 'ROLE_BATRACK-ABS-USER',
  BATRACK_BATRACK_USER: 'ROLE_BATRACK-BATRACK-USER',
  BATRACK_BATTER_USER: 'ROLE_BATRACK-BATTER-USER',
  BATRACK_DUGOUT_USER: 'ROLE_BATRACK-DUGOUT-USER',
  BATRACK_LINEUP_CARDS_USER: 'ROLE_BATRACK-LINEUP-CARDS-USER',
  BATRACK_MLB_AUTH_USER: 'ROLE_BATRACK-MLB-AUTH-USER',
  BATRACK_PITCH_CLOCK_USER: 'ROLE_BATRACK-PITCH_CLOCK_USER',
};
