import React, { ChangeEvent, useEffect, useState } from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import {
  Button,
  createStyles,
  FormControl,
  FormGroup,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Theme,
} from '@material-ui/core';
import { SportId } from '../../../types/SportId';
import { getAllSportIds } from '../../../clients/BatRackClient';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formGroup: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'baseline',
      paddingBottom: '20px',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    sportIdSelector: {
      marginTop: '19px',
      marginLeft: '20px',
    },
    expandAllBtn: {
      alignSelf: 'flex-end',
      bottom: '10px',
      marginLeft: 'auto',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        padding: '20px 20px 0px 20px',
        bottom: '0px',
      },
    },
  }),
);

type TabsChallengesFormProps = {
  date: Date;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
  selectedSportId: SportId | undefined;
  setSelectedSportId: React.Dispatch<React.SetStateAction<SportId | undefined>>;
  expandAll: boolean;
  expandAllClick: (toggle: boolean) => void;
};

const ChallengesForm: React.FC<TabsChallengesFormProps> = ({
  date,
  setDate,
  selectedSportId,
  setSelectedSportId,
  expandAll,
  expandAllClick,
}) => {
  const classes = useStyles();

  // Default to LOW A
  const DEFAULT_SPORT_ID = 14;

  const [sportIds, setSportIds] = useState<SportId[]>([]);

  useEffect(() => {
    getSportIds().then();
  }, []);

  useEffect(() => {
    if (sportIds.length > 0) {
      const defaultSportId = sportIds.find(s => s.sportId === DEFAULT_SPORT_ID) || undefined;
      setSelectedSportId(defaultSportId);
    }
  }, [sportIds]);

  const getSportIds = async () => {
    const result: SportId[] = await getAllSportIds();
    setSportIds(result);
  };

  const selectSportId = (event: ChangeEvent<{ value: number | unknown }>) => {
    setSelectedSportId(sportIds.find(s => s.sportId === event.target.value));
  };

  return (
    <FormGroup className={classes.formGroup}>
      <FormControl>
        <KeyboardDatePicker
          id='date-picker-inline'
          label='Game Date'
          required={true}
          format='MM/dd/yyyy'
          variant='inline'
          disableToolbar
          margin='normal'
          autoOk={true}
          value={date}
          onChange={(date: Date | null) => {
            if (date) {
              setDate(date);
            }
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </FormControl>
      <FormControl size='small' className={classes.sportIdSelector}>
        <InputLabel>Sport ID</InputLabel>
        <Select value={selectedSportId?.sportId || ''} onChange={selectSportId}>
          {sportIds.map(sportId => (
            <MenuItem key={sportId.sportId} value={sportId.sportId}>
              {sportId.sportId} - {sportId.sportName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.expandAllBtn}>
        <Button variant='contained' color='primary' onClick={() => expandAllClick(!expandAll)}>
          {expandAll ? 'Collapse All' : 'Expand All'}
        </Button>
      </FormControl>
    </FormGroup>
  );
};

export default ChallengesForm;
