import { statsApiClient } from '../utils/axios';
import { StatsBoxscore } from '../types/LineupCard';
import { SportId } from '../types/SportId';
import { Venue } from '../types/Venue';
import { Season } from '../types/Season';

export const getGumboHydratePerson = async (gamePk: number): Promise<any> => {
  const { data } = await statsApiClient.get(`/v1.1/game/${gamePk}/feed/live?hydrate=person`);
  return data;
};

export const getBoxscore = async (gamePk: number): Promise<StatsBoxscore> => {
  const { data } = await statsApiClient.get(
    `/v1/game/${gamePk}/boxscore?hydrate=person(rosterEntries(team),currentTeam)`,
  );
  return data;
};

export const getSeason = async (sportId: number): Promise<Season> => {
  const { data } = await statsApiClient.get(`v1/seasons?sportId=${sportId}`);
  return data.seasons[0];
};

export const getVenuesBySportIdsAndSeason = async (sportIds: SportId[], season: number): Promise<Venue[]> => {
  const ids = sportIds.map(sportId => sportId.sportId).join(',');
  const { data } = await statsApiClient.get(`/v1/venues?sportIds=${ids}&season=${season}`);
  return data.venues;
};
