import React, { useMemo } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { makeStyles } from '@material-ui/core';

export const valueGetter = (originalValue: string, updatedValue: string): string => {
  updatedValue = updatedValue !== null ? updatedValue : 'TBD';
  return `${originalValue} ${updatedValue}`;
};

const useStyles = makeStyles(() => ({
  emphasizeDifference: {
    fontWeight: 'bold',
  },
}));

interface DiffValuesCellRendererProps extends ICellRendererParams {
  orignalKeyValue: string;
  updatedKeyValue: string;
}

const DiffValuesCellRenderer: React.FC<DiffValuesCellRendererProps> = ({ data, originalValueKey, updatedValueKey }) => {
  const classes = useStyles();

  const originalValue: string = useMemo(() => {
    return data[originalValueKey] ? data[originalValueKey] : 'TBD';
  }, [data, originalValueKey]);
  const updatedValue: string = useMemo(() => {
    return data[updatedValueKey] ? data[updatedValueKey] : 'TBD';
  }, [data, updatedValueKey]);

  return (
    <span className={originalValue === updatedValue ? '' : classes.emphasizeDifference}>
      {originalValue === updatedValue ? updatedValue : `${originalValue} \u2192 ${updatedValue}`}
    </span>
  );
};

export default DiffValuesCellRenderer;
