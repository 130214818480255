import { absScoreboardClient } from '../utils/axios';
import { VenueConfig } from '../types/ABSScoreboard';

export const fetchAllVenueConfigs = async (): Promise<VenueConfig[]> => {
  return (await absScoreboardClient.get('/api/venue/configs')).data.entity;
};

export const createVenueConfig = async (teamId: number | string): Promise<VenueConfig> => {
  return (await absScoreboardClient.post(`/api/venue/create/${teamId}`)).data.entity;
};

export const deleteVenueConfig = async (teamId: number | string): Promise<VenueConfig> => {
  return (await absScoreboardClient.delete(`/api/venue/delete/${teamId}`)).data.entity;
};
