import { Button, Card, Grid, makeStyles, Theme } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { getGameChangeMessagesByDate } from '../../../clients/BatRackClient';
import { MessageData, GameChangeMessages } from '../../../types/GameChangeMessage';
import LoadingOverlay from '../../Global/LoadingOverlay';
import GameChangesTabPanel from './GameChangesTabPanel';

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    padding: spacing(2),
  },
  cardContent: {
    padding: 'unset',
    '&:last-child': {
      paddingBottom: '0',
    },
  },
  datePicker: {
    paddingRight: '1.5rem',
  },
  userInput: {
    alignItems: 'baseline',
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
}));

const GameChangesPage: React.FC = () => {
  const classes = useStyles();
  const mountedRef = useRef(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [messages, setMessages] = useState<GameChangeMessages>();
  const [startDate, setStartDate] = useState<Date>(() => {
    const start = new Date();
    start.setDate(start.getDate() - 30);
    return start;
  });
  const [endDate, setEndDate] = useState<Date>(new Date());

  const submitDisabled = useMemo(() => {
    return !startDate || !endDate || startDate.valueOf() > endDate.valueOf();
  }, [startDate, endDate]);

  const gameChangeMsgData = useMemo(() => {
    const msgData: MessageData[] = [];
    if (messages?.gamechanges?.length) {
      messages.gamechanges.forEach(pubsubMsg => {
        msgData.push(JSON.parse(pubsubMsg.data));
      });
    }
    return msgData;
  }, [messages]);

  const postponedMsgData = useMemo(() => {
    const msgData: MessageData[] = [];
    if (messages?.postponements?.length) {
      messages.postponements.forEach(pubsubMsg => {
        msgData.push(JSON.parse(pubsubMsg.data));
      });
    }
    return msgData;
  }, [messages]);

  // effect just for tracking mounted state
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, [startDate, endDate]);

  const fetchGameChanges = async () => {
    try {
      setLoading(true);
      const startDateParam = new Date(startDate.valueOf());
      const endDateParam = new Date(endDate.valueOf());
      startDateParam.setUTCHours(0, 0, 0, 0);
      endDateParam.setUTCHours(23, 59, 59, 999);
      const res = await getGameChangeMessagesByDate(startDateParam.toISOString(), endDateParam.toISOString());
      if (mountedRef.current) {
        setMessages(res);
      }
    } catch (error) {
      console.log('Error: ', error);
    } finally {
      if (mountedRef.current) {
        setLoading(false);
      }
    }
  };

  return (
    <div className={classes.root}>
      <LoadingOverlay open={loading} loadingMsg={'Retrieving Game Changes'} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card className={classes.card}>
            <div className={classes.userInput}>
              <KeyboardDatePicker
                id='start-date-picker'
                className={classes.datePicker}
                label='Start Date'
                required={true}
                format='MM/dd/yyyy'
                variant='inline'
                disableToolbar
                margin='normal'
                value={startDate}
                onChange={(date: Date | null) => {
                  if (date) {
                    setStartDate(date);
                  }
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change start date',
                }}
              />
              <KeyboardDatePicker
                id='end-date-picker'
                className={classes.datePicker}
                label='End Date'
                required={true}
                format='MM/dd/yyyy'
                variant='inline'
                disableToolbar
                margin='normal'
                value={endDate}
                onChange={(date: Date | null) => {
                  if (date) {
                    setEndDate(date);
                  }
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change end date',
                }}
              />
              <Button variant='contained' color='primary' onClick={fetchGameChanges} disabled={submitDisabled}>
                Submit
              </Button>
            </div>

            <GameChangesTabPanel gameChanges={gameChangeMsgData} postponedChanges={postponedMsgData} />
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default GameChangesPage;
