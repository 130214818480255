import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import StyledTableCell from './StyledTableCell';
import { v4 as uuid } from 'uuid';

export type StyledTableColumn = {
  field: string;
  title: string;
  upperCase?: boolean;
  formatter?: React.ReactNode;
};

type StyledTableProps = {
  tableColumns: StyledTableColumn[];
  data: any[];
};

const StyledTable: React.FC<StyledTableProps> = ({ tableColumns, data }) => {
  const cellValue = (tableColumn: StyledTableColumn, dataRow: any): React.ReactNode => {
    const value = dataRow[tableColumn.field];
    if (value) {
      if (tableColumn.formatter) {
        const Formatter = tableColumn.formatter;
        // eslint-disable-next-line
        //@ts-ignore
        return <Formatter value={value} />;
      } else if (tableColumn.upperCase) {
        return value.toUpperCase();
      }
    }
    return value;
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {tableColumns.map(tableColumn => (
              <StyledTableCell key={tableColumn.field}>{tableColumn.title}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.length > 0 &&
            data?.map((dataRow: any) => {
              return (
                <TableRow key={uuid()}>
                  {tableColumns.map(tableColumn => (
                    <TableCell key={uuid()}>{cellValue(tableColumn, dataRow)}</TableCell>
                  ))}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default StyledTable;
