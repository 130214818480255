import React from 'react';

import { Snackbar } from '@material-ui/core';
import MuiAlert, { Color as AlertSeverity } from '@material-ui/lab/Alert';

type AlertProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  message: string;
  severity?: AlertSeverity;
  autoHideDuration?: number;
};

const Alert = ({ open, setOpen, message, severity, autoHideDuration }: AlertProps) => {
  const closeAlert = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason !== 'clickaway') {
      setOpen(false);
    }
  };

  return (
    <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={closeAlert}>
      <MuiAlert onClose={closeAlert} severity={severity || 'error'}>
        {message}
      </MuiAlert>
    </Snackbar>
  );
};

export default Alert;
