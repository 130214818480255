import { makeStyles, Theme, Tab, Tabs, Paper } from '@material-ui/core';
import React, { useMemo } from 'react';
import { Message, MessageData } from '../../../types/GameChangeMessage';
import GameChangesGrid from './GameChangesGrids/GameChangesGrid';
import PostponedGrid from './GameChangesGrids/PostponedGrid';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`game-change-tabpanel-${index}`} {...other}>
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `game-change-tab-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabPanel: {
    height: '70vh',
  },
}));

type GameChangesTabPanelProps = {
  gameChanges: MessageData[];
  postponedChanges: MessageData[];
};

const GameChangesTabPanel: React.FC<GameChangesTabPanelProps> = ({ gameChanges, postponedChanges }) => {
  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  const gameChangeMessages: Message[] = useMemo(() => {
    return gameChanges?.map(g => g.message);
  }, [gameChanges]);

  const postponedMessages: Message[] = useMemo(() => {
    return postponedChanges?.map(g => g.message);
  }, [gameChanges]);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Paper className={classes.root}>
      <div>
        <Tabs value={value} onChange={handleChange} aria-label='simple tabs example'>
          <Tab label='Game Changes' {...a11yProps(0)} />
          <Tab label='Postponed' {...a11yProps(1)} />
        </Tabs>
      </div>
      <TabPanel value={value} index={0}>
        <div className={classes.tabPanel}>
          <GameChangesGrid rowData={gameChangeMessages} />
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className={classes.tabPanel}>
          <PostponedGrid rowData={postponedMessages} />
        </div>
      </TabPanel>
    </Paper>
  );
};

export default GameChangesTabPanel;
