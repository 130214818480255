import { TeamName } from './TeamName';
import { SportId } from './SportId';

export interface RawGame {
  opponent: TeamName;
  homeGame: boolean;
  startTimeUtc: Date;
  abstractStatus: string;
  detailedStatus: string;
}

export class Game {
  constructor(
    private opponentName: string,
    private opponentCode: string,
    private isHomeGame: boolean,
    private startTimeUtc: Date,
    private abstractStatus: string,
    private detailedStatus: string,
  ) {
    this.opponentName = opponentName;
    this.opponentCode = opponentCode;
    this.isHomeGame = isHomeGame;
    this.startTimeUtc = startTimeUtc;
    this.abstractStatus = abstractStatus;
    this.detailedStatus = detailedStatus;
  }

  static fromRawGame(rawGame: RawGame): Game {
    return new Game(
      rawGame.opponent.name,
      rawGame.opponent.code,
      rawGame.homeGame,
      rawGame.startTimeUtc,
      rawGame.abstractStatus,
      rawGame.detailedStatus,
    );
  }
}

export type GameStatus = {
  abstractStatus: string;
  detailedStatus: string;
};

export type TimeZone = {
  id: string;
  abbreviation: string;
};

export type GameType = {
  gamePk: number;
  gameTime: Date;
  status: GameStatus;
  homeTeamName: TeamName;
  awayTeamName: TeamName;
  awayTeamStatsId: number;
  timeZone: TimeZone;
  homeTeamStatsId: number;
  active: boolean;
  mp4Mode: boolean;
  springTraining: boolean;
  testable: boolean;
  venueId: number;
  leagueId: number;
  sportId: SportId;
  gameNumber: number;
  startTimeTBD: boolean;
  isAbsChallengeGame: boolean;
};

export type Play = {
  playId: string;
  gamePk: number;
  atBatIndex: number;
  playIndex: {
    atBatIndex: number;
    playIndex: number;
  };
  count: {
    balls: number;
    strikes: number;
    outs: number;
  };
  batterId: number;
  batterName: string;
  batSide: string;
  pitcherId: number;
  pitcherName: string;
  isPitch: boolean;
  playResult: string;
  pitchData: PitchData;
  inning: number;
  halfInning: string;
  hitData: {
    exitVelocity: number;
    launchAngle: number;
    distance: number;
  };
  atBatResult: string;
  atBatResultType: string;
  pitchChallengeResult: string;
  createdTs: string;
  updatedTs: string;
  videoUrls: VideoUrl[];
};

export type GamePlays = {
  plays: Play[];
};

export type PitchData = {
  absCall: string;
  edgeDistance: number;
  horizontalBreak: number;
  pfxX: number;
  pfxZ: number;
  pitchExtension: number;
  pitchSpeed: number;
  pitchType: string;
  spinRate: number;
  szBottom: number;
  szDepthInches: number;
  szTop: number;
  szWidthInches: number;
  trackingCoordinates: number;
  verticalBreak: number;
  verticalBreakInduced: number;
  xcoordinate: number;
  zcoordinate: number;
};

export type VideoUrl = {
  id: number;
  angleId: number;
  url: string;
  startOffset: number;
  duration: number;
};
