import { umpireV1Client } from '../utils/axios';
import { FlaggedPitch, FlaggedPitchOptions, HasOpenedTabs } from '../types/FlaggedPitches';

export const getFlaggedData = async (gamePks: number[]): Promise<FlaggedPitch[]> => {
  const gamePksStr: string = gamePks.join(',');
  const url = `/tabs/v1/flag/game/${gamePksStr}`;
  const { data } = await umpireV1Client.get(url);
  return data;
};

export const getFlaggedPitchOptions = async (): Promise<FlaggedPitchOptions[]> => {
  const url = `/tabs/v1/flag/options`;
  const { data } = await umpireV1Client.get(url);
  return data;
};

export const getHasOpenedTabs = async (gamePks: number[]): Promise<HasOpenedTabs[]> => {
  const gamePksStr: string = gamePks.join(',');
  const url = `/tabs/v1/hasOpened/${gamePksStr}`;
  const { data } = await umpireV1Client.get(url);
  return data;
};
