import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { Team } from '../../../types/Team';
import GameCells from './GameCells';
import SyncStatusCells from './SyncStatusCells';
import theme from '../../../utils/theme';
import { SyncStatusOverride } from '../../../types/SyncStatusOverride';

type TeamRowProps = Team & { refreshTeams: () => Promise<void> };

const TeamRow = ({
  code,
  games,
  syncAllowed,
  inGameVideoAllowed,
  syncStatusOverride,
  playsCount,
  videoCount,
  nonPitchEventsCount,
  refreshTeams,
}: TeamRowProps) => {
  let rowColor: string;
  switch (syncStatusOverride) {
    case SyncStatusOverride.ALLOW_SYNC:
      rowColor = theme.palette.success.light;
      break;
    case SyncStatusOverride.DENY_SYNC:
      rowColor = theme.palette.error.light;
      break;
    default:
      rowColor = 'inherit';
  }

  return (
    <TableRow style={{ backgroundColor: rowColor }}>
      <TableCell>{code}</TableCell>
      <GameCells games={games} />
      <SyncStatusCells
        code={code}
        syncAllowed={syncAllowed}
        inGameVideoAllowed={inGameVideoAllowed}
        syncStatusOverride={syncStatusOverride}
        playsCount={playsCount}
        videoCount={videoCount}
        nonPitchEventsCount={nonPitchEventsCount}
        refreshTeams={refreshTeams}
      />
    </TableRow>
  );
};

export default TeamRow;
