import React, { createContext, useCallback, useContext, useEffect, useReducer } from 'react';
import { AuthState, OktaAuth } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { getUserInfo } from '../clients/BatRackClient';
import { UserInfo } from '../types/UserInfo';

export type AuthStateType = {
  loggedIn: boolean;
  email: string;
  accessToken: string;
  groups: string[];
  userInfo?: UserInfo;
};

export type AuthDispatchType = { type: string; value?: any };

export type AuthContextType = {
  state: AuthStateType;
  dispatch: React.Dispatch<AuthDispatchType>;
};

export type WrapperProps = {
  children: JSX.Element | JSX.Element[];
};

export const OKTA_TESTING_DISABLE_HTTPS_CHECK = process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;
export const OKTA_CLIENT_ID = process.env.OKTA_CLIENT_ID;
export const OKTA_ISSUER = process.env.OKTA_ISSUER;
export const REDIRECT_URI = process.env.OKTA_REDIRECT_URI + `/login/callback`;

export const OKTA_CONFIG = {
  clientId: OKTA_CLIENT_ID,
  issuer: OKTA_ISSUER,
  redirectUri: REDIRECT_URI,
  scopes: ['openid', 'profile', 'email', 'offline_access'],
  pkce: true,
  disableHttpsCheck: OKTA_TESTING_DISABLE_HTTPS_CHECK,
  tokenManager: {
    expireEarlySeconds: 60,
  },
};

const oktaAuth: OktaAuth = new OktaAuth(OKTA_CONFIG);

const AuthContext = createContext<Partial<AuthContextType>>({});

const loggedOutState = { loggedIn: false, email: '', accessToken: '', groups: [], userInfo: undefined };

const logOut = () => {
  localStorage.clear();
  return loggedOutState;
};

const getStateFromOktaToken = (oktaToken: AuthState, userInfo: UserInfo) => {
  const { accessToken, idToken } = oktaToken;

  if (!accessToken?.accessToken || !idToken) {
    return logOut();
  }

  localStorage.setItem('userInfo', JSON.stringify(userInfo));
  return {
    loggedIn: true,
    email: idToken.claims?.email ?? '',
    accessToken: accessToken.accessToken,
    groups: accessToken?.claims.groups.filter((group: string) => group.includes('Batrack')) ?? [],
    userInfo,
  };
};

const AuthProvider = ({ children }: WrapperProps) => {
  const { authState } = useOktaAuth();
  const reducer = useCallback((state: AuthStateType, action: AuthDispatchType) => {
    switch (action.type) {
      case 'login': {
        return getStateFromOktaToken(action.value.authState, action.value.userInfo);
      }

      case 'logout': {
        return logOut();
      }

      default: {
        return state;
      }
    }
  }, []);

  const [state, dispatch] = useReducer(reducer, loggedOutState);

  useEffect(() => {
    if (authState?.isAuthenticated) {
      (async function fetchUserInfo() {
        const userInfoJson = localStorage.getItem('userInfo');
        let userInfo: UserInfo;
        if (userInfoJson) {
          userInfo = JSON.parse(userInfoJson);
        } else {
          userInfo = await getUserInfo();
        }
        dispatch({ type: 'login', value: { authState, userInfo } });
      })();
    }
  }, [authState]);

  return <AuthContext.Provider value={{ state, dispatch }}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
};

export { useAuth, AuthProvider, oktaAuth };
