import React, { useEffect, useState } from 'react';
import SyncStrategyTable from './SyncStrategyTable/SyncStrategyTable';
import UpdateSyncStrategy from './UpdateSyncStrategy/UpdateSyncStrategy';
import { SyncStrategy } from '../../../types/SyncStrategy';
import { getSyncStrategies } from '../../../clients/BatRackClient';

const SyncStrategyPage: React.FC = () => {
  const [syncStrategies, setSyncStrategies] = useState<SyncStrategy[]>([]);

  const getData = async () => {
    const result: SyncStrategy[] = await getSyncStrategies();
    setSyncStrategies(result);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      {!!syncStrategies?.length && <SyncStrategyTable syncStrategies={syncStrategies} />}
      {!!syncStrategies?.length && (
        <>
          <UpdateSyncStrategy syncStrategies={syncStrategies} getData={getData} />
        </>
      )}
    </div>
  );
};

export default SyncStrategyPage;
