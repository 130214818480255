import React from 'react';
import { TableHead, TableRow } from '@material-ui/core';
import StyledTableCell from '../../../../Global/Tables/StyledTableCell';
import { LineupCardStatus } from '../../../../../types/LineupCardStatus';

interface HeadCell {
  id: keyof LineupCardStatus;
  label: string;
}

const headCells: HeadCell[] = [
  { id: 'teamName', label: 'Team' },
  { id: 'submitted', label: 'Submitted' },
  { id: 'generated', label: 'Generated' },
  { id: 'version', label: 'Version' },
  { id: 'cardHistory', label: 'Card History' },
];

const LineupCardsTableHeaderCells: React.FC<HeadCell> = () => {
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <StyledTableCell key={headCell.id} style={{ width: 250, textAlign: 'center' }}>
            {headCell.label}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default LineupCardsTableHeaderCells;
