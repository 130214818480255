import React from 'react';

import { makeStyles, TableCell, Tooltip } from '@material-ui/core';
import moment from 'moment-timezone';

import { Team } from '../../../types/Team';

const useStyles = makeStyles({
  gameCell: {
    margin: 0,
    padding: 0,
  },
  gameCellText: {
    margin: '7px',
    marginLeft: '16px',
  },
});

type GameCellsProps = Pick<Team, 'games'>;

const GameCells = ({ games }: GameCellsProps) => {
  const classes = useStyles();

  if (games.length === 0) {
    return (
      <>
        <TableCell className={classes.gameCell}>
          <p className={classes.gameCellText}>-</p>
        </TableCell>
        <TableCell className={classes.gameCell}>
          <p className={classes.gameCellText}>-</p>
        </TableCell>
        <TableCell className={classes.gameCell}>
          <p className={classes.gameCellText}>-</p>
        </TableCell>
      </>
    );
  }

  const gameCellsText = games
    .sort((gameA, gameB) => new Date(gameA.startTimeUtc).getTime() - new Date(gameB.startTimeUtc).getTime())
    .reduce(
      (acc, game, index) => {
        acc.opponent = [
          ...acc.opponent,
          <p key={index} className={classes.gameCellText}>
            {`${game.isHomeGame ? ' vs. ' : '@'} ${game.opponentCode}`}
          </p>,
        ];
        acc.gameStart = [
          ...acc.gameStart,
          <Tooltip
            key={index}
            title={moment
              .utc(game.startTimeUtc)
              .tz('America/New_York')
              .format('ddd, MMM. D @ h:mm A z')}
            placement='top-start'
          >
            <p className={classes.gameCellText}>
              {moment
                .utc(game.startTimeUtc)
                .tz('America/New_York')
                .format('h:mm A z')}
            </p>
          </Tooltip>,
        ];
        acc.gameStatus = [
          ...acc.gameStatus,
          <p key={index} className={classes.gameCellText}>
            {game.detailedStatus}
          </p>,
        ];
        return acc;
      },
      { opponent: [] as JSX.Element[], gameStart: [] as JSX.Element[], gameStatus: [] as JSX.Element[] },
    );

  return (
    <>
      <TableCell className={classes.gameCell}>{gameCellsText.opponent}</TableCell>
      <TableCell className={classes.gameCell}>{gameCellsText.gameStart}</TableCell>
      <TableCell className={classes.gameCell}>{gameCellsText.gameStatus}</TableCell>
    </>
  );
};

export default GameCells;
