import React, { useState } from 'react';
import { PitchClockExpiryType } from '../../../types/PitchClockExpiry';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import StyledTableCell from '../../Global/Tables/StyledTableCell';
import { isAfter } from 'date-fns';
import moment from 'moment-timezone';

type ExpiriesListProps = {
  expiries: PitchClockExpiryType[];
  gamePk: string;
};

const columnNames = ['Game PK', 'Venue ID', 'Home', 'Away', 'Time', 'Clock Type', 'Delay', 'Sport ID'];

const ExpiriesList: React.FC<ExpiriesListProps> = ({ expiries, gamePk }: ExpiriesListProps) => {
  return (
    <Paper>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columnNames.map(columnName => (
                <StyledTableCell key={columnName}>{columnName}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {expiries
              .filter(
                expiry => gamePk === 'ALL' || (!expiry.gamePk ? gamePk === 'N/A' : expiry.gamePk.toString() === gamePk),
              )
              .sort(
                (expiry1: PitchClockExpiryType, expiry2: PitchClockExpiryType) =>
                  moment.utc(expiry2.ballparkClock).valueOf() - moment.utc(expiry1.ballparkClock).valueOf(),
              )
              .map((expiry: PitchClockExpiryType) => (
                <TableRow key={`${expiry.gamePk}-${expiry.venueId}-${expiry.ballparkClock}`}>
                  <TableCell>{expiry.gamePk}</TableCell>
                  <TableCell>{expiry.venueId}</TableCell>
                  <TableCell>{expiry.homeTeam ? expiry.homeTeam.name : null}</TableCell>
                  <TableCell>{expiry.awayTeam ? expiry.awayTeam.name : null}</TableCell>
                  <TableCell>
                    {moment
                      .utc(expiry.ballparkClock)
                      .tz('America/New_York')
                      .format('MM/DD/YYYY hh:mm a z')}
                  </TableCell>
                  <TableCell>{expiry.clockType}</TableCell>
                  <TableCell>{expiry.diff}</TableCell>
                  <TableCell>{expiry.sportId}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ExpiriesList;
