import { ColDef } from 'ag-grid-community';
import RolesCellRenderer from './CellRenderer/RolesCellRenderer';

export const columnDefs: ColDef[] = [
  {
    field: 'lastName',
    headerName: 'Last',
    sort: 'asc',
  },
  {
    field: 'firstName',
    headerName: 'First',
  },
  {
    field: 'email',
    headerName: 'User Name',
  },
  {
    field: 'roles',
    headerName: 'Roles',
    editable: true,
    cellRenderer: RolesCellRenderer,
  },
];
