import React, { useEffect, useState } from 'react';
import { Color as AlertSeverity, Color } from '@material-ui/lab/Alert/Alert';
import { Play } from '../../../types/Game';
import { makeStyles } from '@material-ui/core/styles';
import { executeWithParamsAndAlert } from '../../../utils/restUtil';
import { getPlayByPlayId } from '../../../clients/BatRackClient';
import { Grid } from '@material-ui/core';
import PlayDetails from './PlayDetails/PlayDetails';
import PitchDetails from './PlayDetails/PitchDetails';
import BatterDetails from './PlayDetails/BatterDetails';
import VideoUrlDetails from './PlayDetails/VideoUrlDetails';
import Alert from '../../Global/Alert';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    height: '80vh',
  },
  gridContainer: {
    height: '80vh',
  },
}));

type PlayLookupDetailsProps = {
  playId: string;
};

const PlayLookupDetails: React.FC<PlayLookupDetailsProps> = ({ playId }) => {
  const classes = useStyles();

  const [alertIsOpen, setAlertIsOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = React.useState<string>('');
  const [alertSeverity, setAlertSeverity] = React.useState<Color>('success' as AlertSeverity);
  const [play, setPlay] = useState<Play>();

  useEffect(() => {
    lookupPlay().then();
  }, [playId]);

  const lookupPlay = async () => {
    const result = await executeWithParamsAndAlert(
      getPlayByPlayId,
      playId,
      `Successfully retrieved play ${playId}`,
      `Failed to retrieve play ${playId}`,
      setAlertMessage,
      setAlertSeverity,
      setAlertIsOpen,
    );
    if (result?.playId) {
      setPlay(result);
    }
  };

  return (
    <div className={classes.root}>
      {play && (
        <Grid container spacing={1} className={classes.gridContainer}>
          <Grid item xs={12} md={3}>
            <PlayDetails play={play} />
          </Grid>
          <Grid item xs={12} md={3}>
            <PitchDetails play={play} />
          </Grid>
          <Grid item xs={12} md={3}>
            <BatterDetails play={play} />
          </Grid>
          <Grid item xs={12} md={3}>
            <VideoUrlDetails play={play} />
          </Grid>
        </Grid>
      )}
      <Alert
        open={alertIsOpen && alertMessage.length > 0}
        setOpen={setAlertIsOpen}
        message={alertMessage}
        severity={alertSeverity}
        autoHideDuration={5000}
      />
    </div>
  );
};

export default PlayLookupDetails;
