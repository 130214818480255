import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, CardContent, Grid, TextField, Typography } from '@material-ui/core';
import Alert from '../../../Global/Alert';
import { Color as AlertSeverity } from '@material-ui/lab/Alert/Alert';
import { updateGameTestability } from '../../../../clients/BatRackClient';

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: '700px',
    margin: '0 auto',
    marginBottom: '24px',
  },
  grid: {
    marginTop: '8px',
    display: 'flex',
    justifyContent: 'center',
  },
  textField: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  button: {
    marginLeft: '16px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: '0',
      marginTop: '16px',
    },
  },
}));

const GameTestability = () => {
  const classes = useStyles();
  const [gamePk, setGamePk] = React.useState('');
  const [alertIsOpen, setAlertIsOpen] = React.useState(false);
  const [alertSeverity, setAlertSeverity] = React.useState('success' as AlertSeverity);

  const handleUpdateTestability = async (testable: boolean) => {
    try {
      setGamePk('');
      await updateGameTestability(gamePk, testable);
      setAlertSeverity('success');
    } catch (error) {
      if ([401, 403].includes(error.response?.status)) {
        return;
      }
      setAlertSeverity('error');
    }
    setAlertIsOpen(true);
  };

  return (
    <Card variant='outlined' className={classes.card}>
      <CardContent>
        <Typography color='textSecondary' gutterBottom>
          Game Testability
        </Typography>
        <Grid className={classes.grid} container>
          <TextField
            className={classes.textField}
            id='outlined-basic'
            label='Game PK'
            variant='outlined'
            size='small'
            value={gamePk}
            onChange={e => setGamePk(e.target.value)}
          />
          <Button
            className={classes.button}
            variant='contained'
            color='primary'
            onClick={() => handleUpdateTestability(true)}
            disabled={!gamePk || gamePk.length < 1}
          >
            Enable Tests
          </Button>
          <Button
            className={classes.button}
            variant='contained'
            color='primary'
            onClick={() => handleUpdateTestability(false)}
            disabled={!gamePk || gamePk.length < 1}
          >
            Disable Tests
          </Button>
          <Alert
            open={alertIsOpen}
            setOpen={setAlertIsOpen}
            message={`${alertSeverity === 'success' ? 'Game Testability Updated' : 'Game Testability Update Failed'}`}
            severity={alertSeverity}
            autoHideDuration={5000}
          />
        </Grid>
      </CardContent>
    </Card>
  );
};

export default GameTestability;
