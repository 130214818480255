import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SyncStrategy } from '../../../../types/SyncStrategy';
import { updateActiveSyncStrategy } from '../../../../clients/BatRackClient';
import Alert from '../../../Global/Alert';
import { Color as AlertSeverity } from '@material-ui/lab/Alert/Alert';

const useStyles = makeStyles(() => ({
  card: {
    maxWidth: '800px',
    margin: '0 auto',
  },
  grid: {
    justifyContent: 'center',
  },
  inputLabel: {
    position: 'relative',
  },
  select: {
    fontSize: '20px',
    minWidth: '300px',
  },
}));

type UpdateSyncStrategyProps = {
  syncStrategies: SyncStrategy[];
  getData: () => Promise<any>;
};

const UpdateSyncStrategy: React.FC<UpdateSyncStrategyProps> = ({ syncStrategies, getData }) => {
  const classes = useStyles();
  const [selectedStrategy, setSelectedStrategy] = useState<string>('');
  const [alertIsOpen, setAlertIsOpen] = React.useState(false);
  const [alertSeverity, setAlertSeverity] = React.useState('success' as AlertSeverity);

  const findActiveSyncStrategy = () => {
    const activeSyncStrategy = syncStrategies.find(syncStrategy => syncStrategy.active);
    if (activeSyncStrategy) {
      setSelectedStrategy(activeSyncStrategy.syncStrategy);
    }
  };

  const handleChange = (event: ChangeEvent<{ value: string | unknown }>) => {
    setSelectedStrategy(event.target.value as string);
  };

  const updateSyncStrategy = async () => {
    try {
      await updateActiveSyncStrategy(selectedStrategy);
      setAlertSeverity('success');
      await getData();
    } catch (error) {
      if ([401, 403].includes(error.response?.status)) {
        return;
      }
      setAlertSeverity('error');
    }
    setAlertIsOpen(true);
  };

  useEffect(() => {
    findActiveSyncStrategy();
  }, [syncStrategies]);

  return (
    <Card variant={'outlined'} className={classes.card}>
      <CardContent>
        <Typography color='textSecondary' gutterBottom>
          Update Sync Strategy
        </Typography>
        <Grid className={classes.grid} container>
          <FormControl>
            <InputLabel className={classes.inputLabel}>
              <Select className={classes.select} value={selectedStrategy} onChange={handleChange}>
                {syncStrategies.map(syncStrategy => (
                  <MenuItem key={syncStrategy.syncStrategy} value={syncStrategy.syncStrategy}>
                    {syncStrategy.syncStrategy}
                  </MenuItem>
                ))}
              </Select>
            </InputLabel>
          </FormControl>
          <Button variant='contained' color='primary' onClick={updateSyncStrategy}>
            Update
          </Button>
          <Alert
            open={alertIsOpen}
            setOpen={setAlertIsOpen}
            message={`${
              alertSeverity === 'success' ? 'Sync Strategy Successfully Updated' : 'Failed to Update Sync Strategy'
            }`}
            severity={alertSeverity}
            autoHideDuration={5000}
          />
        </Grid>
      </CardContent>
    </Card>
  );
};

export default UpdateSyncStrategy;
