import { ICellRendererParams } from 'ag-grid-community';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Role } from '../../../../types/Role';
import { Checkbox, Chip, FormControl, Input, ListItemText, MenuItem, Select, Theme } from '@material-ui/core';
import { Roles } from '../../../../constants/RolesConstants';
import { isEqual } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  roleSelectForm: {
    width: '95%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    backgroundColor: theme.palette.primary.main,
    margin: 2,
  },
}));

type RolesCellRendererParams = ICellRendererParams & {
  setValue: (value: never) => void;
  roles: Role[];
};

const RolesCellRenderer: React.FC<RolesCellRendererParams> = props => {
  const classes = useStyles();

  const [roleOpts, setRoleOpts] = useState<string[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [userRoles, setUserRoles] = useState<Role[]>([]);

  useEffect(() => {
    if (props.roles) {
      setRoles(props.roles.sort(rolesSort));
      setRoleOpts(props.roles.map(role => role.role));
    }
  }, [props.roles]);

  useEffect(() => {
    if (!isEqual(userRoles, props.value)) {
      setUserRoles(props.value.sort(rolesSort));
    }
  }, [props.value]);

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    const values = event.target.value as string[];
    const newestRoleRole = values.slice(-1)[0];
    let updatedRoles: Role[] = [];
    if ([Roles.BATRACK_SUPER_ADMIN, Roles.BATRACK_ADMIN].indexOf(newestRoleRole) > -1) {
      updatedRoles = roles.filter(r => r.role === newestRoleRole);
    } else {
      updatedRoles = values.map(value => roles.filter(r => r.role === value)[0]);
    }
    setUserRoles(updatedRoles);
    if (props && !isEqual(updatedRoles, props.value)) {
      props.setValue(updatedRoles.sort(rolesSort));
    }
  };

  const roleOptionDisabled = (role: string): boolean => {
    const adminRoleAssigned =
      userRoles.filter(r => r.role === Roles.BATRACK_SUPER_ADMIN || r.role === Roles.BATRACK_ADMIN).length > 0;
    if (adminRoleAssigned && [Roles.BATRACK_SUPER_ADMIN, Roles.BATRACK_ADMIN].indexOf(role) === -1) {
      return true;
    } else if (
      !adminRoleAssigned &&
      userRoles.length > 0 &&
      [Roles.BATRACK_SUPER_ADMIN, Roles.BATRACK_ADMIN].indexOf(role) > -1
    ) {
      return true;
    }
    return false;
  };

  const rolesSort = (a: Role, b: Role): number => {
    if (a.role < b.role) {
      return -1;
    }
    if (a.role > b.role) {
      return 1;
    }
    return 0;
  };

  return (
    <FormControl className={classes.roleSelectForm}>
      <Select
        displayEmpty={true}
        multiple={true}
        input={<Input />}
        onChange={handleChange}
        renderValue={selectedRole => (
          <div className={classes.chips}>
            {(selectedRole as string[]).length > 0 &&
              (selectedRole as string[]).map(role => <Chip key={role} label={role} className={classes.chip} />)}
            {(selectedRole as string[]).length === 0 && (
              <Chip key={'no-role'} label={'No Role Assigned'} className={classes.chip} />
            )}
          </div>
        )}
        value={userRoles.map(userRole => userRole.role)}
      >
        {roleOpts.map(role => (
          <MenuItem key={role} value={role} disabled={roleOptionDisabled(role)}>
            <Checkbox checked={!!userRoles.filter(userRole => userRole.role === role)[0]} />
            <ListItemText primary={role} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default RolesCellRenderer;
