import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { CellValueChangedEvent, ColDef } from 'ag-grid-community';
import { columnDefs } from './UsersGridColumnDefs';
import { UserInfo } from '../../../types/UserInfo';
import { makeStyles } from '@material-ui/core/styles';
import { getAccountRoles } from '../../../clients/BatRackClient';
import { Role } from '../../../types/Role';
import Alert from '../../Global/Alert';
import { executeAndAlert } from '../../../utils/restUtil';
import { Color, Color as AlertSeverity } from '@material-ui/lab/Alert/Alert';

const useStyles = makeStyles(() => ({
  outerDiv: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  gridWrapper: {
    flex: '1 1 auto',
  },
}));

type UsersGridProps = {
  rowData: UserInfo[];
  addUserInfoUpdate: (userInfo: UserInfo) => void;
};

const UsersGrid: React.FC<UsersGridProps> = ({ rowData, addUserInfoUpdate }) => {
  const classes = useStyles();

  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const gridRef = useRef<AgGridReact>(null);
  const [colDefs] = useState<ColDef[]>(columnDefs);
  const [alertIsOpen, setAlertIsOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = React.useState('');
  const [alertSeverity, setAlertSeverity] = React.useState<Color>('success' as AlertSeverity);
  const [roles, setRoles] = useState<Role[]>([]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      autoHeight: true,
      filter: true,
      resizable: true,
      sortable: true,
    };
  }, []);

  useEffect(() => {
    getAccountRolesOpts().then();
    window.onresize = autoSizeColumns;
  }, []);

  useEffect(() => {
    const rolesColumn = gridRef?.current?.api?.getColumnDef('roles');
    if (rolesColumn) {
      rolesColumn.cellRendererParams = {
        roles: roles,
      };
    }
  }, [roles]);

  useEffect(() => {
    if (gridRef?.current?.api) {
      gridRef.current.api.setRowData(rowData);
    }
  }, [rowData]);

  const autoSizeColumns = useCallback(() => {
    if (window.innerWidth > 480) {
      gridRef?.current?.api.sizeColumnsToFit();
    } else {
      const allColumnIds: string[] = [];
      gridRef?.current?.columnApi?.getAllColumns()?.forEach(column => {
        allColumnIds.push(column.getId());
      });
      gridRef?.current?.columnApi?.autoSizeColumns(allColumnIds, false);
    }
  }, []);

  const cellValueChanged = (event: CellValueChangedEvent) => {
    addUserInfoUpdate(event.data);
  };

  const getAccountRolesOpts = async () => {
    const responseData = await request(getAccountRoles, '', 'Failed to retrieve role options');
    setRoles(responseData?.length > 0 ? responseData : []);
  };

  const request = async (call: () => Promise<any>, successAlertMsg: string, failureAlertMsg: string): Promise<any> => {
    return await executeAndAlert(
      call,
      successAlertMsg,
      failureAlertMsg,
      setAlertMessage,
      setAlertSeverity,
      setAlertIsOpen,
    );
  };

  return (
    <>
      <div style={containerStyle}>
        <div className={classes.outerDiv}>
          <div className={classes.gridWrapper}>
            <div style={gridStyle} className='ag-theme-alpine'>
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={colDefs}
                defaultColDef={defaultColDef}
                onCellValueChanged={cellValueChanged}
                onFirstDataRendered={autoSizeColumns}
              />
            </div>
          </div>
        </div>
      </div>
      <Alert
        open={alertIsOpen && alertMessage.length > 0}
        setOpen={setAlertIsOpen}
        message={alertMessage}
        severity={alertSeverity}
        autoHideDuration={5000}
      />
    </>
  );
};

export default UsersGrid;
