import React from 'react';

import { LineupCardStatus } from '../../../types/LineupCardStatus';
import { formatDate } from '../../../utils/timeDateUtil';

type CardHistoryModalProps = {
  card: LineupCardStatus | undefined;
  date: string;
};

const LINEUP_GCP_URL = process.env.LINEUP_GCP_URL;

const CardHistoryModal: React.FC<CardHistoryModalProps> = ({ card, date }) => {
  const displayVersionHistory = () => {
    const row = [];
    if (card?.version != undefined) {
      for (let i = 1; i <= card?.version; i++) {
        row.push(
          <>
            <h4>
              <a
                href={`${LINEUP_GCP_URL}/${formatDate(date, 'MM-DD-YYYY')}/${card.gamePk}/${
                  card.teamName
                }/V${i}-lineupcard.pdf`}
                key={i}
                // eslint-disable-next-line react/jsx-no-target-blank
                target='_blank'
              >
                Version {i}
              </a>
            </h4>
          </>,
        );
      }
    }
    return row;
  };

  return <div>{displayVersionHistory()}</div>;
};

export default CardHistoryModal;
