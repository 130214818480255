import { ColDef, ValueGetterParams } from 'ag-grid-community';
import DiffValuesCellRenderer, { valueGetter } from './CellRenderers/DiffValuesCellRenderer';

export const columnDefs: ColDef[] = [
  {
    field: 'editedAt',
    headerName: 'Edited At',
  },
  {
    field: 'status',
    headerName: 'Status',
  },
  {
    field: 'sport',
    headerName: 'Sport',
  },
  {
    field: 'leagueAbbrev',
    headerName: 'League',
  },
  {
    headerName: 'Away',
    cellRenderer: DiffValuesCellRenderer,
    cellRendererParams: {
      originalValueKey: 'awayTeam',
      updatedValueKey: 'updatedAwayTeam',
    },
    valueGetter: (params: ValueGetterParams): string => {
      return valueGetter(params?.data?.awayTeam, params?.data?.updatedAwayTeam);
    },
  },
  {
    headerName: 'Home',
    cellRenderer: DiffValuesCellRenderer,
    cellRendererParams: {
      originalValueKey: 'homeTeam',
      updatedValueKey: 'updatedHomeTeam',
    },
    valueGetter: (params: ValueGetterParams): string => {
      return valueGetter(params?.data?.homeTeam, params?.data?.updatedHomeTeam);
    },
  },
  {
    headerName: 'Date',
    cellRenderer: DiffValuesCellRenderer,
    cellRendererParams: {
      originalValueKey: 'date',
      updatedValueKey: 'updatedDate',
    },
    valueGetter: (params: ValueGetterParams): string => {
      return valueGetter(params?.data?.date, params?.data?.updatedDate);
    },
  },
  {
    headerName: 'Time',
    cellRenderer: DiffValuesCellRenderer,
    cellRendererParams: {
      originalValueKey: 'time',
      updatedValueKey: 'updatedTime',
    },
    valueGetter: (params: ValueGetterParams): string => {
      return valueGetter(params?.data?.time, params?.data?.updatedTime);
    },
  },
  {
    field: 'notes',
    headerName: 'Notes',
  },
];
