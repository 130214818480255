export type LineupCard = {
  firstName: string;
  lastName: string;
  pitcher?: boolean;
  twp?: boolean;
  statsId?: number;
  ebisId?: number;
  inStats?: PlayerStatus;
  inEbis?: PlayerStatus;
  rosterStatus?: string;
};

export type LineupCardLoading = {
  ebis: boolean;
  stats: boolean;
};

export enum PlayerStatus {
  NotPresent,
  Present,
  Unknown,
}

export type StatsBoxscore = {
  teams: {
    away: StatsTeamDetails;
    home: StatsTeamDetails;
  };
};

export type StatsTeamDetails = {
  players: Map<string, StatsPlayer[]>;
  team: StatsTeam;
};

export type StatsTeam = {
  abbreviation: string;
};

export type StatsPlayer = {
  person: StatsPerson;
};

export type StatsPerson = {
  id: number;
  firstName: string;
  lastName: string;
};

export type EbisOrgList = {
  ebisOrgList: EbisOrg[];
};

export type EbisOrg = {
  statsOrgCode: string;
};

export type EbisRoster = {
  players: EbisPerson[];
};

export type EbisPerson = {
  ebisId: number;
  statsPlayerId: number;
  preferredName: string;
  lastName: string;
  pitcher: boolean;
  twp: boolean;
  rosterStatus: string;
};
