const RESEARCH_API_URL = process.env.RESEARCH_API_URL;
const STATS_API_URL = process.env.STATS_API_URL;

/*
 * Research API
 */

export const getResearchApiUrlGamePreview = (gamePk: number): string => {
  return `${RESEARCH_API_URL}/games/${gamePk}/preview`;
};

export const getResearchApiUrlForPlay = (gamePk: number, playId: string): string => {
  return `${RESEARCH_API_URL}/games/${gamePk}/plays/${playId}`;
};

/*
 * Stats API
 */

export const getStatsApiUrlLiveGameFeed = (gamePk: number): string => {
  return `${STATS_API_URL}/v1.1/game/${gamePk}/feed/live`;
};

export const getStatsApiUrlLiveGameWithStrikeZoneAnalytics = (gamePk: number): string => {
  return `${STATS_API_URL}/v1/game/${gamePk}/guids?gameModeId=2&hydrate=analytics(strikeZone)`;
};
