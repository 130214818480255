import { AxiosResponse } from 'axios';
import React from 'react';
import { Color, Color as AlertSeverity } from '@material-ui/lab/Alert/Alert';

export const execute = async (call: () => Promise<any>): Promise<AxiosResponse> => {
  try {
    return await call();
  } catch (error) {
    console.log('Error executing rest request: ', error);
    return error.response;
  }
};

export const executeAndAlert = async (
  call: () => Promise<any>,
  successfulAlertMessage: string,
  failureAlertMessage: string,
  setAlertMessage: React.Dispatch<React.SetStateAction<string>>,
  setAlertSeverity: React.Dispatch<React.SetStateAction<Color>>,
  setAlertIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
): Promise<any> => {
  const response = await execute(call);
  handleResponse(
    response,
    successfulAlertMessage,
    failureAlertMessage,
    setAlertMessage,
    setAlertSeverity,
    setAlertIsOpen,
  );
  return response;
};

export const executeWithParams = async (call: (params: any) => Promise<any>, params: any): Promise<AxiosResponse> => {
  try {
    return await call(params);
  } catch (error) {
    console.log('Error executing rest request: ', error);
    return error.response;
  }
};

export const executeWithParamsAndAlert = async (
  call: (params: any) => Promise<any>,
  params: any,
  successfulAlertMessage: string,
  failureAlertMessage: string,
  setAlertMessage: React.Dispatch<React.SetStateAction<string>>,
  setAlertSeverity: React.Dispatch<React.SetStateAction<Color>>,
  setAlertIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
): Promise<any> => {
  const response = await executeWithParams(call, params);
  handleResponse(
    response,
    successfulAlertMessage,
    failureAlertMessage,
    setAlertMessage,
    setAlertSeverity,
    setAlertIsOpen,
  );
  return response;
};

const handleResponse = (
  response: AxiosResponse,
  successfulAlertMessage: string,
  failureAlertMessage: string,
  setAlertMessage: React.Dispatch<React.SetStateAction<string>>,
  setAlertSeverity: React.Dispatch<React.SetStateAction<Color>>,
  setAlertIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  if (isErrorResponse(response)) {
    console.log('Error response: ', response);
    setAlertMessage(failureAlertMessage);
    setAlertSeverity('error' as AlertSeverity);
    setAlertIsOpen(true);
  } else {
    if (successfulAlertMessage.length > 0) {
      setAlertMessage(successfulAlertMessage);
      setAlertSeverity('success' as AlertSeverity);
      setAlertIsOpen(true);
    }
  }
};

const isErrorResponse = (response: AxiosResponse): boolean => {
  return response === undefined || response.status < 200 || response.status > 299;
};
