import React, { useState, useCallback, useMemo } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControlLabel,
  Checkbox,
  Button,
} from '@material-ui/core';
import { BatteryStd, BatteryUnknown, Refresh } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import StyledTableCell from '../../Global/Tables/StyledTableCell';
import { getClockcomStatuses } from '../../../clients/BatRackClient';
import { ClockcomStatus } from '../../../types/Clockcom';
import useAsyncState from '../../../hooks/useAsyncState';

const Loading: React.FC = () => <div>Loading...</div>;

const LoadingError: React.FC = () => <div>Error loading ClockCom statuses</div>;

const Yep: React.FC = () => <Alert severity='success'>Yes</Alert>;

const Nope: React.FC = () => <Alert severity='error'>No</Alert>;

type ClockcomStatusesTableProps = {
  clockcomStatuses: ClockcomStatus[];
};

const NORMAL_CELL_WIDTH = 200;
const SKINNY_CELL_WIDTH = 140;

type BinaryDisplayProps = {
  intValue: number;
};

const TransmitterAck: React.FC<BinaryDisplayProps> = ({ intValue }) => (
  <Alert severity={intValue ? 'success' : 'error'} style={{ whiteSpace: 'nowrap' }}>
    <code
      style={{
        fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace',
      }}
    >
      {(intValue >>> 0).toString(2).padStart(8, '0')}
    </code>
  </Alert>
);

type ReceiverProps = {
  receiver: string;
};

const Receiver: React.FC<ReceiverProps> = ({ receiver }) => {
  switch (receiver) {
    case 'red':
    case 'blue':
    case 'black':
      return <BatteryStd style={{ color: receiver }}></BatteryStd>;
    default:
      return <BatteryUnknown style={{ color: 'gray' }}></BatteryUnknown>;
  }
};

type ReceiverAcksProps = {
  receivers: string[];
};

const getAlertTypeByReceiverAckCount = (receivers: string[]) => {
  if (receivers.length == 0) {
    return 'error';
  } else if (receivers.length == 1) {
    return 'warning';
  } else {
    return 'success';
  }
};

const ReceiverAcks: React.FC<ReceiverAcksProps> = ({ receivers }) => (
  <Alert severity={getAlertTypeByReceiverAckCount(receivers)} style={{ whiteSpace: 'nowrap' }}>
    {receivers.length ? receivers.map(receiver => <Receiver key={receiver} receiver={receiver} />) : 'None'}
  </Alert>
);

const displayGameState = (gameState: string) => {
  switch (gameState) {
    case 'OTHER':
      return 'Not in game';
    case 'LIVE':
      return 'Live';
    case 'WARMUP':
      return 'Warmup';
    default:
      return gameState || 'Unknown';
  }
};

const epochMillisToDate = (epoch: number) => (epoch ? new Date(epoch).toLocaleString() : 'N/A');

const ClockcomStatusesTable: React.FC<ClockcomStatusesTableProps> = ({ clockcomStatuses }) => (
  <TableContainer component={Paper} style={{ margin: 'auto' }}>
    <Table>
      <TableHead>
        <TableRow>
          <StyledTableCell style={{ maxWidth: NORMAL_CELL_WIDTH }}>Venue Code</StyledTableCell>
          <StyledTableCell style={{ maxWidth: NORMAL_CELL_WIDTH }}>Venue IP</StyledTableCell>
          <StyledTableCell style={{ maxWidth: SKINNY_CELL_WIDTH }}>Game State</StyledTableCell>
          <StyledTableCell style={{ maxWidth: NORMAL_CELL_WIDTH }}>ClockCom Version</StyledTableCell>
          <StyledTableCell style={{ maxWidth: SKINNY_CELL_WIDTH }}>Daktronics Connected</StyledTableCell>
          <StyledTableCell style={{ maxWidth: SKINNY_CELL_WIDTH }}>Transmitter Connected</StyledTableCell>
          <StyledTableCell style={{ maxWidth: NORMAL_CELL_WIDTH }}>Last Transmitter Ack</StyledTableCell>
          <StyledTableCell style={{ maxWidth: NORMAL_CELL_WIDTH }}>Last Receiver Ack</StyledTableCell>
          <StyledTableCell style={{ maxWidth: NORMAL_CELL_WIDTH }}>Active Receivers</StyledTableCell>
          <StyledTableCell style={{ maxWidth: NORMAL_CELL_WIDTH }}>Last FieldSense Buzz</StyledTableCell>
          <StyledTableCell style={{ maxWidth: NORMAL_CELL_WIDTH }}>Last FieldSense Wakeup</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {clockcomStatuses.map(
          ({
            clockcomVenue,
            clockcomServiceRunning,
            clockcomVersion,
            dakListenerRunning,
            transmitterConnected,
            mostRecentTransmitterAck,
            mostRecentReceiverAck,
            mostRecentBuzzRequestEpochMillis,
            mostRecentWakeupRequestEpochMillis,
            activeReceivers,
            gameState,
          }) => (
            <TableRow key={clockcomVenue.id}>
              <TableCell style={{ whiteSpace: 'nowrap', maxWidth: NORMAL_CELL_WIDTH }}>{clockcomVenue.code}</TableCell>
              <TableCell style={{ whiteSpace: 'nowrap', maxWidth: NORMAL_CELL_WIDTH }}>{clockcomVenue.ip}</TableCell>
              <TableCell style={{ whiteSpace: 'nowrap', maxWidth: SKINNY_CELL_WIDTH }}>
                {displayGameState(gameState)}
              </TableCell>
              <TableCell style={{ whiteSpace: 'nowrap', maxWidth: NORMAL_CELL_WIDTH }}>
                {clockcomServiceRunning ? (
                  <Alert severity='success' style={{ whiteSpace: 'nowrap' }}>
                    {clockcomVersion}
                  </Alert>
                ) : (
                  <Alert severity='error' style={{ whiteSpace: 'nowrap' }}>
                    Not Installed
                  </Alert>
                )}
              </TableCell>
              <TableCell style={{ whiteSpace: 'nowrap', maxWidth: SKINNY_CELL_WIDTH }}>
                {dakListenerRunning ? <Yep /> : <Nope />}
              </TableCell>
              <TableCell style={{ whiteSpace: 'nowrap', maxWidth: SKINNY_CELL_WIDTH }}>
                {transmitterConnected ? <Yep /> : <Nope />}
              </TableCell>
              <TableCell style={{ whiteSpace: 'nowrap', maxWidth: NORMAL_CELL_WIDTH }}>
                <TransmitterAck intValue={mostRecentTransmitterAck} />
              </TableCell>
              <TableCell style={{ whiteSpace: 'nowrap', maxWidth: NORMAL_CELL_WIDTH }}>
                <ReceiverAcks receivers={mostRecentReceiverAck} />
              </TableCell>
              <TableCell style={{ whiteSpace: 'nowrap', maxWidth: NORMAL_CELL_WIDTH }}>
                <ReceiverAcks receivers={activeReceivers} />
              </TableCell>
              <TableCell align='right' style={{ whiteSpace: 'nowrap', maxWidth: NORMAL_CELL_WIDTH }}>
                {epochMillisToDate(mostRecentBuzzRequestEpochMillis)}
              </TableCell>
              <TableCell align='right' style={{ whiteSpace: 'nowrap', maxWidth: NORMAL_CELL_WIDTH }}>
                {epochMillisToDate(mostRecentWakeupRequestEpochMillis)}
              </TableCell>
            </TableRow>
          ),
        )}
      </TableBody>
    </Table>
  </TableContainer>
);

const EMPTY_STATUSES = {
  statuses: [],
};

const ClockcomPage: React.FC = () => {
  const [showLive, setShowLive] = useState(true);
  const [showWarmup, setShowWarmup] = useState(true);
  const [showOther, setShowOther] = useState(true);
  const [reloadToggle, setReloadToggle] = useState(false);

  const { state: clockcomStatuses, loading, error: loadingError } = useAsyncState(getClockcomStatuses, EMPTY_STATUSES, [
    reloadToggle,
  ]);

  const filteredStatuses = useMemo(
    () =>
      clockcomStatuses.statuses.filter(
        ({ gameState }) =>
          (showLive || gameState !== 'LIVE') &&
          (showWarmup || gameState !== 'WARMUP') &&
          (showOther || gameState === 'LIVE' || gameState === 'WARMUP'),
      ),
    [clockcomStatuses, showLive, showWarmup, showOther],
  );
  const onLiveChecked = useCallback(e => setShowLive(e.target.checked), []);
  const onWarmupChecked = useCallback(e => setShowWarmup(e.target.checked), []);
  const onOtherChecked = useCallback(e => setShowOther(e.target.checked), []);
  const onReloadClicked = useCallback(() => setReloadToggle(!reloadToggle), [reloadToggle]);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '1em',
        }}
      >
        <div style={{ marginLeft: '1em' }}>
          <FormControlLabel
            control={<Checkbox checked={showLive} onChange={onLiveChecked} color='primary' />}
            label='Live'
          />
          <FormControlLabel
            control={<Checkbox checked={showWarmup} onChange={onWarmupChecked} color='primary' />}
            label='Warmup'
          />
          <FormControlLabel
            control={<Checkbox checked={showOther} onChange={onOtherChecked} color='primary' />}
            label='Other'
          />
        </div>
        <Button variant='outlined' color='secondary' endIcon={<Refresh />} onClick={onReloadClicked}>
          Reload
        </Button>
      </div>
      {loading ? (
        <Loading />
      ) : loadingError ? (
        <LoadingError />
      ) : (
        <ClockcomStatusesTable clockcomStatuses={filteredStatuses} />
      )}
    </div>
  );
};

export default ClockcomPage;
