import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import RouteConstants from '../constants/RouteConstants';
import { LoginCallback, SecureRoute } from '@okta/okta-react';
import Login from '../components/Authentication/Login';
import { makeStyles } from '@material-ui/core/styles';
import { useRoles } from '../context/RoleContext';
import BatRackNavigationMenu from '../constants/NavigationMenuConstants';
import { NavigationMenuItem, Page } from '../types/NavigationMenu';
import { Roles } from '../constants/RolesConstants';
import NotFoundPage from '../components/Global/NotFound/NotFoundPage';

const MLBAuthSessionAuthenticator = React.lazy(() => import('../components/MLBAuth/MLBAuthSessionAuthenticator'));

const useStyles = makeStyles(() => ({
  container: {
    padding: '16px',
  },
}));

const AuthenticatedApp: React.FC = () => {
  const classes = useStyles();
  const {
    isSuperAdminUser,
    isAdminUser,
    isUnprocessedUser,
    isABSUser,
    isBatrackUser,
    isLineupCardsUser,
    isMLBAuthUser,
    isPitchClockUser,
    isBATTERUser,
    hasRole,
  } = useRoles();

  const getBasePage = (): string => {
    if (isSuperAdminUser || isAdminUser || hasRole(Roles.BATRACK_DUGOUT_USER)) {
      return RouteConstants.BASE;
    } else if (isUnprocessedUser) {
      return RouteConstants.NOT_FOUND;
    } else if (isABSUser) {
      return RouteConstants.CHALLENGES;
    } else if (isBatrackUser) {
      return RouteConstants.GAMES;
    } else if (isBATTERUser) {
      return RouteConstants.BEAST_AUDIT_REPORT;
    } else if (isLineupCardsUser) {
      return RouteConstants.LINEUP_CARDS;
    } else if (isMLBAuthUser) {
      return RouteConstants.MLB_AUTH;
    } else if (isPitchClockUser) {
      return RouteConstants.EXPIRIES;
    }
    return RouteConstants.NOT_FOUND;
  };

  return (
    <div className={classes.container}>
      <React.Suspense fallback={<div />}>
        <Switch>
          <Route path={RouteConstants.LOGIN_CALLBACK} exact component={LoginCallback} />
          {BatRackNavigationMenu.map((item: NavigationMenuItem) =>
            item.pages.map((page: Page) => {
              if (
                hasRole(item.appRole) ||
                isSuperAdminUser ||
                (isAdminUser && item.appRole !== Roles.BATRACK_SUPER_ADMIN)
              ) {
                return (
                  <SecureRoute key={page.route} exact path={page.route}>
                    {page.component}
                  </SecureRoute>
                );
              }
            }),
          )}
          <Route path={RouteConstants.MLB_AUTH_SESSION_AUTHENTICATOR} exact component={MLBAuthSessionAuthenticator} />
          <Route path={RouteConstants.LOGIN}>
            <Login />
          </Route>
          <Route path={RouteConstants.NOT_FOUND} component={NotFoundPage} />
          <Redirect to={getBasePage()} />
        </Switch>
      </React.Suspense>
    </div>
  );
};

export default AuthenticatedApp;
