import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Theme,
} from '@material-ui/core';
import { getABSAutomaticBalls, getPitchClockExpiries } from '../../../clients/BatRackClient';
import { PitchClockExpiryType } from '../../../types/PitchClockExpiry';
import { KeyboardDatePicker } from '@material-ui/pickers';
import ExpiriesList from './ExpiriesList';
import theme from '../../../utils/theme';
import AutomaticBallsList from './AutomaticBallsList';
import { AutomaticBallType } from '../../../types/AutomaticBall';

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    padding: spacing(2),
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  cardContent: {
    padding: 'unset',
    '&:last-child': {
      paddingBottom: '0',
    },
  },
  link: {
    display: 'block',
    marginBottom: '8px',
    color: 'black',
    width: 'max-content',
  },
  select: {
    height: '40px',
    display: 'flex',
    alignSelf: 'flex-end',
  },
  selectWrapper: {
    marginBottom: '6px',
    marginLeft: '16px',
    marginTop: '8px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: '0',
      display: 'inline-block',
      marginTop: '16px',
    },
  },
}));

const ExpiriesPage: React.FC = () => {
  const classes = useStyles();

  const [date, setDate] = useState<Date>(new Date());
  const [expiries, setExpiries] = useState<PitchClockExpiryType[]>([]);
  const [automaticBalls, setAutomaticBalls] = useState<AutomaticBallType[]>([]);
  const [gamePks, setGamePks] = useState<string[]>([]);
  const [expiryGamePks, setExpiryGamePks] = useState<string[]>([]);
  const [automaticBallsGamePks, setAutomaticBallsGamePks] = useState<string[]>([]);
  const [selectedGamePk, setSelectedGamePk] = useState<string>('ALL');

  const getExpiries = async () => {
    const dateStr = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    const result: PitchClockExpiryType[] = await getPitchClockExpiries(dateStr);
    setExpiries(result);
    setExpiryGamePks(result.map(expiry => (!expiry.gamePk ? 'N/A' : expiry.gamePk.toString())));
  };

  const getAutomaticBalls = async () => {
    const dateStr = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    const result: AutomaticBallType[] = await getABSAutomaticBalls(dateStr);
    setAutomaticBalls(result);
    setAutomaticBallsGamePks(result.map(ball => (!ball.gamePk ? 'N/A' : ball.gamePk.toString())));
  };

  const updateGamePks = (expiryGamePks: string[], automaticBallsGamePks: string[]) => {
    const expirySet = new Set(expiryGamePks);
    const automaticBallsSet = new Set(automaticBallsGamePks);
    setGamePks(Array.from(new Set([...expirySet, ...automaticBallsSet])));
  };

  const handleGamePkChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    const gamePk = event.target.value as string;
    setSelectedGamePk(gamePk);
  };

  useEffect(() => {
    getExpiries();
    getAutomaticBalls();
  }, [date]);

  useEffect(() => {
    updateGamePks(expiryGamePks, automaticBallsGamePks);
  }, [expiryGamePks, automaticBallsGamePks]);

  return (
    <div className={classes.root}>
      <Link
        className={classes.link}
        href='https://looker.mlb.com/dashboards/1710?Venue+ID=&Ballpark+Clock+Date=14+day'
        underline='hover'
        target='_blank'
      >
        Pendulum Looker Dashboard
      </Link>
      <Link
        className={classes.link}
        href='https://grafana.shared.mlbinfra.com/d/milb-pitch-clocks/milb-pitch-clocks?orgId=1&var-park=buf1&var-park=cbs1&var-park=chr1&var-park=drm1&var-park=elp1&var-park=gwi1&var-park=ind1&var-park=iow1&var-park=jax1&var-park=lhv1&var-park=lou1&var-park=lvg1&var-park=mrb1&var-park=nas1&var-park=nor1&var-park=okc1&var-park=oma1&var-park=ren1&var-park=roc1&var-park=rre1&var-park=src1&var-park=slc1&var-park=sls1&var-park=src1&var-park=stp1&var-park=swb1&var-park=syr1&var-park=tac1&var-park=tol1&var-park=wor1&var-park=alb1'
        underline='hover'
        target='_blank'
      >
        MiLB Moxa Status
      </Link>
      <Link
        className={classes.link}
        href='https://grafana.shared.mlbinfra.com/d/ballpark-ops-historic-avail/ballpark-historical-availability?orgId=1&var-park=ari1&var-category=video&var-host=All&viewPanel=13'
        underline='hover'
        target='_blank'
      >
        MLB Moxa Status
      </Link>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card className={classes.card}>
            <KeyboardDatePicker
              id='date-picker-inline'
              label='Game Date'
              required={true}
              format='MM/dd/yyyy'
              variant='inline'
              disableToolbar
              margin='normal'
              value={date}
              onChange={(date: Date | null) => {
                if (date) {
                  setDate(date);
                  setSelectedGamePk('ALL');
                }
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <FormControl size='small' className={classes.selectWrapper}>
              <InputLabel>Game PK</InputLabel>
              <Select
                margin='dense'
                className={classes.select}
                style={{ minWidth: 135 }}
                value={selectedGamePk}
                onChange={handleGamePkChange}
              >
                <MenuItem value='ALL'>ALL</MenuItem>
                {gamePks.sort().map(gamePk => {
                  return (
                    <MenuItem key={gamePk} value={gamePk}>
                      {gamePk}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader subheader='Expiries' />
            <CardContent className={classes.cardContent}>
              {<ExpiriesList expiries={expiries} gamePk={selectedGamePk} />}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader subheader='GUMBO Automatic Balls' />
            <CardContent className={classes.cardContent}>
              <AutomaticBallsList automaticBalls={automaticBalls} gamePk={selectedGamePk} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default ExpiriesPage;
