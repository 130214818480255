import React, { useEffect, useState } from 'react';
import { createVenueConfig, deleteVenueConfig, fetchAllVenueConfigs } from '../../../clients/ABSScoreboardClient';
import { VenueConfig } from '../../../types/ABSScoreboard';
import StyledTable, { StyledTableColumn } from '../../Global/Tables/StyledTable';
import { Button, FormControl, IconButton, Input, InputLabel, Link, Modal, Typography } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette, shadows, spacing }) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: palette.background.paper,
    boxShadow: shadows[5],
    padding: spacing(4),
    outline: 'none',
  },
  addNew: {},
  modalButtons: {
    marginTop: spacing(2),
  },
  saveButton: {
    marginRight: spacing(2),
  },
  filters: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing(2),
  },
  searchContainer: {
    marginLeft: spacing(2),
  },
}));

type DeleteVenueConfigFormatterProps = {
  value: string;
};

type VenueGuidFormatterProps = {
  value: string;
};

const SliderPage: React.FC = () => {
  const classes = useStyles();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [venueConfigs, setVenueConfigs] = useState<VenueConfig[]>([]);
  const [displayVenueConfigs, setDisplayVenueConfigs] = useState<VenueConfig[]>([]);
  const [teamId, setTeamId] = useState('');
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    getData().then();
  }, []);

  const getData = async () => {
    const result: VenueConfig[] = await fetchAllVenueConfigs();
    setVenueConfigs(result);
  };

  const sortVenueConfigs = (r1: VenueConfig, r2: VenueConfig): number => {
    const venueId1: number = parseInt(r1.venueId);
    const venueId2: number = parseInt(r2.venueId);
    if (venueId1 > venueId2) {
      return 1;
    } else if (venueId1 < venueId2) {
      return -1;
    } else {
      return 0;
    }
  };

  const searchVenueConfigs = (venueConfig: VenueConfig, searchText: string): boolean => {
    if (!!searchText?.length && !!venueConfig) {
      return (
        venueConfig.venueId?.toLowerCase()?.includes(searchText) ||
        venueConfig.venueGuid?.toLowerCase()?.includes(searchText) ||
        venueConfig.league?.toLowerCase()?.includes(searchText) ||
        venueConfig.teamCode?.toLowerCase()?.includes(searchText)
      );
    } else {
      return true;
    }
  };

  useEffect(() => {
    setDisplayVenueConfigs(venueConfigs.filter(vc => searchVenueConfigs(vc, searchText)).sort(sortVenueConfigs));
  }, [venueConfigs, searchText]);

  const DeleteVenueConfigFormatter: React.FC<DeleteVenueConfigFormatterProps> = ({ value }) => {
    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(false);

    const openModal = () => {
      setOpen(true);
    };

    const closeModal = () => {
      setOpen(false);
    };

    const deleteVenueConfigCall = async () => {
      try {
        await deleteVenueConfig(value);
        closeModal();
        getData();
      } catch (e) {
        console.error(e);
        alert(e);
      }
    };

    return (
      <div>
        <Modal
          open={open}
          onClose={closeModal}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <Typography>Are you sure you want to delete the venueGuid: {value}</Typography>
              <div className={classes.modalButtons}>
                <Button
                  className={classes.saveButton}
                  onClick={deleteVenueConfigCall}
                  variant='outlined'
                  color='primary'
                >
                  Delete
                </Button>
                <Button onClick={closeModal} variant='outlined' color='secondary'>
                  Cancel
                </Button>
              </div>
            </div>
          </Fade>
        </Modal>

        <IconButton onClick={openModal}>
          <DeleteIcon />
        </IconButton>
      </div>
    );
  };

  const VenueGuidFormatter: React.FC<VenueGuidFormatterProps> = ({ value }) => {
    return (
      <Link href={`${process.env.ABS_SCOREBOARD_UI_URL}?venueGuid=${value}`} target='_blank' rel='noreferrer'>
        {value}
      </Link>
    );
  };

  const tableColumns: StyledTableColumn[] = [
    { field: 'venueGuid', title: '', formatter: DeleteVenueConfigFormatter },
    { field: 'venueGuid', title: 'Venue Guid', formatter: VenueGuidFormatter },
    { field: 'venueId', title: 'Venue ID' },
    { field: 'teamCode', title: 'Team', upperCase: true },
  ];

  const createVenueConfigCall = async (teamId: number | string) => {
    try {
      await createVenueConfig(teamId);
      setShowModal(false);
      await getData();
      setTeamId('');
    } catch (e) {
      console.error(e);
    }
  };

  const saveVenueConfig = () => {
    createVenueConfigCall(teamId);
  };

  const closeModal = () => {
    setShowModal(false);
    setTeamId('');
  };

  return (
    <div>
      <Modal
        open={showModal}
        onClose={closeModal}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showModal}>
          <div className={classes.paper}>
            <Typography>Add a Venue Config</Typography>
            <FormControl>
              <InputLabel>Team ID</InputLabel>
              <Input value={teamId} onChange={event => setTeamId(event.target.value)} required={true} />
            </FormControl>
            <div className={classes.modalButtons}>
              <Button className={classes.saveButton} onClick={saveVenueConfig} variant='outlined' color='primary'>
                Save
              </Button>
              <Button onClick={closeModal} variant='outlined' color='secondary'>
                Cancel
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
      <div>
        <div className={classes.filters}>
          <Button className={classes.addNew} onClick={() => setShowModal(true)} variant='outlined' color='primary'>
            Add New Venue Config
          </Button>
          <FormControl className={classes.searchContainer}>
            <InputLabel>Search...</InputLabel>
            <Input value={searchText} onChange={event => setSearchText(event.target.value)} />
          </FormControl>
        </div>

        <StyledTable tableColumns={tableColumns} data={displayVenueConfigs} />
      </div>
    </div>
  );
};

export default SliderPage;
