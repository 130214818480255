import { TeamName } from './TeamName';

export const ALLOWED_INCORRECT_CHALLENGES = 3;
export const INITIAL_TEAM_CHALLENGES: PitchChallengesCount = { challenges: 0, overturned: 0, upheld: 0 };

export enum PITCH_CHALLENGE_RESULT {
  OVERTURNED = 'OVERTURNED',
  UPHELD = 'UPHELD',
}

export type PitchChallenge = {
  playId: string;
  inning: number;
  halfInning: string;
  batterName: string;
  pitcherName: string;
  playResult: string;
  pitchChallengeResult: PITCH_CHALLENGE_RESULT;
  pitchChallengeTeamId: number;
};

export type PitchChallengesCount = {
  challenges: number;
  overturned: number;
  upheld: number;
};

export type GamePitchChallenges = {
  gamePk: number;
  awayTeam: TeamName;
  awayTeamId: number;
  homeTeam: TeamName;
  homeTeamId: number;
  pitchChallenges: PitchChallenge[];
};
export interface GamePitchChallengeRow {
  gamePitchChallenges: GamePitchChallenges;
  expanded: boolean;
}
