import React from 'react';
import { LineupCard } from '../../../../../types/LineupCard';
import { TableHead, TableRow, TableSortLabel, Tooltip } from '@material-ui/core';
import StyledTableCell from '../../../../Global/Tables/StyledTableCell';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export type Order = 'asc' | 'desc';

export function getComparator<Key extends keyof LineupCard>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string | boolean | LineupCard | undefined },
  b: { [key in Key]: number | string | boolean | LineupCard | undefined },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

interface HeadCell {
  id: keyof LineupCard;
  label: string;
}

const headCells: HeadCell[] = [
  { id: 'firstName', label: 'First Name' },
  { id: 'lastName', label: 'Last Name' },
  { id: 'statsId', label: 'Stats ID' },
];

type LineupCardsTableHeaderCellsProps = {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof LineupCard) => void;
  order: Order;
  orderBy: string;
};

const LineupCardsTableHeaderCells: React.FC<LineupCardsTableHeaderCellsProps> = ({ onRequestSort, order, orderBy }) => {
  const createSortHandler = (property: keyof LineupCard) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <StyledTableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </StyledTableCell>
        ))}
        <StyledTableCell>EBIS ID</StyledTableCell>
        <Tooltip title='Indicates if a player is in Stats lineup for selected game'>
          <StyledTableCell>In Stats</StyledTableCell>
        </Tooltip>
        <Tooltip title='Indicates if a player is on EBIS roster'>
          <StyledTableCell>In EBIS</StyledTableCell>
        </Tooltip>
        <Tooltip title='Indicates if a player has an active status on EBIS roster'>
          <StyledTableCell>EBIS Status</StyledTableCell>
        </Tooltip>
      </TableRow>
    </TableHead>
  );
};

export default LineupCardsTableHeaderCells;
