import React from 'react';
import {
  createStyles,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core';
import { Venue } from '../../../types/Venue';
import StyledTableCell from '../../Global/Tables/StyledTableCell';
import { makeStyles } from '@material-ui/core/styles';
import { Clear } from '@material-ui/icons';
import { deleteRaulVenue } from '../../../clients/BatRackClient';
import Alert from '../../Global/Alert';
import { Color as AlertSeverity } from '@material-ui/lab/Alert/Alert';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'inline-flex',
      maxHeight: 'calc(100% - 50px)',
      width: 'max-content',
      [theme.breakpoints.down('xs')]: {
        margin: '0 auto',
      },
    },
    table: {
      height: 'max-content',
      position: 'sticky',
    },
    removeButton: {
      fontSize: '0.5rem',
      padding: '0',
    },
  }),
);

type RaulVenuesTableProps = {
  venues: Venue[];
  refreshVenues: () => Promise<void>;
};

const RaulVenuesTable: React.FC<RaulVenuesTableProps> = ({ venues, refreshVenues }) => {
  const classes = useStyles();

  const [alertIsOpen, setAlertIsOpen] = React.useState(false);
  const [alertSeverity, setAlertSeverity] = React.useState('success' as AlertSeverity);

  const columnNames = ['Venue ID', 'Venue Name', ''];

  const removeRaulVenue = async (venue: Venue) => {
    try {
      await deleteRaulVenue(venue);
      setAlertSeverity('success');
    } catch (error) {
      if ([401, 403].includes(error.response?.status)) {
        return;
      }
      setAlertSeverity('error');
    }
    await refreshVenues();
    setAlertIsOpen(true);
  };

  return (
    <div className={classes.container}>
      <TableContainer>
        <Table stickyHeader={true} size={'small'} className={classes.table}>
          <TableHead>
            <TableRow>
              {columnNames.map(columnName => (
                <StyledTableCell key={columnName}>{columnName}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {venues.map(venue => (
              <TableRow key={venue.venueId}>
                <TableCell>{venue.venueId}</TableCell>
                <TableCell>{venue.name}</TableCell>
                <TableCell>
                  <IconButton onClick={() => removeRaulVenue(venue)} className={classes.removeButton}>
                    <Clear />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Alert
        open={alertIsOpen}
        setOpen={setAlertIsOpen}
        message={`${alertSeverity === 'success' ? 'Venue Removed Successfully' : 'Venue Remove Failed'}`}
        severity={alertSeverity}
        autoHideDuration={5000}
      />
    </div>
  );
};

export default RaulVenuesTable;
