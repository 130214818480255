import { Play } from '../../../../types/Game';
import React from 'react';
import PlayLookupCard from './PlayLookupCard';

type PlayDetailsProps = {
  play: Play;
};

const PlayDetails: React.FC<PlayDetailsProps> = ({ play }) => {
  const inningString = (): string => {
    const halfInning = play.halfInning.charAt(0).toUpperCase() + play.halfInning.slice(1);
    return `${halfInning} of the ${play.inning}`;
  };

  const listItems = new Map<string, string | number | boolean>([
    ['Play ID', play.playId],
    ['Game PK', play.gamePk],
    ['Inning', inningString()],
    ['Count', `Balls: ${play?.count?.balls}, Strikes: ${play?.count?.strikes}, Outs: ${play?.count?.outs}`],
    ['Play Result', play.playResult],
    ['Play Index', play?.playIndex?.playIndex],
    ['Is Pitch', play.isPitch],
    ['Created', play.createdTs],
    ['Updated', play.updatedTs],
  ]);

  return <PlayLookupCard cardTitle='Play Details' listItems={listItems} />;
};

export default PlayDetails;
