import React, { ChangeEvent, useEffect, useState } from 'react';
import { GameType } from '../../../types/Game';
import { getEbisRosterByStatsOrgCode, getEbisTeams, getTodaysGames } from '../../../clients/BatRackClient';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from '@material-ui/core';
import LineupCardsTable from './LineupCardsTable/LineupCardsTable';
import { getBoxscore } from '../../../clients/StatsApiClient';
import { EbisRoster, LineupCardLoading, StatsPerson } from '../../../types/LineupCard';
import Alert from '../../Global/Alert';
import { Refresh, Stars } from '@material-ui/icons';
import LoadingOverlay from '../../Global/LoadingOverlay';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: '8px',
  },
  grid: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  tableSelectionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '20px 15px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  teamSelect: {
    minWidth: '100px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '25px',
    },
  },
  gameSelect: {
    minWidth: '150px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '25px',
    },
  },
  showSelect: {
    minWidth: '145px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '25px',
    },
  },
  refreshButton: {
    marginTop: '8px',
  },
  optionedPlayersToggle: {
    marginTop: '8px',
  },
  optionedPlayersToggleColor: {
    '&.Mui-checked': {
      color: theme.palette.primary.dark,
    },
  },
}));

const LineupCardsPage: React.FC = () => {
  const classes = useStyles();

  const tableDisplayOpts = [
    { label: 'All', value: 'all' },
    { label: 'EBIS Roster', value: 'ebisRoster' },
    { label: 'Stats API Roster', value: 'statsRoster' },
    { label: 'Differences', value: 'diffs' },
  ];

  const [teamOpts, setTeamOpts] = useState<string[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<string>('');
  const [ebisRoster, setEbisRoster] = useState<EbisRoster>();
  const [statsRoster, setStatsRoster] = useState<StatsPerson[]>([]);
  const [todaysGames, setTodaysGames] = useState<Map<string, GameType[]>>(new Map());
  const [selectedTeamGames, setSelectedTeamGames] = useState<GameType[]>([]);
  const [selectedGame, setSelectedGame] = useState<string>('');
  const [tableDisplayOpt, setTableDisplayOpt] = useState<string>('all');
  const [showOptionedPlayers, setShowOptionedPlayers] = useState<boolean>(false);
  const [loading, setLoading] = useState<LineupCardLoading>({ ebis: false, stats: false });
  const [loadingMsg, setLoadingMsg] = useState<string>('');
  const [alertIsOpen, setAlertIsOpen] = useState<boolean>(false);

  useEffect(() => {
    getTeamOpts();
    getAllGamesForToday();
  }, []);

  useEffect(() => {
    handleSelectedTeamChange();
  }, [selectedTeam]);

  useEffect(() => {
    if (selectedTeamGames.length > 0) {
      setSelectedGame(selectedTeamGames[0].gamePk.toString());
    }
  }, [selectedTeamGames]);

  useEffect(() => {
    handleSelectedGameChange();
  }, [selectedGame]);

  useEffect(() => {
    const ebisLoadingMsg = `Retrieving EBIS Roster for ${selectedTeam}`;
    const statsLoadingMsg = `Retrieving Stats Roster For Game ${selectedGame}`;
    if (loading.ebis && loading.stats) {
      setLoadingMsg(`\t${ebisLoadingMsg}\n${statsLoadingMsg}`);
    } else if (loading.ebis) {
      setLoadingMsg(ebisLoadingMsg);
    } else if (loading.stats) {
      setLoadingMsg(statsLoadingMsg);
    } else {
      setLoadingMsg('');
    }
  }, [loading]);

  const getTeamOpts = async () => {
    const result = await getEbisTeams();
    const teams: string[] = [];
    result.ebisOrgList.forEach(ebisOrg => {
      teams.push(ebisOrg.statsOrgCode);
    });
    teams.push('AAS');
    teams.push('NAS');
    setTeamOpts(teams);
  };

  const getAllGamesForToday = async () => {
    const result: Map<string, GameType[]> = await getTodaysGames();
    for (const [k, v] of Object.entries(result)) {
      setTodaysGames(new Map(todaysGames.set(k, v)));
    }
  };

  const getEbisRosterForSelectedTeam = async () => {
    setEbisRoster(undefined);
    setLoading(state => ({ ...state, ebis: true }));
    const ebisResult = await getEbisRosterByStatsOrgCode(selectedTeam);
    setEbisRoster(ebisResult);
    setLoading(state => ({ ...state, ebis: false }));
  };

  const getStatsRoster = async () => {
    setLoading(state => ({ ...state, stats: true }));
    const boxscore = await getBoxscore(parseInt(selectedGame));
    if (boxscore?.teams?.away && boxscore?.teams?.home) {
      const teamToCompare = selectedTeam === 'AAS' ? 'AL' : selectedTeam === 'NAS' ? 'NL' : selectedTeam;
      const isAway = boxscore.teams.away.team?.abbreviation === teamToCompare;
      const boxscorePlayers = isAway ? boxscore.teams.away?.players : boxscore.teams.home?.players;
      const statsRoster: StatsPerson[] = [];
      Object.values(boxscorePlayers).forEach(p => {
        const person: StatsPerson = { id: p.person.id, firstName: p.person.firstName, lastName: p.person.lastName };
        statsRoster.push(person);
      });
      setStatsRoster(statsRoster);
    }
    setLoading(state => ({ ...state, stats: false }));
  };

  const selectGame = (event: ChangeEvent<{ value: string | unknown }>) => {
    setSelectedGame(event.target.value as string);
  };

  const selectTeam = (event: ChangeEvent<{ value: string | unknown }>) => {
    setSelectedGame(''); // Prevent Games dropdown from having invalid option selected
    const team = event.target.value as string;
    setSelectedTeam(team);
  };

  const handleSelectedTeamChange = () => {
    if (selectedTeam?.length == 0) {
      return;
    }
    setAlertIsOpen(false);
    setStatsRoster([]);
    // All Star
    if (selectedTeam !== 'AAS' && selectedTeam !== 'NAS') {
      getEbisRosterForSelectedTeam();
    } else {
      setEbisRoster(undefined);
    }
    findGamesForSelectedTeam();
  };

  const handleSelectedGameChange = () => {
    if (selectedGame?.length === 0) {
      return;
    }
    getStatsRoster();
  };

  const handleSelectedFilterChange = (event: ChangeEvent<{ value: string | unknown }>) => {
    setTableDisplayOpt(event.target.value as string);
  };

  const handleShowOptionedPlayersChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowOptionedPlayers(event.target.checked);
  };

  const findGamesForSelectedTeam = () => {
    if (selectedTeam?.length === 0) {
      return;
    }
    const teamGames = todaysGames.get(selectedTeam);
    setSelectedTeamGames(teamGames || []);
    setAlertIsOpen(!teamGames);
  };

  const isLoading = (): boolean => {
    return loading.ebis || loading.stats;
  };

  return (
    <div className={classes.root}>
      <LoadingOverlay open={isLoading()} loadingMsg={loadingMsg} />
      <Grid item xs={12} className={classes.grid}>
        <Paper>
          <FormGroup className={classes.tableSelectionsContainer}>
            <FormControl size='small' className={classes.teamSelect}>
              <InputLabel>Team</InputLabel>
              <Select value={selectedTeam} onChange={selectTeam} disabled={!(teamOpts?.length > 0)}>
                {teamOpts.map(teamOpt => (
                  <MenuItem key={teamOpt} value={teamOpt}>
                    {teamOpt}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl size='small' className={classes.gameSelect}>
              <InputLabel>Game</InputLabel>
              <Select value={selectedGame} onChange={selectGame} disabled={!(selectedTeamGames?.length > 0)}>
                {todaysGames.get(selectedTeam)?.map(game => (
                  <MenuItem key={game.gamePk} value={game.gamePk}>
                    {game.gamePk}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl size='small' className={classes.showSelect}>
              <InputLabel>Show</InputLabel>
              <Select value={tableDisplayOpt} onChange={handleSelectedFilterChange} disabled={!ebisRoster?.players}>
                {tableDisplayOpts.map(filter => (
                  <MenuItem key={filter.value} value={filter.value}>
                    {filter.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl size='small'>
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.optionedPlayersToggleColor}
                    icon={<Stars />}
                    checkedIcon={<Stars />}
                    checked={showOptionedPlayers}
                    onChange={handleShowOptionedPlayersChange}
                    disabled={!ebisRoster?.players}
                  />
                }
                label='Show inactive players?'
                className={classes.optionedPlayersToggle}
              />
            </FormControl>
            <FormControl>
              <Button
                className={classes.refreshButton}
                variant='contained'
                color='primary'
                onClick={getEbisRosterForSelectedTeam}
                disabled={!ebisRoster}
              >
                <Refresh />
              </Button>
            </FormControl>
          </FormGroup>
          <LineupCardsTable
            loading={isLoading()}
            statsRoster={statsRoster}
            ebisRoster={ebisRoster?.players}
            tableDisplayOpt={tableDisplayOpt}
            showOptionedPlayers={showOptionedPlayers}
            showStatsInfo={selectedTeamGames?.length > 0}
          />
          <Alert
            open={alertIsOpen && !isLoading()}
            setOpen={setAlertIsOpen}
            message={`${selectedTeam} has no games today`}
            severity={'info'}
            autoHideDuration={5000}
          />
        </Paper>
      </Grid>
    </div>
  );
};

export default LineupCardsPage;
