import { Backdrop, CircularProgress, createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'center',
    },
    loadingMessage: {
      color: 'white',
      whiteSpace: 'pre-wrap',
    },
  }),
);

type LoadingOverlayProps = {
  open: boolean;
  loadingMsg?: string;
};

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ open, loadingMsg }) => {
  const classes = useStyles();
  return (
    <div>
      <Backdrop open={open} className={classes.backdrop}>
        <CircularProgress />
        {loadingMsg && <h5 className={classes.loadingMessage}>{loadingMsg}</h5>}
      </Backdrop>
    </div>
  );
};

export default LoadingOverlay;
