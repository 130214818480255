import { ColDef, CsvExportParams, ProcessCellForExportParams } from 'ag-grid-community';
import { BeastAuditReportRow } from '../../../types/BeastAuditReport';
import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { columnDefs } from './BeastAuditReportGridColumnDefs';
import { Button } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

const useStyles = makeStyles(() => ({
  outerDiv: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  gridWrapper: {
    flex: '1 1 auto',
  },
  exportBtnContainer: {
    alignItems: 'baseline',
    display: 'inline-flex',
    bottom: '10px',
  },
}));

type BeastAuditReportAgGridProps = {
  rowData: BeastAuditReportRow[];
};

const BeastAuditReportGrid: React.FC<BeastAuditReportAgGridProps> = ({ rowData }) => {
  const classes = useStyles();

  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const gridRef = useRef<AgGridReact>(null);
  const [colDefs] = useState<ColDef[]>(columnDefs);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      filter: true,
      resizable: true,
      sortable: true,
      autoHeight: true,
    };
  }, []);

  useEffect(() => {
    if (gridRef?.current?.api) {
      gridRef.current.api.setRowData(rowData);
    }
  }, [rowData]);

  const autoSizeColumns = useCallback(() => {
    const allColumnIds: string[] = [];
    gridRef?.current?.columnApi?.getAllColumns()?.forEach(column => {
      allColumnIds.push(column.getId());
    });
    gridRef?.current?.columnApi?.autoSizeColumns(allColumnIds, false);
  }, []);

  const exportToCsv = useCallback(() => {
    const params: CsvExportParams = {
      allColumns: true,
      columnSeparator: ';',
      fileName: `beast-audit-report-${new Date().toISOString()}.csv`,
      processCellCallback(params: ProcessCellForExportParams): string {
        return params.value.replace('\n', ', ');
      },
    };
    gridRef?.current?.api?.exportDataAsCsv(params);
  }, []);

  return (
    <>
      <div className={classes.exportBtnContainer}>
        <Button variant='contained' color='primary' onClick={exportToCsv}>
          <GetApp />
        </Button>
      </div>
      <div style={containerStyle}>
        <div className={classes.outerDiv}>
          <div className={classes.gridWrapper}>
            <div style={gridStyle} className='ag-theme-alpine'>
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={colDefs}
                defaultColDef={defaultColDef}
                onFirstDataRendered={autoSizeColumns}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BeastAuditReportGrid;
