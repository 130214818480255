import React, { useEffect, useState } from 'react';

import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';

import { getTeams } from '../../../clients/BatRackClient';
import { Team } from '../../../types/Team';
import Alert from '../../Global/Alert';
import TeamRow from './TeamRow';
import TeamsTableControlBar from './TeamsTableControlBar';
import StyledTableCell from '../../Global/Tables/StyledTableCell';

const TeamsTable = () => {
  const [teams, setTeams] = useState<Team[]>([]);
  const [divisionFilter, setDivisionFilter] = useState<string>('ALL');
  const [divisions, setDivisions] = useState<string[]>([]);
  const [alertIsOpen, setAlertIsOpen] = React.useState(false);

  const refreshTeams = async () => {
    try {
      const teams: Team[] = await getTeams();
      setTeams(teams);
      setDivisions(Array.from(new Set(teams.map(team => (!team.division ? 'N/A' : team.division)))));
    } catch (error) {
      if ([401, 403].includes(error.response?.status)) {
        return;
      }
      setTeams([]);
      setAlertIsOpen(true);
    }
  };

  const filterTeams = (division: string) => {
    setDivisionFilter(division);
  };

  useEffect(() => {
    refreshTeams();
  }, []);

  const columnNames = [
    'Team',
    'Opponent',
    'Game Start',
    'Game Status',
    '# Plays',
    '# Video',
    'Team Sync Status',
    'Sync Status Override',
    'Video',
  ];

  return (
    <div>
      <TeamsTableControlBar divisions={divisions} filterTeams={filterTeams} />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columnNames.map(columnName => (
                <StyledTableCell key={columnName}>{columnName}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {teams
              .filter(
                team =>
                  divisionFilter === 'ALL' ||
                  (!team.division ? divisionFilter === 'N/A' : team.division === divisionFilter),
              )
              .sort((teamA, teamB) => teamA.code.localeCompare(teamB.code))
              .map(team => (
                <TeamRow key={team.code} {...team} refreshTeams={refreshTeams} />
              ))}
          </TableBody>
        </Table>
        <Alert
          open={alertIsOpen}
          setOpen={setAlertIsOpen}
          message='There was an error fetching team data.'
          autoHideDuration={15000}
        />
      </TableContainer>
    </div>
  );
};

export default TeamsTable;
