import { SyncStatusOverride } from '../types/SyncStatusOverride';
import { RawTeam, Team } from '../types/Team';
import { batrackV1Client } from '../utils/axios';
import { SportId } from '../types/SportId';
import { GamePlays, GameType, Play } from '../types/Game';
import { SyncStrategy } from '../types/SyncStrategy';
import { EbisOrgList, EbisRoster } from '../types/LineupCard';
import { UserInfo } from '../types/UserInfo';
import { BatterTeam, BeastAuditReportRow } from '../types/BeastAuditReport';
import { Venue } from '../types/Venue';
import { Role } from '../types/Role';
import { DelayType } from '../types/DelayType';
import { ClockcomStatuses } from '../types/Clockcom';
import { PitchClockExpiryType } from '../types/PitchClockExpiry';
import { AutomaticBallType } from '../types/AutomaticBall';
import { GameChangeMessages } from '../types/GameChangeMessage';
import { GamePitchChallenges } from '../types/PitchChallenge';

export const importsOktaUsers = async (): Promise<never> => {
  return await batrackV1Client.post('/importOktaAccounts');
};

export const getAccountRoles = async (): Promise<Role[]> => {
  return (await batrackV1Client.get('/role/all')).data.roles;
};

export const getUserInfo = async (): Promise<UserInfo> => {
  return (await batrackV1Client.get('/userInfo')).data;
};

export const updateUserInfo = async (userInfo: UserInfo): Promise<UserInfo> => {
  return (await batrackV1Client.put('/userInfo', userInfo)).data;
};

export const getAllUsersInfo = async (): Promise<UserInfo[]> => {
  return (await batrackV1Client.get('/userInfo/all')).data.users;
};

export const getTeams = async (): Promise<Team[]> => {
  const { data } = await batrackV1Client.get('/teams');
  return data.map((team: RawTeam) => Team.fromRawTeam(team));
};

export const updateSyncStatusOverride = async (teamCode: string, override: SyncStatusOverride): Promise<void> => {
  await batrackV1Client.patch(`/teams/${teamCode}`, [{ op: 'replace', path: '/syncStatusOverride', value: override }], {
    headers: { 'Content-Type': 'application/json-patch+json' },
  });
};

export const updateInGameVideoAllowed = async (teamCode: string, allowed: boolean): Promise<void> => {
  await batrackV1Client.patch(`/teams/${teamCode}`, [{ op: 'replace', path: '/inGameVideoAllowed', value: allowed }], {
    headers: { 'Content-Type': 'application/json-patch+json' },
  });
};

export const getTodaysGames = async (): Promise<Map<string, GameType[]>> =>
  (await batrackV1Client.get('/games?includeSpringTraining=true')).data.games;

export const getTodaysRaulGames = async (): Promise<Map<string, GameType[]>> =>
  (await batrackV1Client.get('/games?raulGames=true')).data.games;

export const loadGame = async (gamePk: string, date: string, mp4Mode: boolean): Promise<void> => {
  await batrackV1Client.put(`/games/${gamePk}?date=${date}&mp4Mode=${mp4Mode}`, null, {
    headers: { 'Content-Type': 'application/json' },
  });
};

export const resetGame = async (gamePk: string): Promise<void> => {
  await batrackV1Client.delete(`/games/${gamePk}/plays`);
};

export const updateGameTestability = async (gamePk: string, testable: boolean): Promise<void> => {
  await batrackV1Client.patch(`/games/${gamePk}/`, [{ op: 'replace', path: '/testable', value: testable }], {
    headers: { 'Content-Type': 'application/json-patch+json' },
  });
};

export const getAllSportIds = async (): Promise<SportId[]> => (await batrackV1Client.get('/sportIds')).data;

export const getGamePlays = async (gamePk: number): Promise<GamePlays> =>
  (await batrackV1Client.get(`/games/${gamePk}/plays`)).data;

export const getTabsDifferingCalls = async (gamePk: number): Promise<GamePlays> =>
  (await batrackV1Client.get(`/games/${gamePk}/plays/differing-abs-umpire-call`)).data;

export const getPlayByPlayId = async (playId: string): Promise<Play> =>
  (await batrackV1Client.get(`/plays/${playId}`)).data;

export const getTabsTeams = async (date?: string): Promise<GameType[]> => {
  const url = date ? `/tabs/games?date=${date}` : `/tabs/games`;
  return (await batrackV1Client.get(url)).data;
};

export const getGamePitchChallengesByDateAndSportId = async (
  date: string,
  sportId: number,
): Promise<GamePitchChallenges[]> => {
  const url = `/tabs/games/pitch-challenges?date=${date}&sportId=${sportId}`;
  return (await batrackV1Client.get(url)).data;
};

export const getSyncStrategies = async (): Promise<SyncStrategy[]> =>
  (await batrackV1Client.get('/sync-strategies')).data;

export const updateActiveSyncStrategy = async (syncStrategy: string): Promise<void> => {
  await batrackV1Client.put(`sync-strategies/${syncStrategy}/active`);
};

export const getEbisTeams = async (): Promise<EbisOrgList> => (await batrackV1Client.get('ebis/orgs')).data;

export const getEbisRosterByStatsOrgCode = async (statsOrgCode: string): Promise<EbisRoster> =>
  (await batrackV1Client.get(`ebis/orgs/roster?statsOrgCode=${statsOrgCode}`)).data;

export const getBatterTeams = async (): Promise<BatterTeam[]> => (await batrackV1Client.get('batter/teams')).data;

export const getBeastAuditReport = async (
  startDate: string,
  endDate: string,
  teamIds: string,
  sport: string,
): Promise<BeastAuditReportRow[]> =>
  (
    await batrackV1Client.get(
      `batter/beast-audit-report?startDate=${startDate}&endDate=${endDate}&teamIds=${teamIds}&sport=${sport}`,
    )
  ).data;

export const getGameChangeMessagesByDate = async (startDate: string, endDate: string): Promise<GameChangeMessages> =>
  (await batrackV1Client.get(`game-change-messages?startDate=${startDate}&endDate=${endDate}`)).data;

export const getAllRaulVenues = async (): Promise<Venue[]> =>
  (await batrackV1Client.get('pitch-clock/raul-venues')).data.venues;

export const postNewRaulVenue = async (venue: Venue): Promise<Venue> =>
  await batrackV1Client.post('pitch-clock/raul-venues', { venueId: venue.id });

export const deleteRaulVenue = async (venue: Venue): Promise<Venue> =>
  await batrackV1Client.delete(`pitch-clock/raul-venues/${venue.venueId}`);

export const getAllDelayTypes = async (): Promise<DelayType[]> =>
  (await batrackV1Client.get('delay-type/all')).data.delayTypes;

export const getPitchClockExpiries = async (date?: string): Promise<PitchClockExpiryType[]> => {
  const url = date ? `/pitch-clock/expiries?date=${date}` : `/pitch-clock/expiries`;
  return (await batrackV1Client.get(url)).data.pitchClockExpiries;
};

export const getClockcomStatuses = async (): Promise<ClockcomStatuses> => {
  return (await batrackV1Client.get('/pitch-clock/clockcom/statuses')).data;
};

export const getABSAutomaticBalls = async (date?: string): Promise<AutomaticBallType[]> => {
  const url = date ? `/plays/automaticBalls/abs?date=${date}` : `/plays/automaticBalls/abs`;
  return (await batrackV1Client.get(url)).data.automaticBalls;
};
