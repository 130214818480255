import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { SyncStrategy } from '../../../../types/SyncStrategy';
import StyledTableCell from '../../../Global/Tables/StyledTableCell';

const useStyles = makeStyles(() => ({
  card: {
    maxWidth: '800px',
    margin: '0 auto',
    marginBottom: '24px',
  },
  active: {
    color: 'black',
  },
  notActive: {
    color: 'darkgrey',
  },
}));

const columnNames = ['Sync Strategy', 'Active', 'Updated By', 'Updated Time'];

type SyncStrategyTableProps = {
  syncStrategies: SyncStrategy[];
};

const SyncStrategyTable: React.FC<SyncStrategyTableProps> = ({ syncStrategies }) => {
  const classes = useStyles();

  syncStrategies.sort((a, b) => a.syncStrategy.localeCompare(b.syncStrategy));

  return (
    <Card className={classes.card}>
      <TableContainer component={Card}>
        <Table>
          <TableHead>
            <TableRow>
              {columnNames.map(columnName => (
                <StyledTableCell key={columnName}>{columnName}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {syncStrategies.map((syncStrategy: SyncStrategy) => (
              <TableRow key={syncStrategy.syncStrategy}>
                <TableCell>{syncStrategy.syncStrategy}</TableCell>
                <TableCell className={syncStrategy.active ? classes.active : classes.notActive}>
                  {String(syncStrategy.active)}
                </TableCell>
                <TableCell>{syncStrategy.updatedUser}</TableCell>
                <TableCell>{syncStrategy.updatedTimestamp}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default SyncStrategyTable;
