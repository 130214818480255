import React, { useEffect, useState } from 'react';
import { SportId } from '../../../types/SportId';
import { getAllSportIds } from '../../../clients/BatRackClient';
import { Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import StyledTableCell from '../../Global/Tables/StyledTableCell';

const columnNames = ['Sport ID', 'Sport Name', 'Sync Game', 'Sync Plays', 'Sync Video', 'Pull Games'];

const SportIDPage: React.FC = () => {
  const [sportIds, setSportIds] = useState<SportId[]>([]);

  const getData = async () => {
    const result: SportId[] = await getAllSportIds();
    setSportIds(result);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Paper>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columnNames.map(columnName => (
                <StyledTableCell key={columnName}>{columnName}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sportIds
              .sort((sportId1: SportId, sportId2: SportId) => (sportId1.sportId > sportId2.sportId ? 1 : -1))
              .map((sportId: SportId) => (
                <TableRow key={sportId.sportId}>
                  <TableCell>{sportId.sportId}</TableCell>
                  <TableCell>{sportId.sportName}</TableCell>
                  <TableCell>
                    <Checkbox
                      checked={sportId.syncGame}
                      onChange={() => console.log('cant click')}
                      color='primary'
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={sportId.syncPlays}
                      onChange={() => console.log('cant click')}
                      color='primary'
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={sportId.syncVideo}
                      onChange={() => console.log('cant click')}
                      color='primary'
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={sportId.pullGames}
                      onChange={() => console.log('cant click')}
                      color='primary'
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default SportIDPage;
