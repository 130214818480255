import React, { useEffect, useState } from 'react';
import { LineupCard, PlayerStatus } from '../../../../../types/LineupCard';
import { makeStyles } from '@material-ui/core/styles';
import { TableCell, TableFooter, TableRow } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  ebisTotals: {
    backgroundColor: 'white',
    bottom: 0,
    position: 'sticky',
  },
}));

type LineupCardsTableFooterProps = {
  lineupCards: LineupCard[];
};

const LineupCardsTableFooter: React.FC<LineupCardsTableFooterProps> = ({ lineupCards }) => {
  const classes = useStyles();
  const [ebisPlayerTotal, setEbisPlayerTotal] = useState<number>(0);
  const [ebisRosterPitchersTotal, setEbisRosterPitchersTotal] = useState<number>(0);

  useEffect(() => {
    const ebisRoster = lineupCards.filter(lc => lc.inEbis === PlayerStatus.Present);
    setEbisPlayerTotal(ebisRoster.filter(lc => !lc.pitcher || (lc.pitcher && lc.twp)).length);
    setEbisRosterPitchersTotal(ebisRoster.filter(lc => lc.pitcher && !lc.twp).length);
  }, [lineupCards]);

  return (
    <TableFooter className={classes.ebisTotals}>
      <TableRow>
        <TableCell colSpan={7} align={'right'}>
          <p>EBIS Player Count: {ebisPlayerTotal}</p>
          <p>EBIS Pitcher Count: {ebisRosterPitchersTotal}</p>
        </TableCell>
      </TableRow>
    </TableFooter>
  );
};

export default LineupCardsTableFooter;
