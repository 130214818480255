import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Button,
  createStyles,
  FormControl,
  FormGroup,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { getBatterTeams, getBeastAuditReport } from '../../../clients/BatRackClient';
import { BatterTeam, BeastAuditReportRow } from '../../../types/BeastAuditReport';
import { Season } from '../../../types/Season';
import { getSeason } from '../../../clients/StatsApiClient';
import Alert from '../../Global/Alert';

const useStyles = makeStyles(theme =>
  createStyles({
    formGroup: {
      alignItems: 'baseline',
      display: 'inline-flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      paddingBottom: '20px',
      width: 'calc(100% - 80px)',
    },
    formControl: {
      margin: '0 1rem',
      width: '225px',
    },
    runReportBtnContainer: {
      alignSelf: 'flex-end',
      bottom: '12px',
      marginLeft: 'auto',
      '& .MuiButton-label': {
        fontWeight: 600,
      },
      [theme.breakpoints.down('sm') || theme.breakpoints.down('xs')]: {
        bottom: '0',
        margin: '1rem 0 0 1rem',
      },
    },
  }),
);

type BeastAuditReportFormProps = {
  setReport: React.Dispatch<React.SetStateAction<BeastAuditReportRow[]>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const BeastAuditReportForm: React.FC<BeastAuditReportFormProps> = ({ setReport, setLoading }) => {
  const classes = useStyles();
  // Placeholder variables. In the future BATTER will support MiLB
  const sport = 'MLB';
  const sportId = 1;

  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [teams, setTeams] = useState<BatterTeam[]>([]);
  const [selectedTeams, setSelectedTeams] = useState<string[]>([]);
  const [alertIsOpen, setAlertIsOpen] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState('');

  useEffect(() => {
    getSeasonStartAndEndDates();
    getTeamsFromBatter();
  }, []);

  const generateReport = async () => {
    setReport([]);
    setLoading(true);
    try {
      const start = startDate.toISOString().slice(0, 10);
      const end = endDate.toISOString().slice(0, 10);
      const teamIds = selectedTeams.toString();
      const result = await getBeastAuditReport(start, end, teamIds, sport);
      setReport(result);
    } catch (error) {
      if ([401, 403].includes(error.response?.status)) {
        return;
      }
      setAlertMessage('Failed to generate Beast Audit Report');
      setAlertIsOpen(true);
    }
    setLoading(false);
  };

  const getSeasonStartAndEndDates = async () => {
    try {
      const season: Season = await getSeason(sportId);
      const start = new Date(season.regularSeasonStartDate);
      const end = new Date(season.regularSeasonEndDate);
      // TypeScript displays dates in local time zones. Add the UTC offset to display correct date.
      const offsetMs = start.getTimezoneOffset() * 60000;
      setStartDate(new Date(start.getTime() + offsetMs));
      setEndDate(new Date(end.getTime() + offsetMs));
    } catch (error) {
      if ([401, 403].includes(error.response?.status)) {
        return;
      }
      setAlertMessage('Failed to retrieve season start and end dates from Stats API');
      setAlertIsOpen(true);
    }
  };

  const getTeamsFromBatter = async () => {
    try {
      const result: BatterTeam[] = await getBatterTeams();
      setTeams(result);
    } catch (error) {
      if ([401, 403].includes(error.response?.status)) {
        return;
      }
      setAlertMessage('Failed to retrieve teams from BATTER');
      setAlertIsOpen(true);
    }
  };

  const selectTeam = (event: ChangeEvent<{ value: unknown }>) => {
    setSelectedTeams(event.target.value as string[]);
  };

  return (
    <>
      <FormGroup className={classes.formGroup}>
        <FormControl className={classes.formControl}>
          <KeyboardDatePicker
            id='date-picker-inline'
            label='Start Date'
            required={true}
            format='MM/dd/yyyy'
            variant='inline'
            disableToolbar
            margin='normal'
            autoOk={true}
            value={startDate}
            onChange={(date: Date | null) => {
              if (date) {
                setStartDate(date);
              }
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <KeyboardDatePicker
            id='date-picker-inline'
            label='End Date'
            required={true}
            format='MM/dd/yyyy'
            variant='inline'
            disableToolbar
            margin='normal'
            autoOk={true}
            value={endDate}
            onChange={(date: Date | null) => {
              if (date) {
                setEndDate(date);
              }
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel>Team</InputLabel>
          <Select multiple value={selectedTeams || ''} onChange={selectTeam}>
            {teams.map(team => (
              <MenuItem key={team.id} value={team.id}>
                {team.fullTeamName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={classes.runReportBtnContainer}>
          <Button variant='contained' color='primary' onClick={generateReport}>
            {'Run Report'}
          </Button>
        </FormControl>
      </FormGroup>
      <Alert
        open={alertIsOpen}
        setOpen={setAlertIsOpen}
        message={alertMessage}
        severity='error'
        autoHideDuration={5000}
      />
    </>
  );
};

export default BeastAuditReportForm;
