import React, { useEffect, useState } from 'react';
import { Checkbox } from '@material-ui/core';
import { getGumboHydratePerson } from '../../../../clients/StatsApiClient';

type LineupCheckProps = {
  gamePk: number;
};

const LineupCheck: React.FC<LineupCheckProps> = ({ gamePk }: LineupCheckProps) => {
  const [hasLineup, setHasLineup] = useState(false);

  const lineupExists = (gameData: any): boolean => {
    return !!gameData?.liveData?.boxscore?.teams?.home?.players && !!gameData?.liveData?.boxscore?.teams?.away?.players;
  };

  const checkHasLineup = async () => {
    const result = await getGumboHydratePerson(gamePk);
    setHasLineup(lineupExists(result));
  };

  useEffect(() => {
    checkHasLineup();
  }, [gamePk]);

  return (
    <Checkbox
      checked={hasLineup}
      onChange={checkHasLineup}
      color='primary'
      inputProps={{ 'aria-label': 'secondary checkbox' }}
    />
  );
};

export default LineupCheck;
