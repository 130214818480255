import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import dugoutLogo from '../../assets/baseball-logo.png';
import {
  AppBar,
  Button,
  ClickAwayListener,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuIcon from '@material-ui/icons/Menu';
import { useAuth } from '../../context/AuthContext';
import { useRoles } from '../../context/RoleContext';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import NavigationMenuConstants from '../../constants/NavigationMenuConstants';
import { NavigationMenuItem, Page } from '../../types/NavigationMenu';
import { Roles } from '../../constants/RolesConstants';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingRight: '15px',
    width: '100%',
    margin: '0 auto',
  },
  title: {
    flex: 1,
  },
  menu: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  emailText: {
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  icon: {
    color: theme.palette.primary.contrastText,
    minWidth: 0,
    padding: '5px',
  },
  dugoutLogo: {
    width: '55px',
    marginRight: '10px',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  missingMenuButton: {
    width: '48px',
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  list: {
    paddingTop: '0',
  },
  navigationMenuLabel: {
    backgroundColor: theme.palette.primary.main,
    '& .MuiListItemText-primary': {
      fontWeight: 700,
    },
  },
  pageSelected: {
    color: theme.palette.primary.dark,
    '& span.MuiListItemText-primary': {
      fontWeight: 550,
    },
    '& svg.MuiSvgIcon-root': {
      color: theme.palette.primary.dark,
    },
  },
}));

const BatRackHeader: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const { isSuperAdminUser, isAdminUser, isUnprocessedUser, hasRole } = useRoles();
  const [menuIsOpen, setMenuIsOpen] = React.useState(false);
  const { state: authState, dispatch: authDispatch } = useAuth();
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [navigationMenu, setNavigationMenu] = React.useState([...NavigationMenuConstants]);

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setMenuIsOpen(false);
  };

  const handleToggle = () => {
    setMenuIsOpen(prevOpen => !prevOpen);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const toggleNavigationMenuLabel = (navigationMenuLabel: string) => {
    const updatedNavigationMenu = navigationMenu;
    updatedNavigationMenu.filter(item => item.label === navigationMenuLabel).map(item => (item.open = !item.open));
    setNavigationMenu([...updatedNavigationMenu]);
  };

  return (
    <>
      {authState?.loggedIn ? (
        <>
          <AppBar position='sticky' elevation={0}>
            <Toolbar className={classes.toolbar}>
              {!isUnprocessedUser ? (
                <IconButton
                  color='inherit'
                  aria-label='open drawer'
                  onClick={handleDrawerOpen}
                  edge='start'
                  className={clsx(classes.menuButton, drawerOpen && classes.hide)}
                >
                  <MenuIcon />
                </IconButton>
              ) : (
                <div className={classes.missingMenuButton} />
              )}
              <img src={dugoutLogo} alt='batrack' className={classes.dugoutLogo} />
              <Typography variant='h5' className={classes.title}>
                BEST Admin
              </Typography>

              <div className={classes.menu}>
                <Typography className={classes.emailText}>{authState!.email}</Typography>
                <Button ref={anchorRef} onClick={handleToggle} className={classes.icon}>
                  <MoreVertIcon />
                </Button>
                <Popper
                  open={menuIsOpen}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                  modifiers={{
                    offset: {
                      enabled: true,
                      offset: '-12, 0',
                    },
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList autoFocusItem={menuIsOpen} id='menu-list-grow'>
                        <MenuItem onClick={() => authDispatch!({ type: 'logout' })}>Logout</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Popper>
              </div>
            </Toolbar>
          </AppBar>
          <Drawer
            className={classes.drawer}
            variant='temporary'
            anchor='left'
            open={drawerOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeader} />
            <List className={classes.list}>
              {navigationMenu
                .filter(
                  item =>
                    hasRole(item.appRole) ||
                    isSuperAdminUser ||
                    (isAdminUser && item.appRole !== Roles.BATRACK_SUPER_ADMIN),
                )
                .map((item: NavigationMenuItem) => (
                  <div key={`${item.label} - Root`}>
                    <ListItem
                      button
                      key={item.label}
                      className={classes.navigationMenuLabel}
                      onClick={() => toggleNavigationMenuLabel(item.label)}
                    >
                      <ListItemText primary={item.label} />
                      {item.open ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={item.open} timeout='auto' unmountOnExit>
                      <List disablePadding>
                        {item.pages.map((page: Page) => {
                          return (
                            <ListItem
                              button
                              key={page.name}
                              className={location.pathname === page.route ? classes.pageSelected : ''}
                              component={Link}
                              to={page.route}
                              onClick={handleDrawerClose}
                            >
                              <ListItemIcon>{page.icon}</ListItemIcon>
                              <ListItemText primary={page.name} />
                            </ListItem>
                          );
                        })}
                      </List>
                    </Collapse>
                  </div>
                ))}
            </List>
          </Drawer>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default BatRackHeader;
