import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Message } from '../../../../types/GameChangeMessage';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { makeStyles } from '@material-ui/core/styles';
import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { columnDefs } from './GameChangesGridColDefs';

const useStyles = makeStyles(() => ({
  outerDiv: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  gridWrapper: {
    flex: '1 1 auto',
  },
}));

type GameChangesGridProps = {
  rowData: Message[];
};

const GameChangesGrid: React.FC<GameChangesGridProps> = ({ rowData }) => {
  const classes = useStyles();

  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const gridRef = useRef<AgGridReact>(null);
  const [colDefs] = useState<ColDef[]>(columnDefs);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      filter: true,
      resizable: true,
      sortable: true,
      autoHeight: true,
    };
  }, []);

  const autoSizeColumns = useCallback(() => {
    const allColumnIds: string[] = [];
    gridRef?.current?.columnApi?.getAllColumns()?.forEach(column => {
      allColumnIds.push(column.getId());
    });
    gridRef?.current?.columnApi?.autoSizeColumns(allColumnIds, false);
  }, []);

  useEffect(() => {
    if (gridRef?.current?.api) {
      gridRef.current.api.setRowData(rowData);
    }
  }, [rowData]);

  return (
    <div style={containerStyle}>
      <div className={classes.outerDiv}>
        <div className={classes.gridWrapper}>
          <div style={gridStyle} className='ag-theme-alpine'>
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              columnDefs={colDefs}
              defaultColDef={defaultColDef}
              onFirstDataRendered={autoSizeColumns}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameChangesGrid;
