import React, { useState } from 'react';
import { Color as AlertSeverity } from '@material-ui/lab/Alert/Alert';
import { loadGame } from '../../../../clients/BatRackClient';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '../../../Global/Alert';
import { KeyboardDatePicker } from '@material-ui/pickers';

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: '700px',
    margin: '0 auto',
    marginBottom: '24px',
  },
  grid: {
    marginTop: '8px',
    display: 'flex',
    justifyContent: 'center',
  },
  textField: {
    marginTop: '10px',
    marginRight: '16px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: '16px',
    },
  },
  button: {
    marginTop: '10px',
    maxHeight: '40px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: '0',
      marginTop: '16px',
    },
  },
  formGroup: {
    marginTop: '10px',
    marginLeft: '16px',
  },
}));

const LoadGame = () => {
  const classes = useStyles();
  const [gamePk, setGamePk] = React.useState('');
  const [date, setDate] = useState<Date>(new Date());
  const [mp4Mode, setMp4Mode] = React.useState(false);
  const [alertIsOpen, setAlertIsOpen] = React.useState(false);
  const [alertSeverity, setAlertSeverity] = React.useState('success' as AlertSeverity);

  const handleLoadGame = async () => {
    try {
      setGamePk('');
      const statsApiDate = date.toISOString().slice(0, 10); // yyyy-MM-dd
      await loadGame(gamePk, statsApiDate, mp4Mode);
      setAlertSeverity('success');
    } catch (error) {
      if ([401, 403].includes(error.response?.status)) {
        return;
      }
      setAlertSeverity('error');
    }
    setAlertIsOpen(true);
  };

  return (
    <Card variant={'outlined'} className={classes.card}>
      <CardContent>
        <Typography color='textSecondary' gutterBottom>
          Load Game
        </Typography>
        <Grid className={classes.grid} container>
          <TextField
            className={classes.textField}
            id='outlined-basic'
            label='Game PK'
            variant='outlined'
            size='small'
            value={gamePk}
            onChange={e => setGamePk(e.target.value)}
          />
          <KeyboardDatePicker
            id='date-picker-inline'
            label='Game Date'
            required={true}
            format='MM/dd/yyyy'
            variant='inline'
            disableToolbar
            style={{ width: '150px' }}
            value={date}
            onChange={(date: Date | null) => {
              if (date) {
                setDate(date);
              }
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
          <FormGroup className={classes.formGroup}>
            <FormControlLabel
              control={<Checkbox value={mp4Mode} onChange={e => setMp4Mode(e.target.checked)} color='primary' />}
              label='MP4 Mode'
            />
          </FormGroup>
          <Button
            className={classes.button}
            variant='contained'
            color='primary'
            onClick={() => handleLoadGame()}
            disabled={!gamePk || gamePk.length < 1 || !date}
          >
            Load Game
          </Button>
          <Alert
            open={alertIsOpen}
            setOpen={setAlertIsOpen}
            message={`${alertSeverity === 'success' ? 'Game Loaded Successfully' : 'Game Load Failed'}`}
            severity={alertSeverity}
            autoHideDuration={5000}
          />
        </Grid>
      </CardContent>
    </Card>
  );
};

export default LoadGame;
