import {
  Card,
  CardContent,
  CardHeader,
  createStyles,
  Divider,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      height: '750px',
      marginTop: '1rem',
      overflow: 'auto',
    },
    cardHeader: {
      backgroundColor: 'black',
      color: 'white',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    cardContent: {
      whiteSpace: 'pre-line',
    },
  }),
);

type PlayDetailsCardProps = {
  cardTitle: string;
  listItems: Map<string, string | number | boolean>;
};

const PlayLookupCard: React.FC<PlayDetailsCardProps> = ({ cardTitle, listItems }) => {
  const classes = useStyles();

  const listItemText = (value: string | number | boolean) => {
    return value != null ? value.toString() : '';
  };

  return (
    <Card className={classes.card}>
      <CardHeader className={classes.cardHeader} title={cardTitle} />
      <CardContent className={classes.cardContent}>
        <List dense={true}>
          {Array.from(listItems.entries()).map(entry => (
            <div key={entry[0]}>
              <ListItem alignItems='flex-start'>
                <ListItemText primary={entry[0]} secondary={listItemText(entry[1])} />
              </ListItem>
              <Divider />
            </div>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default PlayLookupCard;
