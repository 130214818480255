import { Play } from '../../../../types/Game';
import React from 'react';
import PlayLookupCard from './PlayLookupCard';

type PitchDetailsProps = {
  play: Play;
};

const PitchDetails: React.FC<PitchDetailsProps> = ({ play }) => {
  const listItems = new Map<string, string | number | boolean>([
    ['Pitcher', `${play.pitcherName} (${play.pitcherId})`],
    ['Pitch Type', play?.pitchData?.pitchType],
    ['Pitch Speed', play?.pitchData?.pitchSpeed],
    ['Pitch Extension', play?.pitchData?.pitchExtension],
    ['Spin Rate', play?.pitchData?.spinRate],
    ['SZ Top', play?.pitchData?.szTop],
    ['SZ Bottom', play?.pitchData?.szBottom],
    ['SZ Depth Inches', play?.pitchData?.szDepthInches],
    ['SZ Width Inches', play?.pitchData?.szWidthInches],
    ['PFX X', play?.pitchData?.pfxX],
    ['PFX Z', play?.pitchData?.pfxZ],
    ['Horizontal Break', play?.pitchData?.horizontalBreak],
    ['Vertical Break', play?.pitchData?.verticalBreak],
    ['Vertical Break Induced', play?.pitchData?.verticalBreakInduced],
    ['Edge Distance (Feet)', play?.pitchData?.edgeDistance],
    ['Track Coordinates', play?.pitchData?.trackingCoordinates],
    ['X Coordinate', play?.pitchData?.xcoordinate],
    ['Z Coordinate', play?.pitchData?.zcoordinate],
  ]);

  return <PlayLookupCard cardTitle='Pitch Details' listItems={listItems} />;
};

export default PitchDetails;
