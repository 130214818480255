import React from 'react';
import { LocalStorageError } from '../../types/Error';
import { batrackV1Client } from '../../utils/axios';
import Alert from '../Global/Alert';
import { useAuth, WrapperProps } from '../../context/AuthContext';

const BatrackHttpInterceptor = ({ children }: WrapperProps) => {
  const { dispatch: authDispatch } = useAuth();
  const [alertIsOpen, setAlertIsOpen] = React.useState(false);

  batrackV1Client.interceptors.request.use(
    request => {
      const oktaToken = localStorage.getItem('okta-token-storage');

      if (!oktaToken) {
        throw new LocalStorageError('Cannot find okta-token-storage.');
      }

      const { accessToken } = JSON.parse(oktaToken);

      if (!accessToken?.accessToken) {
        throw new LocalStorageError('Failed to parse accessToken.');
      }

      request.headers.Authorization = `Bearer ${accessToken.accessToken}`;
      return request;
    },
    error => {
      authDispatch!({ type: 'logout' });
      setAlertIsOpen(true);
    },
  );

  batrackV1Client.interceptors.response.use(
    response => response,
    error => {
      const errorStatus = error.response?.status;
      const userIsUnauthorized = [401, 403].includes(errorStatus);
      if (userIsUnauthorized) {
        authDispatch!({ type: 'logout' });
        setAlertIsOpen(true);
      }
      return Promise.reject(error);
    },
  );

  return (
    <>
      {children}
      <Alert
        open={alertIsOpen}
        setOpen={setAlertIsOpen}
        message='Your credentials have expired. Please log in again.'
      />
    </>
  );
};

export default BatrackHttpInterceptor;
