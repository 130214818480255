export type SSeEmitter = {
  timeout: number;
};

export type SseListener = {
  eventTypes: string[];
  emitter: SSeEmitter;
  status: string;
  groups?: string[];
  channel: string;
  ipAddress: string;
  podId: string;
  authenticated: boolean;
};

export type SseListenerData = {
  eventTypes: string;
  status: string;
  groups: string;
  channel: string;
  ipAddress: string;
  podId: string;
  authenticated: string;
};

export const flattenListener = (listener: SseListener): SseListenerData => {
  return {
    eventTypes: listener?.eventTypes?.join(',') || '',
    status: listener?.status,
    groups: listener?.groups?.join(',') || '',
    channel: listener?.channel,
    ipAddress: listener?.ipAddress,
    podId: listener?.podId,
    authenticated: listener?.authenticated ? 'true' : 'false',
  };
};
