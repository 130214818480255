import { common } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core';

export default createMuiTheme({
  typography: {
    h5: {
      fontSize: 20,
      fontWeight: 'bold',
    },
    body2: {
      fontSize: 16,
    },
  },
  palette: {
    primary: {
      main: '#A8C6FA',
      dark: '#6EA0F7',
      contrastText: common.white,
    },
    success: {
      main: '#7AEB7A',
      light: '#D2F8D2',
      dark: '#4EE44E',
    },
    error: {
      main: '#FF5148',
      light: '#FFB2AE',
      dark: '#FF2015',
    },
  },
});
