import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Button, FormControl, FormGroup, Grid, Paper, TextField, Modal, Typography } from '@material-ui/core';
import LineupCardsTable from './LineupCardsTable/LineupCardsTable';
import { Refresh } from '@material-ui/icons';
import LoadingOverlay from '../../Global/LoadingOverlay';
import { LineupCardStatus } from '../../../types/LineupCardStatus';
import { getStatusList } from '../../../clients/LineupClient';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CardHistoryModal from './CardHistoryModal';
import { formatDate } from '../../../utils/timeDateUtil';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: '8px',
  },
  grid: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  tableSelectionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '20px 15px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  teamSelect: {
    minWidth: '100px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '25px',
    },
  },
  refreshButton: {
    marginTop: '8px',
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
  },
}));

const LineupCardsStatus: React.FC = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [date, setDate] = useState<string>('');
  const [lineupStatusList, setLineupStatusList] = useState<LineupCardStatus[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalCard, setModalCard] = useState<LineupCardStatus | undefined>(undefined);
  const todayDate: string = formatDate(new Date().toDateString(), 'YYYY-MM-DD');

  useEffect(() => {
    getLineupStatusList(formatDate(todayDate, 'MM/DD/YYYY'));
  }, []);

  const isLoading = (): boolean => {
    return loading;
  };

  const getLineupStatusList = async (date: string) => {
    setLoading(true);
    const responseData = getStatusList(date);
    setLineupStatusList(await responseData);
    setDate(date);
    setLoading(false);
  };

  const handleOpenModal = (card: LineupCardStatus) => {
    setShowModal(true);
    setModalCard(card);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <div className={classes.root}>
      <LoadingOverlay open={isLoading()} loadingMsg='Fetching Status' />
      <Grid item xs={12} className={classes.grid}>
        <Paper>
          <FormGroup className={classes.tableSelectionsContainer}>
            <FormControl size='small' className={classes.teamSelect}>
              <TextField
                id='date'
                label='Game Date'
                type='date'
                defaultValue={todayDate}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={e => {
                  getLineupStatusList(formatDate(e.target.value, 'MM/DD/YYYY'));
                  setDate(formatDate(e.target.value, 'MM/DD/YYYY'));
                }}
              />
            </FormControl>
            <FormControl>
              <Button
                className={classes.refreshButton}
                variant='contained'
                color='primary'
                onClick={() => getLineupStatusList(formatDate(date, 'MM/DD/YYYY'))}
              >
                <Refresh />
              </Button>
            </FormControl>
          </FormGroup>
          <LineupCardsTable loading={isLoading()} cardStatusList={lineupStatusList} handleOpenModal={handleOpenModal} />
        </Paper>
      </Grid>
      <Modal
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        open={showModal}
        onClose={handleCloseModal}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showModal}>
          <div className={classes.paper}>
            <Typography variant='h6' id='modal-title'>
              <b>Version History</b>
            </Typography>
            <Typography variant='subtitle1' id='simple-modal-description'>
              <CardHistoryModal card={modalCard} date={formatDate(date, 'YYYY-MM-DD')} />
            </Typography>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default LineupCardsStatus;
